import React from "react";

import imgLogin from "assets/css/img/pages/404.png";

const View = ({ firebase, history }) => {
    React.useEffect(() => {
        const cardContainer = document.querySelector("body div.col-xl-8.col-11");
        document.body.classList.toggle("not-authorized");
        cardContainer.classList.toggle("col-xl-8");
        cardContainer.classList.toggle("col-11");
        cardContainer.classList.toggle("col-xl-7");
        cardContainer.classList.toggle("col-md-8");
        cardContainer.classList.toggle("col-12");
        return () => {
            document.body.classList.toggle("not-authorized");
            cardContainer.classList.toggle("col-xl-8");
            cardContainer.classList.toggle("col-11");
            cardContainer.classList.toggle("col-xl-7");
            cardContainer.classList.toggle("col-md-8");
            cardContainer.classList.toggle("col-12");
        }
    }, []);

    return (
        <div className="card bg-transparent shadow-none text-center" style={{margin: "auto"}}>
            <div className="card-content">
                <div className="card-body text-center">
                    <h1 className="error-title">Página no encontrada :(</h1>
                    <p>no pudimos encontrar la página que estás buscando</p>
                    <img src={imgLogin} className="img-fluid" alt="not authorized" width="600" />
                </div>
            </div>
        </div>

    )
}

export default View;