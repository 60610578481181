import React from 'react';

import SimpleCard from 'components/SimpleCard';

const View = ({ isActive = false, company = {} }) => {
	const { resources = [] } = company;

	const [currentResources, setCurrentResources] = React.useState([
		...resources,
	]);

	const onAddResource = React.useCallback(() => {
		const arr = [...currentResources];
		arr.push({ name: '', url: '' });
		setCurrentResources(arr);
	}, [currentResources]);

	const onRemoveResource = React.useCallback(
		(pos) => {
			const arr = [...currentResources];

			arr.splice(pos, 1);
			setCurrentResources(arr);
		},
		[currentResources]
	);

	if (currentResources.length === 0) {
		return (
			<div className={isActive ? '' : 'hide'}>
				<div className="row">
					<div className="col-md-12">
						<SimpleCard>
							<div className="col-md-12 text-right">
								<button
									className="btn btn-outline-primary"
									type="button"
									onClick={onAddResource}
								>
									<i className="bx bx-plus" /> Agregar Recurso
								</button>
							</div>
							<h4>Aún no hay registros cargados</h4>
						</SimpleCard>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={isActive ? '' : 'hide'}>
			<SimpleCard>
				<div className="col-md-12 text-right">
					<button
						className="btn btn-outline-primary"
						type="button"
						onClick={onAddResource}
					>
						<i className="bx bx-plus" /> Agregar Recurso
					</button>
				</div>
				<p>
					Ingresa aqui el listado de los recursos disponibles para descargar
					durante la reunión
				</p>
			</SimpleCard>
			{currentResources.map((resource, index) => (
				<div className="row" key={`${index}_${resource.name}`}>
					<div className="col-md-12">
						<SimpleCard>
							<div className="row">
								<div className="col-md-2 d-flex align-items-center">
									<button
										className="btn btn-outline-danger"
										type="button"
										onClick={() => onRemoveResource(index)}
									>
										<i className="bx bx-trash" /> Eliminar
									</button>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label className="col-form-label">
											Nombre para mostrar
										</label>
										<input
											className="form-control"
											type="text"
											placeholder="Nombre"
											autoComplete="off"
											name={`resourceName_${index}`}
											defaultValue={currentResources[index].name || ''}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label className="col-form-label">
											Dirección <i>(url)</i> para descarga
										</label>
										<input
											className="form-control"
											type="text"
											placeholder="Url"
											autoComplete="off"
											name={`resourceUrl_${index}`}
											defaultValue={currentResources[index].url || ''}
										/>
									</div>
								</div>
							</div>
						</SimpleCard>
					</div>
				</div>
			))}
		</div>
	);
};

export default View;
