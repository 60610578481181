import React from 'react';
import moment from 'moment';

import TickCount from 'services/countDownTick';
import styled from 'styled-components';

const CountDown = ({ endDate }) => {
	const [timeData, setTimeData] = React.useState({ isOutOfDate: false });
	const hanleData = React.useCallback((newData) => {
		setTimeData(newData);
	}, []);

	const [tick] = React.useState(
		new TickCount({ endDate, callback: hanleData })
	);

	React.useEffect(() => {
		tick.start();
		return () => {
			tick.destroy();
		};
	}, [tick]);

	if (timeData.isOutOfDate) {
		return null;
	}

	return (
		<CountDownContainer className="algo">
			<div>
				<div>
					<div>
						<span>{timeData.days}</span>
						<small>día{timeData.days === '01' ? '' : 's'}</small>
					</div>
					<div>
						<span>{timeData.hours}</span>
						<small>hora{timeData.hours === '01' ? '' : 's'}</small>
					</div>
					<div>
						<span>{timeData.minutes}</span>
						<small>minuto{timeData.minutes === '01' ? '' : 's'}</small>
					</div>
				</div>
				<h3>
					La asamblea empezara en{' '}
					{moment.unix(endDate).format('YYYY-MM-DD HH:mm:ss')}
				</h3>
			</div>
		</CountDownContainer>
	);
};

export default CountDown;

const CountDownContainer = styled.div`
	background: rgba(0, 0, 0, 0.84);
	border-radius: 5px;
	color: white;
	height: calc(100% - 6em);
	padding: 2em;
	position: fixed;
	width: calc(100% - 9em);
	z-index: 2;

	& > div {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		width: 100%;
	}

	& > div > h3 {
		color: white;
	}

	& > div > div {
		text-align: center;
		width: 100%;
	}

	& > div > div > div {
		display: inline-block;
		margin-bottom: 2em;
		text-align: center;
		width: 20%;
	}
	& > div > div > div > span {
		display: block;
		font-size: 5em;
		font-weight: 200;
		margin: 0;
	}

	& > div > div > div > small {
		text-transform: uppercase;
		font-weight: 900;
	}

	@media (max-width: 960px) {
		& {
			width: calc(100% - 8em);
		}
	}

	@media (max-width: 780px) {
		& > div > div > div > span {
			font-size: 4em;
		}

		& > div > h3 {
			font-size: 20px;
		}
	}

	@media (max-width: 660px) {
		& {
			width: calc(100% - 6em);
		}

		& > div > div > div > span {
			font-size: 3em;
		}

		& > div > h3 {
			font-size: 16px;
		}
	}

	@media (max-width: 420px) {
		& {
			width: calc(100% - 6em);
		}
		& > div > div > div {
			width: 33%;
		}
		& > div > div > div > span {
			font-size: 3em;
		}

		& > div > h3 {
			font-size: 14px;
		}
	}
`;
