import styled from "styled-components";

export const Container = styled.div`
    & {
        align-items: center;
        background: rgba(0, 0, 0, 0.90);
        display: flex;
        height: 100%;
        position: absolute;
        justify-content: center;
        width: 100%;
        z-index: 5;
    }

    & > .survey-content{
        color: var(--white);
        padding: 1rem;
        height: 80%;
        display: flex;
        flex-direction: column;
        max-height: 80%;
        overflow: hidden;
        width: 420px;
    }

    & > .survey-content > .header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        min-height: 38px;
    }

    & > .survey-content > .body{
        margin-bottom: 1rem;
        max-height: calc(100% - 92px);
        overflow-y: auto;
        flex: 1;
    }

    & > .survey-content > .body > .form-item{
        display: none;   
    }

    & > .survey-content > .body > .form-item.active{
        display: block;   
    }

    & > .survey-content > .body h4{
        color: white;
        font-weight: 300;
        margin-bottom: 1rem;
    }

    & > .survey-content > .body .form-choices > div{
        border: 2px solid var(--white);
        border-radius: 4px;
        
        margin-bottom: 8px;
    }
    & > .survey-content > .body .form-choices{
        display: flex;
        flex-direction: column;
    }
    & > .survey-content > .body .form-choices > div{
        align-items: center;
        display: flex;
        padding-left: 0;
    }
    & > .survey-content > .body .form-choices > div label{
        color: white;
        flex: 1;
        padding: 1rem 1rem 1rem 2.5rem;
    }

    & > .survey-content > .body .form-choices > div.checkbox label:after,
    & > .survey-content > .body .form-choices > div.checkbox label:before{
        left: 1rem;
        top: calc(50% - 10px);
    }

    & > .survey-content > .body .form-choices > div.checkbox input:checked~label:after{
        left: calc(1rem + 4px);
        top: calc(50% - 5px);
    }

    & > .survey-content > .body .form-choices > div input{
        margin-left: 1rem;
        position: absolute;
    }

    & > .survey-content > .footer{
        margin-left: 0 !important;
        text-align: right;
        width: 100%;
    }

    .form-item.align-items-center{
        height: 100%;
    }
`;