import React from 'react';

import Uploader from './loadFromFile';
import ViewersList from './list';

const View = ({
	needUpload = false,
	eid,
	firebase,
	handleState,
	isDownloading,
	registeredUsers,
	getViewers,
	company,
}) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Asistentes</h4>
			</div>
			<div className="card-content">
				<div className="card-body">
					{needUpload && (
						<Uploader
							firebase={firebase}
							eid={eid}
							handleState={handleState}
							company={company}
						/>
					)}
					{!needUpload && (
						<ViewersList
							eid={eid}
							handleState={handleState}
							list={registeredUsers}
							isDownloading={isDownloading}
							getViewers={getViewers}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default View;
