import React from 'react';
import Switch from 'react-switch';

import cryptPassword from 'services/cryptPassword';

const EmailForm = ({
	prefix = '',
	data = {},
	sendTestMessage,
	isSendingMessage,
}) => {
	const [useSecure, setSecure] = React.useState(
		data.isSecure === undefined ? false : data.isSecure
	);
	const refContainer = React.useRef(null);

	const onSendTestEmail = React.useCallback(() => {
		const elements = refContainer.current.getElementsByTagName('input');

		const _data = {};
		for (let element of elements) {
			if (element.name !== '') {
				_data[element.name.replace(`${prefix}_`, '')] = element.value;
			}
		}

		sendTestMessage(_data, prefix).then(() => {
			console.log('Tolis');
		});
	}, [sendTestMessage, prefix]);

	return (
		<div className="form form-horizontal" ref={refContainer}>
			<div className="form-body">
				<div className="row">
					<div className="col-md-4">
						<label>Dirección del Servidor</label>
					</div>
					<div className="col-md-8 form-group ">
						<div className="position-relative has-icon-left">
							<input
								type="text"
								className="form-control"
								placeholder="mail.tudominio.com"
								name={`${prefix}_host`}
								defaultValue={data.host || ''}
							/>
							<div className="form-control-position">
								<i className="bx bx-server"></i>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-4">
						<label>Puerto</label>
					</div>
					<div className="col-md-4 form-group ">
						<div className="position-relative has-icon-left">
							<input
								type="number"
								className="form-control"
								placeholder="465"
								name={`${prefix}_port`}
								defaultValue={data.port || ''}
							/>
							<div className="form-control-position">
								<i className="bx bx-compass"></i>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-4">
						<label>Usar conexión segura</label>
					</div>
					<div className="col-md-8 form-group ">
						<div className="position-relative has-icon-left">
							<Switch
								checked={useSecure}
								onChange={(checked) => setSecure(checked)}
							/>
						</div>
						<input
							type="hidden"
							value={useSecure}
							name={`${prefix}_isSecure`}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-4">
						<label>Usuario</label>
					</div>
					<div className="col-md-6 form-group ">
						<div className="position-relative has-icon-left">
							<input
								type="text"
								className="form-control"
								placeholder="usuario@tudominio.com"
								name={`${prefix}_user`}
								defaultValue={data.user || ''}
							/>
							<div className="form-control-position">
								<i className="bx bx-user"></i>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-4">
						<label>Contraseña</label>
					</div>
					<div className="col-md-6 form-group ">
						<div className="position-relative has-icon-left">
							<input
								type="password"
								className="form-control"
								placeholder="TuClaveSegura.123"
								name={`${prefix}_password`}
								defaultValue={
									data.password === undefined
										? ''
										: cryptPassword.decode(data.password, 426)
								}
							/>
							<div className="form-control-position">
								<i className="bx bx-key"></i>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-4">
						<label>Nombre del Remitente</label>
					</div>
					<div className="col-md-6 form-group ">
						<div className="position-relative has-icon-left">
							<input
								type="text"
								className="form-control"
								placeholder="Notificaciones tudominio.com"
								name={`${prefix}_from`}
								defaultValue={data.from}
							/>
							<div className="form-control-position">
								<i className="bx bx-user-voice"></i>
							</div>
						</div>
					</div>

					<div className="col-12 d-flex justify-content-end ">
						<button
							disabled={isSendingMessage}
							type="button"
							onClick={onSendTestEmail}
							className="btn btn-primary mr-1 mb-1"
						>
							<i
								className={
									'bx ' +
									(isSendingMessage ? 'bx-loader-alt spin' : 'bx-mail-send')
								}
							/>{' '}
							{isSendingMessage ? 'Enviando' : 'Envíar Correo de Prueba'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailForm;
