import React from 'react';

import { NavLink } from 'react-router-dom';

import imgLogin from 'assets/css/img/pages/login.png';

const View = ({ company, isLoading, onLogin, onGoogle, onFacebook }) => {
	return (
		<React.Fragment>
			<div className="col-md-6 col-12 px-0">
				<div className="card disable-rounded-right mb-0 p-2 h-100 d-flex justify-content-center">
					<div className="card-header pb-1">
						<div className="card-title">
							<h4 className="text-center mb-2">Bienvenido</h4>
						</div>
					</div>
					<div className="card-content">
						<div className="card-body">
							<div style={{ textAlign: 'right' }}>
								<button
									onClick={onGoogle}
									className="btn btn-social btn-google font-small-3 mb-md-0 mb-1"
								>
									<i className="bx bxl-google font-medium-3" />
									<span className="pl-50 d-block text-center">Administrar</span>
								</button>
							</div>
							<div className="divider">
								<div className="divider-text text-uppercase text-muted">
									<small>o inicia sesión con tu correo</small>
								</div>
							</div>
							<form onSubmit={onLogin}>
								<div className="form-group mb-50">
									<label className="text-bold-600">Dirección de correo</label>
									<input
										type="email"
										name="email"
										className="form-control"
										placeholder="Email"
										autoComplete="username"
									/>
								</div>
								<div className="form-group">
									<label className="text-bold-600">Clave</label>
									<input
										type="password"
										name="password"
										className="form-control"
										placeholder="Password"
										autoComplete="current-password"
									/>
								</div>
								<div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-center">
									<div className="text-right">
										<NavLink to="/recuperar-clave" className="card-link">
											<small>Olvidaste tu clave?</small>
										</NavLink>
									</div>
								</div>
								<button
									type="submit"
									disabled={isLoading}
									className="btn btn-primary glow w-100 position-relative"
								>
									Ingresar
									<i
										id="icon-arrow"
										className={
											'bx ' +
											(isLoading ? 'bx-loader-alt spin' : 'bx-right-arrow-alt')
										}
									/>
								</button>
							</form>
							<hr />
							<div className="mt-2">
								<p>
									Al ingresar confirmas que conoces y estás de acuerdo con
									nuestros{' '}
									<a href="/terminos-y-condiciones" target="_blank">
										<strong>Términos y condiciones</strong>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-6 d-md-block d-none text-center align-self-center p-0">
				<div className="card-content">
					<img
						className="img-fluid"
						src={company.LOGIN_512x || imgLogin}
						alt="branding logo"
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

export default View;
