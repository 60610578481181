import React from "react";
import View from "./view";

class FieldsBuilder extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: (props.field.name || ""),
            type: (props.field.type || "text"),
            choices: (props.field.choices || [""]),
        };
        this.handleState = this.handleState.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteChoice = this.onDeleteChoice.bind(this);
        this.onDown = this.onDown.bind(this);
        this.onDownChoice = this.onDownChoice.bind(this);
        this.onUp = this.onUp.bind(this);
        this.onUpChoice = this.onUpChoice.bind(this);
    }
    handleState(data){
        this.setState(data, ()=>{
            this.props.onUpdate(this.state, this.props.index);
        });
    }
    onDelete(){
        this.props.onDelete(this.props.index);
    }
    onDeleteChoice(index){
        const {choices = []} = this.state;
        choices.splice(index, 1);
        this.handleState({ choices });
    }
    onDown(){
        this.props.onDown(this.props.index);
    }
    onDownChoice(index){
        const choices = move(this.state.choices, index, index + 1);
        this.handleState({ choices });
    }
    onUp(){
        this.props.onUp(this.props.index);
    }
    onUpChoice(index) {
        const choices = move(this.state.choices, index, index - 1);
        this.handleState({ choices });
    }
    render(){
        return (
            <View 
                isTheFirst={this.props.isTheFirst}
                isTheLast={this.props.isTheLast}
                field={this.props.field} 
                state={this.state} 
                handleState={this.handleState}
                onDelete={this.onDelete}
                onDeleteChoice={this.onDeleteChoice}
                onDown={this.onDown}
                onDownChoice={this.onDownChoice}
                onUp={this.onUp}
                onUpChoice={this.onUpChoice}
                />
        );
    }
}

export default FieldsBuilder;

const move = (arr, from, to)=>{
    console.log(arr, from , to);
    const
        _fields = [...arr],
        element = arr[from];

    _fields.splice(from, 1);
    _fields.splice(to, 0, element);
    console.log(_fields);
    return _fields;
}