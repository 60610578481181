import React from 'react';
import swal from 'sweetalert';

import View from './view';

import cryptPassword from 'services/cryptPassword';
import Loading from 'components/Loading';

class Settings extends React.Component {
	constructor(props) {
		super(props);

		const { match = {} } = props,
			{ params = {} } = match,
			{ eid = false } = params;

		this.state = {
			isDownloading: true,
			isLoading: false,
			isSendingMessage: false,
			eid,
			company: {
				email: {},
			},
		};

		this.updateCompanyData = this.updateCompanyData.bind(this);
	}
	componentDidMount() {
		const { firebase } = this.props;
		const { eid } = this.state;
		const self = this;

		firebase.get('assemblies', eid).then((doc) => {
			if (doc.exists) {
				self.setState({ company: doc.data(), isDownloading: false });
			} else {
				swal('', 'La asamblea no existe', 'error').then(() => {
					self.props.history.push('/asambleas');
				});
			}
		});
	}
	updateCompanyData = (field, requireDbUpdate = true) => {
		const { eid } = this.state;
		const { firebase, company, handleMainState } = this.props;
		const self = this;

		if (requireDbUpdate) {
			if (!this.state.isLoading) {
				this.setState({ isLoading: true }, () => {
					firebase
						.update('assemblies', eid, field)
						.then(() => {
							/*const newCompany = { ...company, ...field };
							handleMainState({ company: newCompany });*/
							swal('', 'Los datos han sido actualizados', 'success').then(
								() => {
									self.setState({ isLoading: false });
								}
							);
						})
						.catch((error) => {
							console.error(error);
							swal(
								'Error',
								'Algo salió mal intentando actualizar, por favor intentalo nuevamente',
								'error'
							).then(() => {
								self.setState({ isLoading: false });
							});
						});
				});
			}
		} else {
			const newCompany = { ...company, ...field };
			handleMainState({ company: newCompany });
		}
	};
	onSubmitData = (e) => {
		e.preventDefault();

		const {
			name,
			address,
			neighborhood,
			city,
			phone,
			yt_code,
			terms,
		} = e.target.elements;
		const {
			notifications_host,
			notifications_port,
			notifications_isSecure,
			notifications_user,
			notifications_password,
			notifications_from,
		} = e.target.elements;

		const {
			security_host,
			security_port,
			security_isSecure,
			security_user,
			security_password,
			security_from,
		} = e.target.elements;

		const resources = [];

		let resourcesSize = 0;
		for (let index in e.target.elements) {
			if (index.startsWith('resourceUrl_')) {
				resourcesSize++;
			}
		}

		for (let i = 0; i < resourcesSize; i++) {
			resources.push({
				name: e.target.elements[`resourceName_${i}`].value,
				url: e.target.elements[`resourceUrl_${i}`].value,
			});
		}

		const data = {
			name: name.value,
			address: address.value,
			neighborhood: neighborhood.value,
			city: city.value,
			phone: phone.value,
			resources,
			email: {
				notifications: {
					host: notifications_host.value,
					port: parseInt(notifications_port.value, 10),
					isSecure: notifications_isSecure.value === 'true',
					user: notifications_user.value,
					password: cryptPassword.encode(notifications_password.value, 426),
					from: notifications_from.value,
				},
				security: {
					host: security_host.value,
					port: parseInt(security_port.value, 10),
					isSecure: security_isSecure.value === 'true',
					user: security_user.value,
					password: cryptPassword.encode(security_password.value, 426),
					from: security_from.value,
				},
			},
			terms: terms.value,
			yt_code: yt_code.value,
		};

		this.updateCompanyData(data);
	};
	sendTestMessage = (settings, type) => {
		const { firebase, currentUser } = this.props;
		const { eid } = this.state;

		settings.password = cryptPassword.encode(settings.password, 426);

		return new Promise((resolve, reject) => {
			this.setState({ isSendingMessage: true }, () => {
				firebase
					.sendEmail({
						eid,
						type: type,
						to: currentUser.email,
						template: 'test',
						subject: 'Mensaje de Prueba',
						additionalInfo: { name: currentUser.name },
						settings,
					})
					.then((response) => {
						console.log(response);
						this.setState({ isSendingMessage: false }, () => {
							swal('', 'Mensaje enviado', 'success');
						});
						resolve();
					})
					.catch((error) => {
						this.setState({ isSendingMessage: false }, () => {
							swal('Error', error.message, 'error');
						});
						console.error(error);
						reject();
					});
			});
		});
	};

	render() {
		if (this.state.isDownloading) {
			return <Loading noBackground />;
		}
		return (
			<View
				{...this.state}
				firebase={this.props.firebase}
				onSubmitData={this.onSubmitData}
				updateData={this.updateCompanyData}
				sendTestMessage={this.sendTestMessage}
			/>
		);
	}
}

export default Settings;
