import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';
import 'firebase/firebase-database';

import config from './config';

import firebaseErrors from 'config/firebaseErrors.json';

class Firebase {
	constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.googleProvider = new app.auth.GoogleAuthProvider();
		this.facebookProvider = new app.auth.FacebookAuthProvider();

		this.emailProvider = new app.auth.EmailAuthProvider();
		this.db = app.firestore();
		this.realtimedatabase = app.database();

		this.functions = app.functions();

		this.storage = app.storage();

		this.FieldValue = app.firestore.FieldValue;
		this.SERVER_TIMESTAMP = app.database.ServerValue.TIMESTAMP;

		app.auth().useDeviceLanguage();

		this.arrayRemove = app.firestore.FieldValue.arrayRemove;
		this.arrayUnion = app.firestore.FieldValue.arrayUnion;

		this.firestore = app.firestore;

		this.getCredential = app.auth.EmailAuthProvider.credential;
	}

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSignInWithGoogle = () => this.auth.signInWithRedirect(this.googleProvider);

	doSignInAnonymous = () => {
		return this.auth.signInAnonymously();
	};

	doSendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

	doSignOut = () => this.auth.signOut();

	get = (collection, id, collection2, id2) => {
		if (id === undefined) {
			return this.db.collection(collection).get();
		}

		if (collection2 !== undefined) {
			if (id2 !== undefined) {
				if (typeof id2 === 'object') {
					let ans = this.db
						.collection(collection)
						.doc(id)
						.collection(collection2);
					if (Array.isArray(id2)) {
						id2.forEach((con) => {
							ans = ans.where(con.field, con.condition, con.value);
						});

						return ans.get();
					} else {
						return ans.where(id2.field, id2.condition, id2.value).get();
					}
				}

				return this.db
					.collection(collection)
					.doc(id)
					.collection(collection2)
					.doc(id2)
					.get();
			}

			return this.db
				.collection(collection)
				.doc(id)
				.collection(collection2)
				.get();
		}

		if (typeof id === 'object') {
			let ans = this.db.collection(collection);
			if (Array.isArray(id)) {
				id.forEach((con) => {
					ans = ans.where(con.field, con.condition, con.value);
				});

				return ans.get();
			} else {
				return ans.where(id.field, id.condition, id.value).get();
			}
		}

		return this.db.collection(collection).doc(id).get();
	};

	add = (collection, data, subcollection, data2) => {
		if (Array.isArray(data)) {
			const { db } = this;
			let batch = db.batch();

			return new Promise((resolve, reject) => {
				data.forEach((record) => {
					const doc = db.collection(collection).doc();
					batch.set(doc, record);
				});

				batch.commit().then(resolve).catch(reject);
			});
		}

		if (subcollection) {
			return this.db
				.collection(collection)
				.doc(data)
				.collection(subcollection)
				.add(data2);
		}
		return this.db.collection(collection).add(data);
	};

	set = (collection, id, data, sub_doc, sub_data) => {
		if (data === undefined) {
			return this.db.collection(collection).doc().set(id);
		}
		if ((id === null || id === undefined) && data !== undefined) {
			return this.db.collection(collection).doc().set(data);
		}

		if (typeof sub_doc === 'string') {
			return this.db
				.collection(collection)
				.doc(id)
				.collection(data)
				.doc(sub_doc)
				.set(sub_data);
		}

		return this.db.collection(collection).doc(id).set(data);
	};

	update = (collection, id, data, doc2, data2) => {
		if (data2 !== undefined) {
			return this.db
				.collection(collection)
				.doc(id)
				.collection(data)
				.doc(doc2)
				.update(data2);
		}
		return this.db.collection(collection).doc(id).update(data);
	};

	delete = (collection, id, subcollection, subid) => {
		if (typeof id === 'object') {
			let ans = this.db.collection(collection);
			if (Array.isArray(id)) {
				id.forEach((con) => {
					ans = ans.where(con.field, con.condition, con.value);
				});
			} else {
				ans = ans.where(id.field, id.condition, id.value);
			}

			let batch = this.db.batch();

			return new Promise((resolve, reject) => {
				ans
					.get()
					.then((snapshot) => {
						if (snapshot.size === 0) {
							resolve({ empty: true });
						}

						snapshot.docs.forEach((doc) => {
							batch.delete(doc.ref);
						});

						batch.commit().then(resolve).catch(reject);
					})
					.catch(reject);
			});
		}

		if (subid !== undefined) {
			return this.db
				.collection(collection)
				.doc(id)
				.collection(subcollection)
				.doc(subid)
				.delete();
		}

		return this.db.collection(collection).doc(id).delete();
	};

	uploadFile = (path, file, filename) => {
		filename = filename || file.name;
		const storageRef = this.storage.ref();

		storageRef.child(`${path}`);
		const imageRef = storageRef.child(`${path}/${filename}`);
		return new Promise((resolve, reject) => {
			imageRef
				.put(file)
				.then((response) => {
					imageRef
						.getDownloadURL()
						.then((url) => {
							resolve({ response, url });
						})
						.catch(reject);
				})
				.catch(reject);
		});
	};

	getUser = (uid) => {
		const getUser = this.functions.httpsCallable('getUser');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await getUser({ uid });

				if (res.data.errorInfo) {
					const { code } = res.data.errorInfo;
					if (firebaseErrors[code.toUpperCase()]) {
						reject(firebaseErrors[code.toUpperCase()]);
					} else {
						reject(
							'Hubo un error en la edición de éste usuario, por favor revisa los parámetros'
						);
					}
				} else {
					resolve(res.data);
				}
			} catch (e) {
				reject(e);
			}
		});
	};

	getAllUsers = () => {
		const getUsers = this.functions.httpsCallable('listUsers');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await getUsers();

				if (res.data.errorInfo) {
					const { code } = res.data.errorInfo;
					if (firebaseErrors[code.toUpperCase()]) {
						reject(firebaseErrors[code.toUpperCase()]);
					} else {
						reject(
							'Hubo un error en la edición de éste usuario, por favor revisa los parámetros'
						);
					}
				} else {
					resolve(res.data);
				}
			} catch (e) {
				reject(e);
			}
		});
	};

	doCreateUser = (data) => {
		const createUser = this.functions.httpsCallable('createUser');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await createUser(data);

				if (res.data.errorInfo) {
					const { code } = res.data.errorInfo;
					if (firebaseErrors[code.toUpperCase()]) {
						reject(firebaseErrors[code.toUpperCase()]);
					} else {
						reject(
							'Hubo un error en la creación de éste usuario, por favor revisa los parámetros'
						);
					}
				} else {
					resolve(res.data);
				}
			} catch (e) {
				reject(e);
			}
		});
	};

	doUpdateUser = (data) => {
		const updateUser = this.functions.httpsCallable('updateUser');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await updateUser(data);

				if (res.data.errorInfo) {
					const { code } = res.data.errorInfo;
					if (firebaseErrors[code.toUpperCase()]) {
						reject(firebaseErrors[code.toUpperCase()]);
					} else {
						reject(
							'Hubo un error en la edición de éste usuario, por favor revisa los parámetros'
						);
					}
				} else {
					resolve(res.data);
				}
			} catch (e) {
				reject(e);
			}
		});
	};

	doDeleteUser = (uid) => {
		const deleteUser = this.functions.httpsCallable('deleteUser');
		this.delete('users', uid);
		return new Promise(async (resolve, reject) => {
			try {
				const res = await deleteUser({ uid });

				resolve(res.data);
			} catch (e) {
				reject(e);
			}
		});
	};

	doOfflineNotification = (uid) => {
		const fun = this.functions.httpsCallable('notifyOffline');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await fun({ uid });

				resolve(res.data);
			} catch (e) {
				reject(e);
			}
		});
	};

	getCollectionBySocket(
		collection,
		collection_id,
		subcollection,
		doc_id,
		resolve
	) {
		if (typeof collection_id === 'function') {
			return this.db.collection(collection).onSnapshot(collection_id);
		}

		if (typeof doc_id !== 'string') {
			if (typeof collection_id === 'object') {
				let ans = this.db.collection(collection);
				if (Array.isArray(collection_id)) {
					collection_id.forEach((con) => {
						ans = ans.where(con.field, con.condition, con.value);
					});

					return ans.onSnapshot(subcollection);
				} else {
					return ans
						.where(
							collection_id.field,
							collection_id.condition,
							collection_id.value
						)
						.onSnapshot(subcollection);
				}
			}

			if (typeof subcollection === 'function') {
				return this.db
					.collection(collection)
					.doc(collection_id)
					.onSnapshot(subcollection);
			}

			if (typeof doc_id === 'function') {
				return this.db
					.collection(collection)
					.doc(collection_id)
					.collection(subcollection)
					.onSnapshot(doc_id);
			}

			return this.db
				.collection(collection)
				.doc(collection_id)
				.collection(subcollection)
				.where(doc_id.condition_field, doc_id.condition, doc_id.condition_value)
				.onSnapshot(resolve);
		}
		return this.db
			.collection(collection)
			.doc(collection_id)
			.collection(subcollection)
			.doc(doc_id)
			.onSnapshot(resolve);
	}

	initPresence(
		room,
		onOnline = { isConnected: true },
		onOffline = { isConnected: false }
	) {
		const uid = this.auth.currentUser.uid;

		const userStatusDatabaseRef = this.realtimedatabase.ref(`/${room}/` + uid);
		const self = this;

		this.realtimedatabase
			.ref('.info/connected')
			.on('value', function (snapshot) {
				if (snapshot.val() === false) {
					return;
				}

				onOnline.time = self.SERVER_TIMESTAMP;
				onOffline.time = self.SERVER_TIMESTAMP;

				userStatusDatabaseRef
					.onDisconnect()
					.set(onOffline)
					.then(function () {
						userStatusDatabaseRef.set(onOnline);
					});
			});
	}

	writeOnBatch(collection, data) {
		if (Array.isArray(data)) {
			const { db } = this;
			let batch = db.batch();

			return new Promise((resolve, reject) => {
				data.forEach((record) => {
					const doc = db.collection(collection).doc(record.id);
					batch.set(doc, record);
				});

				batch.commit().then(resolve).catch(reject);
			});
		}
	}

	sendEmail = (settings) => {
		const sendEmail = this.functions.httpsCallable('sendEmail');
		return new Promise(async (resolve, reject) => {
			try {
				const res = await sendEmail(settings);

				resolve(res.data);
			} catch (e) {
				reject(e);
			}
		});
	};

	updateResume({ fields }) {
		let resumeRef = this.db.collection('resume').doc('current');
		return this.db.runTransaction((t) => {
			return t.get(resumeRef).then((doc) => {
				let data = {};

				if (doc.exists) {
					data = doc.data();
				}

				fields.forEach((field) => {
					let value = data[field.label] !== undefined ? data[field.label] : 0;

					data[field.label] = field.fun(value);
				});

				if (doc.exists) {
					t.update(resumeRef, data);
				} else {
					t.set(resumeRef, data);
				}
			});
		});
	}
	getResume() {
		return new Promise((resolve, reject) => {
			this.db
				.collection('resume')
				.doc('current')
				.get()
				.then((response) => {
					resolve(response.data());
				})
				.catch(reject);
		});
	}
}

export default Firebase;
