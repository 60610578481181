import styled from 'styled-components';

export const Container = styled.div`
	width: 314px;

	& > div.buttons > button {
		border-radius: 0;
	}

	& > div.buttons > button:first-child {
		border-top-left-radius: 0.267rem;
	}

	& > div.buttons > button:last-child {
		border-top-right-radius: 0.267rem;
	}

	& > div.date-picker {
		position: relative;
	}

	& > div.date-picker .DateRangePickerInput,
	& > div.date-picker .DateRangePicker {
		text-align: center;
		width: 313px;
	}

	& .DateInput_input {
		line-height: 12px;
		font-weight: 500;
		padding: 4px 4px 4px;
		font-size: 0.8rem !important;
		text-align: center;
	}

	& .DateRangePickerInput__withBorder {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-color: var(--primary);
	}

	@media (max-width: 620px) {
		width: 100%;

		& .buttons button {
			height: 45px;
			width: 25%;
		}

		& > div.date-picker .DateRangePickerInput,
		& > div.date-picker .DateRangePicker {
			width: 100%;
		}
	}
`;
