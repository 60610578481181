import React from "react";


import DateSelector from "components/DateSelector";
import SimpleCard from "components/SimpleCard";

import { Header, TimetableContainer, Loader } from "./Styles";

import completeWithZero from "services/completeWithZero";

import Modal from "./Modal";

const View = ({ isLoading, startDate, endDate, focusedInput, handleState, onDateChange, showedDays = [], timetables = [], addJourney, deleteJourney, handleDatesMove }) => {
    
    const [showModal, handleModal] = React.useState(false);
    const [selectedDay, handleSelectedDay] = React.useState({});

    const onAddTime = React.useCallback(({startTime, endTime, duration, repeat, repeatMode, until})=>{
        addJourney({ startTime, endTime, duration, repeat, repeatMode, until, selectedDay });
        handleModal(false);
    }, [addJourney, selectedDay])

    return (
        <div className="col-sm-12 mt-1" style={{ minHeight: "860px" }}>
            <SimpleCard>
                <Header>
                    <div className="btn-group" role="group" aria-label="_">
                        <button type="button" onClick={() => {handleDatesMove("previous")}} className="btn"><i className="bx bx-chevron-left" style={{fontSize: "2rem"}} /></button>
                        <button type="button" onClick={() => {handleDatesMove("next")}} className="btn"><i className="bx bx-chevron-right" style={{fontSize: "2rem"}} /></button>
                    </div>

                    <DateSelector
                        endDate={endDate}
                        startDate={startDate}
                        focusedInput={focusedInput}
                        onDateChange={handleState}
                        onChange={onDateChange}
                        allowFutureDays
                        onFocusedInputChange={handleState} />

                </Header>

                {isLoading && (
                    <Loader>
                        <i className="bx bx-loader spin" />

                    </Loader>
                )}
                {!isLoading && ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"].map(day => (
                    <TimetableContainer className={"main-header"} key={day}>
                        <div className="header">
                            <div>
                                <h4 className="day-name">{day}</h4>
                            </div>
                        </div>
                    </TimetableContainer>
                ))}
                <div className="d-flex flex-wrap">
                    {!isLoading && showedDays.map((day, index) => {
                        return (
                            <TimetableContainer key={index} className={(day.isBlocked ? " is-blocked" : "")}>
                                <div className="header">
                                    <div>
                                        <small>{day.moment.format("DD [de] MMM")}</small>
                                    </div>
                                    <button 
                                        onClick={()=>{
                                            handleSelectedDay(day);
                                            handleModal(true);
                                        }}
                                        className="btn text-primary btn-sm">
                                        <i className="bx bx-plus" />
                                        <span><i className="bx bx-timer" /> Agregar Jornada</span>
                                    </button>
                                </div>
                                <div className="timetables">
                                    {timetables[day.label] && timetables[day.label].map((timetableDay, dayIndex) => {
                                        const hourFrom = Math.floor(timetableDay.from);
                                        const minutesFrom = ((timetableDay.from -  hourFrom) * 60).toFixed(0);
                                        const hourUntil = Math.floor(timetableDay.until);
                                        const minutesUntil = ((timetableDay.until - hourUntil) * 60).toFixed(0);

                                        return (
                                            <div className="timetable-container" key={dayIndex}>
                                                <div className="timetable" key={dayIndex}>
                                                    <div>
                                                        <div>
                                                            <i className="bx bx-log-in" />
                                                            <span>{completeWithZero(hourFrom, 2)}:{completeWithZero(minutesFrom, 2)}</span>
                                                        </div>
                                                        <div>
                                                            <i className="bx bx-log-out" />
                                                            <span>{completeWithZero(hourUntil, 2)}:{completeWithZero(minutesUntil, 2)}</span>
                                                        </div>
                                                        <div>
                                                            <i className="bx bx-time" />
                                                            <span>{timetableDay.duration} <small>min</small></span>
                                                        </div>
                                                    </div>
                                                    <button className="btn-delete" onClick={() => deleteJourney(timetableDay)}>
                                                        <i className="bx bx-x" />
                                                        <span>Eliminar</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </TimetableContainer>
                        )
                    })}
                </div>
            </SimpleCard>
            {showModal && (
                <Modal 
                    selectedDay={selectedDay}
                    onSubmit={onAddTime}
                    onClose={() => handleModal(false)}/>
            )}
        </div>
    )
}

export default View;