import LoginPage from 'screens/Login';
import ForgotPasswordPage from 'screens/ForgotPassword';
import NotAuthorizedPage from 'screens/NotAuthorized';
import Terms from 'screens/Terms';
import NotFound from 'screens/404';

const Routes = [
	{
		path: '/terminos-y-condiciones',
		name: 'Terms',
		component: Terms,
	},
	{
		path: '/recuperar-clave',
		name: 'ForgotPasword',
		component: ForgotPasswordPage,
	},
	{
		path: '/no-autorizado',
		name: 'No autorizado',
		component: NotAuthorizedPage,
	},
	{
		path: '/no-encontrado',
		name: 'No Encontrado',
		component: NotFound,
	},
	{
		path: '/',
		name: 'Login Page',
		component: LoginPage,
	},
];

export default Routes;
