import React from 'react';

import SimpleCard from 'components/SimpleCard';

import ImageUploader from 'components/ImageUploader';
import imgDefault from 'assets/img/logo_horizontal.png';

import EmailForm from './EmailForm';

import emailData from './email.json';

const View = ({
	eid,
	isActive = false,
	company = {},
	updateData,
	sendTestMessage,
	isSendingMessage,
}) => {
	const email = company.email ? company.email : emailData;

	return (
		<div className={isActive ? '' : 'hide'}>
			<SimpleCard>
				<div className="row">
					<div className="col-md-4">
						<label className="col-form-label d-block">Logo Institucional</label>
						<small className="text-muted">
							El que se mostrará en el encabezado del Correo
						</small>
					</div>
					<div className="col-md-6 form-group position-relative">
						<ImageUploader
							src={company.EMAIL_HEADER_256x || imgDefault}
							height="10em"
							uploadPath={`assemblies/${eid}`}
							name="EMAIL_HEADER"
							onSelect={(src) => {
								updateData({ EMAIL_HEADER_256x: src }, false);
							}}
						/>
					</div>
				</div>
			</SimpleCard>
			<p>
				<b>Configuración de las cuentas SMTP</b>,{' '}
				<small>Estos datos puedes obtenerlos de tu proveedor de correo</small>
			</p>
			<div className="row">
				<div className="col-md-6">
					<SimpleCard>
						<h4 className="card-title">Notificaciones</h4>
						<EmailForm
							prefix="notifications"
							data={email.notifications}
							sendTestMessage={sendTestMessage}
							isSendingMessage={isSendingMessage}
						/>
					</SimpleCard>
				</div>
				<div className="col-md-6">
					<SimpleCard>
						<h4 className="card-title">Seguridad</h4>
						<EmailForm
							prefix="security"
							data={email.security}
							sendTestMessage={sendTestMessage}
							isSendingMessage={isSendingMessage}
						/>
					</SimpleCard>
				</div>
			</div>
		</div>
	);
};

export default View;
