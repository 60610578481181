import React from 'react';

import SimpleCard from 'components/SimpleCard';
import removeAccents from 'services/removeAccents';

import InputMask from 'react-input-mask';

import moment from 'moment';

const View = ({
	onBack,
	handleValidators,
	validators,
	onSubmit,
	isLoading,
	references,
}) => {
	const [url, setUrl] = React.useState('');

	return (
		<React.Fragment>
			<button onClick={onBack} className="btn btn-primary">
				<i className="bx bx-left-arrow-alt" /> Volver
			</button>
			<div className="d-flex align-items-center mt-1 mb-1">
				<i className="bx bx-chevron-right" />
				<span>Nueva Asamblea</span>
			</div>
			<form onSubmit={onSubmit} className="mb-3" ref={references.form}>
				<SimpleCard>
					<div className="row">
						<div className="col-md-4">
							<label className="form-label">Nombre</label>
						</div>
						<div className="col-md-8 form-group">
							<div className="form-control-container form-group">
								<input
									className="form-control"
									type="text"
									onChange={(e) => {
										setUrl(removeAccents(e.target.value));
									}}
									name="name"
									placeholder="Nombre"
									onBlur={(e) =>
										handleValidators('name', e.target.value !== '')
									}
								/>
								{validators.name === true ||
								validators.name === undefined ? null : (
									<small className="text-danger">
										El nombre no puede estar vacío
									</small>
								)}
								<input type="hidden" name="url" value={url} />
							</div>
							{url !== '' && (
								<div className="form-control-container form-group">
									<p>
										Los asistentes accederán a la asamblea en la siguiente
										dirección:{' '}
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={`${window.location.origin}/${url}`}
										>
											{window.location.origin}/{url}
										</a>
									</p>
								</div>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-md-4">
							<label className="form-label">Fecha de la Asamblea</label>
						</div>
						<div className="col-md-8 form-group position-relative has-icon-left flex-grow-1">
							<div
								className="input-group"
								style={{ minWidth: '285px', maxWidth: '286px' }}
							>
								<InputMask
									className="form-control"
									name="date"
									autoComplete="off"
									mask="9999-99-99"
									placeholder="AAAA-MM-DD"
									defaultValue={moment().format('YYYY-MM-DD')}
									required={true}
									onBlur={(e) =>
										handleValidators(
											'date',
											/^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/.test(
												e.target.value
											)
										)
									}
								/>
								<div className="form-control-position">
									<i className="bx bx-calendar"></i>
								</div>
								<div className="input-group-append">
									<span className="input-group-text">AÑO-MES-DÍA</span>
								</div>
							</div>

							{validators.date === true ||
							validators.date === undefined ? null : (
								<small className="text-danger">
									Debes ingresar una fecha válida
								</small>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-md-4">
							<label className="form-label">Hora de inicio</label>
						</div>
						<div className="col-md-8 form-group position-relative has-icon-left flex-grow-1">
							<div
								className="input-group"
								style={{ minWidth: '285px', maxWidth: '286px' }}
							>
								<InputMask
									className="form-control"
									name="hour"
									autoComplete="off"
									mask="99:99"
									placeholder="hh:mm"
									defaultValue={moment().format('hh-mm')}
									required={true}
									onBlur={(e) =>
										handleValidators(
											'hour',
											/^(?:0?[1-9]|1[0-2]):[0-5][0-9]s?(?:[aApP](.?)[mM]\1)?$/.test(
												e.target.value
											)
										)
									}
								/>
								<div className="form-control-position">
									<i className="bx bx-time"></i>
								</div>
								<div className="input-group-append">
									<select
										name="meridiem"
										className="input-group-text"
										defaultValue={moment().format('a')}
									>
										<option value="am">AM</option>
										<option value="pm">PM</option>
									</select>
								</div>
							</div>
							{validators.hour === true ||
							validators.hour === undefined ? null : (
								<small className="text-danger">
									Debes ingresar una hora válida
								</small>
							)}
						</div>
					</div>
				</SimpleCard>

				<div className="text-right">
					<button disabled={isLoading} className="btn btn-success">
						<i
							className={'bx bx-' + (isLoading ? 'loader-alt spin' : 'save')}
						/>{' '}
						Guardar
					</button>
				</div>
			</form>
		</React.Fragment>
	);
};

export default View;
