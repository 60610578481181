import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import Routes from './routes';

import 'assets/css/authentication.min.css';

const Guest = (props) => {
	return (
		<div className="app-content content">
			<div className="content-overlay"></div>
			<div className="content-wrapper">
				<div className="content-header row"></div>
				<div className="content-body">
					<section className="row flexbox-container">
						<div className="col-xl-8 col-11">
							<div className="card bg-authentication mb-0">
								<div className="row m-0">
									<BrowserRouter>
										<Switch>
											{getRoutes(props)}
											<Route render={() => <Redirect to="/admin" />} />
										</Switch>
									</BrowserRouter>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};

export default Guest;

const getRoutes = (props) => {
	if (props.approved) {
		const maxSize = Routes.length - 1;
		return Routes.map((prop, key) => {
			if (key === maxSize) {
				return (
					<Route
						render={(rprops) => <prop.component {...rprops} {...props} />}
						key={key}
					/>
				);
			} else {
				return (
					<Route
						path={prop.path}
						render={(rprops) => <prop.component {...rprops} {...props} />}
						key={key}
					/>
				);
			}
		});
	} else {
		let Component = Routes[2].component;
		if (props.notFound) {
			Component = Routes[3].component;
			return (
				<Route render={(rprops) => <Component {...rprops} {...props} />} />
			);
		}
		return <Route render={(rprops) => <Component {...rprops} {...props} />} />;
	}
};
