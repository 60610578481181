import styled from "styled-components";

export const Form = styled.form`
    
    & input{
        font-size: 1.8rem;
    }

    & button{
        background: var(--primary);
        border: none;
        color: white;
        width: 120px;
    }
    & button i{
        font-size: 2.4rem;
    }

    & > div:nth-child(2){
        display: flex;
    }

    & > div:nth-child(2) input{
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        display: inline-block;
        width: calc(100% - 55px);
    }

    & > div:nth-child(2) button{
        border-bottom-right-radius: .267rem;
        border-top-right-radius: .267rem;
        width: 55px;
    }

    @media(max-width: 580px){
        margin-top: 2rem;
    }
`;

export const InfoTable = styled.div`
    & > div{
        border-bottom: 1px solid #ebedf0;
        display: flex;
        padding: 1rem 0;
    }

    & > div > div:nth-child(1){
        flex: 1;
        color: #90949c;
    }
    & > div > div:nth-child(2){
        flex: 2;
        color: #1c1e21;
    }

    @media(max-width: 680px){
        & > div{
            flex-direction: column;
        }   
    }
`;