import React from "react";

const SimpleCard = ({ children, className = ""})=>{
    return (
        <div className={"card " + className}>
            <div className="card-content">
                <div className="card-body">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SimpleCard;