import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import 'assets/css/vertical-menu.min.css';
import 'assets/css/datetime.css';
import 'react-contexify/dist/ReactContexify.min.css';
import 'react-dates/lib/css/_datepicker.css';

import Header from './Header';
import Sidebar from './Sidebar';

import arrRoutes from './routes';

import 'react-dates/initialize';

class Logged extends React.Component {
	constructor(props) {
		const { darkMode = false } = props.currentUser;

		super(props);

		const tmpRoutes = arrRoutes.filter((item) =>
			item.allowedBy.includes(props.currentUser.type || '')
		);

		this.state = {
			isCollapsed: false,
			darkMode,
			Routes: tmpRoutes,
			showSecondPassword: false,
		};

		this.collapseMenu = this.collapseMenu.bind(this);
		document.body.classList.remove('bg-full-screen-image');
		document.body.classList.remove('blank-page');
		document.body.classList.remove('2-columns');
		document.body.classList.remove('pace-done');
		document.body.classList.remove('vertical-overlay-menu');
		document.body.classList.remove('menu-open');
		document.body.classList.add('vertical-menu-modern');
	}
	componentWillUnmount() {
		document.body.classList.toggle('bg-full-screen-image');
		document.body.classList.toggle('blank-page');
	}
	toggleMenu() {
		document.body.classList.toggle('vertical-menu-modern');
		document.body.classList.toggle('2-columns');
		document.body.classList.toggle('pace-done');
		document.body.classList.toggle('vertical-overlay-menu');
		document.body.classList.toggle('menu-open');
		document.getElementById('sidenav-overlay').classList.toggle('d-block');
	}
	collapseMenu() {
		this.setState({ isCollapsed: !this.state.isCollapsed }, () => {
			document.body.classList.toggle('menu-collapsed');
		});
	}
	closeSencondPassword = () => {
		this.setState({ showSecondPassword: false });
	};

	handleDarkMode = (value) => {
		const { firebase, currentUser } = this.props;
		this.setState({ darkMode: value }, () => {
			firebase.update('users', currentUser.uid, { darkMode: value });
		});
	};

	render() {
		const { Routes } = this.state;
		const { nid = false } = this.props.currentUser;

		return (
			<BrowserRouter>
				<div className="">
					<div className="header-navbar-shadow"></div>
					<Header
						currentUser={this.props.currentUser}
						toggleMenu={this.toggleMenu}
						darkMode={this.state.darkMode}
						handleDarkMode={this.handleDarkMode}
					/>
					<Sidebar
						company={this.props.company}
						isAdmin={this.props.currentUser.isAdmin}
						isCollapsed={this.state.isCollapsed}
						doSignOut={this.props.doSignOut}
						toggleMenu={this.toggleMenu}
						currentUser={this.props.currentUser}
						collapseMenu={this.collapseMenu}
					/>

					<div className="app-content content">
						<div className="content-overlay"></div>
						<div className="content-wrapper">
							<div className="content-header row"></div>
							<div className="content-body">
								<section>
									{nid !== false && (
										<Switch>
											{Routes.map((route, index) => {
												return (
													<Route
														path={route.path}
														render={(rprops) => (
															<route.component
																{...rprops}
																{...this.props}
																menuIsCollapsed={this.state.isCollapsed}
																collapseMenu={this.collapseMenu}
															/>
														)}
														key={index}
													/>
												);
											}, this)}
										</Switch>
									)}
								</section>
							</div>
						</div>
					</div>

					<div
						id="sidenav-overlay"
						className="sidenav-overlay"
						onClick={this.toggleMenu}
					></div>
				</div>
			</BrowserRouter>
		);
	}
}

export default Logged;
