import styled from "styled-components";

export const Container = styled.div`
    background: ${props => props.noBackground ? "none" : "rgba(0, 0, 0, 0.2)"};
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    & img {
        width: 200px;
    }
`;