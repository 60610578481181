import React from 'react';
import styled from 'styled-components';

const DownloadResources = ({ resources = [] }) => {
	return (
		<ResourcesContainer>
			{resources.map((item) => (
				<a
					key={`${item.index}_${item.name}`}
					href={item.url}
					rel="noopener noreferrer"
					target="_blank"
				>
					{item.name}
				</a>
			))}
		</ResourcesContainer>
	);
};

export default DownloadResources;

const ResourcesContainer = styled.div`
	align-items: center;
	display: flex;

	& > a {
		color: var(--gray-dark);
		display: block;
		margin: 8px 0;
		padding: 1em;
		padding-bottom: 8px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.14);
	}

	& > a:before {
		content: '>';
		margin-right: 8px;
		vertical-align: top;
	}
	@media (max-width: 960px) {
		& > a {
			color: #8a99b5;
		}
	}
`;
