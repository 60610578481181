import SimpleCard from 'components/SimpleCard';
import React from 'react';

const CodeNotFound = () => {
	return (
		<SimpleCard>
			<i className="bx bx-message-error algo" />
			<h1> El código que ingresaste no es válido o ya fue usado</h1>
		</SimpleCard>
	);
};

export default CodeNotFound;
