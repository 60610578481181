async function checkNid(firebase, nid, uid, oldNid){
    const same_document = await firebase.get("users", [{ field: "nid", condition: "==", value: nid }]);
    let isDocumentValid = true;
    console.log(same_document);

    if (oldNid === undefined || uid === undefined) {
        if (!same_document.empty) {
            isDocumentValid = false;
        }
    } else {
        if (!same_document.empty) {
            same_document.forEach((doc) => {
                if (doc.id !== uid) {
                    isDocumentValid = false;
                }
            });
        }
    }

    return new Promise((resolve, reject)=>{
        if (isDocumentValid) {
            resolve()
        } else{
            reject();
        }
    });
}

export default checkNid;