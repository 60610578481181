import React from 'react';
import Chart from './Chart';

import { ChartsContainer } from '../Styles';

const view = ({
	item,
	handleState,
	assemblyId,
	chartData,
	isReport = false,
}) => {
	return (
		<div className="p-3">
			<div className={isReport ? 'text-right' : ''}>
				<button
					className={
						'btn btn-primary mt-2 mb-1' + (isReport ? '' : ' btn-block')
					}
					onClick={() => handleState({ selectedQuestion: -1 })}
				>
					<i className="bx bx-arrow-back" style={{ float: 'left' }} /> Volver
				</button>
			</div>
			<ChartsContainer>
				{item.fields.map((field, index) => (
					<Chart
						data={chartData[index]}
						key={index}
						{...field}
						question={item}
						assemblyId={assemblyId}
						isReport={isReport}
					/>
				))}
			</ChartsContainer>
		</div>
	);
};

export default view;
