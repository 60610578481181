import React from "react";

import imgLogin from "assets/css/img/pages/not-authorized.png";

const View = ({ firebase, history }) => {
    React.useEffect(()=>{
        const cardContainer = document.querySelector("body div.col-xl-8.col-11");
        document.body.classList.toggle("not-authorized");
        cardContainer.classList.toggle("col-xl-8");
        cardContainer.classList.toggle("col-11");
        cardContainer.classList.toggle("col-xl-7");
        cardContainer.classList.toggle("col-md-8");
        cardContainer.classList.toggle("col-12");
        return ()=>{
            document.body.classList.toggle("not-authorized");
            cardContainer.classList.toggle("col-xl-8");
            cardContainer.classList.toggle("col-11");
            cardContainer.classList.toggle("col-xl-7");
            cardContainer.classList.toggle("col-md-8");
            cardContainer.classList.toggle("col-12");
        }
    }, []);

    return (
        <div className="card bg-transparent shadow-none">
            <div className="card-content">
                <div className="card-body text-center">
                    <img src={imgLogin} className="img-fluid" alt="not authorized" width="400" />
                    <h1 className="my-2 error-title">No estás autorizado para ingresar!</h1>
                    <p>Por favor comunicate con un adminsitrador para que autorice tu acceso</p>
                    <button onClick={()=>{  
                        firebase.doSignOut();
                    }} className="btn btn-primary round glow mt-2">Vuelve al inicio</button>
                </div>
            </div>
        </div>
        
    )
}

export default View;