import React from "react";
import passwordGenerator from "services/passwordGenerator";

import passwordValidator from "password-validator";

const schemaPassword = new passwordValidator();

schemaPassword
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase();

const AuthData = React.forwardRef(({ handleValidators, validators}, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);

    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");

    React.useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setShowPassword(false);
                setShowPassword2(false);
                setPassword("");
                setPassword2("");
            }
        }
    });

    React.useEffect(()=>{
        const isValid = schemaPassword.validate(password);
        handleValidators("password", isValid);
    }, [handleValidators, password]);

    React.useEffect(() => {
        handleValidators("password2", password === password2);
    }, [handleValidators, password, password2]);

    return (
        <div className="row">
            <div className="col-md-4">
                <label className="form-label">Correo electrónico</label>
            </div>
            <div className="col-md-8 form-group">
                <input className="form-control" type="email" name="email" onBlur={(e) => handleValidators("email", e.target.value !== "")} autoComplete="off"/>
                {((validators.email === true || validators.email === undefined) ? null : <small className="text-danger">El Correo electrónico no puede estar vacío</small>)}
            </div>

            <div className="col-md-4">
                <label className="form-label">Clave de acceso</label>
            </div>
            <div className="col-md-8 form-group">
                <div className="input-group">
                    <input className="form-control col-md-4" type={(showPassword ? "text" : "password")} name="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password"/>
                    <div className="input-group-append">
                        <button
                            onClick={() => {
                                const pass = passwordGenerator(12);
                                setPassword(pass);
                                setPassword2(pass);
                                setShowPassword(true)
                            }}
                            title={(showPassword ? "Ocultar" : "Ver") + " clave"}
                            className="btn btn-info btn-sm"
                            type="button">
                            <i className={`bx bx-key`} /> Generar
                        </button>
                        <button
                            onClick={() => setShowPassword(!showPassword)}
                            title={(showPassword ? "Ocultar" : "Ver") + " clave"}
                            className="btn btn-secondary"
                            type="button">
                            <i className={`bx bx-lock-${(showPassword ? "open-" : "")}alt`} />
                        </button>
                    </div>
                </div>
                {(
                    (validators.password === true || validators.password === undefined) 
                    ? null 
                    : <small className="text-danger">* La clave debe tener mínimo 8 caracteres, también debe tener mayúsculas y minúsculas</small>
                )}
            </div>

            <div className="col-md-4">
                <label className="form-label">Nuevamente la clave de acceso</label>
            </div>
            <div className="col-md-8 form-group">
                <div className="input-group">
                    <input className="form-control col-md-4" type={(showPassword2 ? "text" : "password")} name="password2" value={password2} onChange={(e) => setPassword2(e.target.value)} autoComplete="new-password"/>
                    <div className="input-group-append">
                        <button
                            onClick={() => setShowPassword2(!showPassword2)}
                            title={(showPassword2 ? "Ocultar" : "Ver") + " clave"}
                            className="btn btn-secondary"
                            type="button">
                            <i className={`bx bx-lock-${(showPassword2 ? "open-" : "")}alt`} />
                        </button>
                    </div>
                </div>
                {(
                    (validators.password2 === true || validators.password2 === undefined)
                        ? null
                        : <small className="text-danger">Las claves deben coincidir</small>
                )}
            </div>
        </div>
    )
});

export default AuthData;