import React from "react";

import Switch from "react-switch";

import defaultAvatar from "assets/img/user.png";

const Header = ({ toggleMenu, currentUser, darkMode, handleDarkMode }) => {
    const [isAtTop, setTopStatus] = React.useState(true);

    React.useEffect(() => {
        const handleScroll = () => {
            if (isAtTop) {
                if (window.scrollY > 15) {
                    setTopStatus(false);
                }
            } else {
                if (window.scrollY <= 15) {
                    setTopStatus(true);
                }
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [isAtTop]);

    React.useEffect(() => {
        const _body = document.getElementsByTagName("body");
        if (darkMode) {
            _body[0].classList.add("dark-layout");
        } else {
            _body[0].classList.remove("dark-layout");
        }
    }, [darkMode]);

    const { photoURL, displayName } = currentUser;
    return (
        <nav className={"header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top " + (isAtTop ? "" : "not-top")}>
            <div className="navbar-wrapper">
                <div className="navbar-container content">
                    <div className="navbar-collapse" id="navbar-mobile">
                        <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                            <ul className="nav navbar-nav">
                                <li className="nav-item mobile-menu d-xl-none mr-auto" onClick={toggleMenu}>
                                    <div className="nav-link nav-menu-main menu-toggle hidden-xs">
                                        <i className="ficon bx bx-menu"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <ul className="nav navbar-nav float-right">
                            <li className="dropdown dropdown-notification nav-item d-flex align-items-center">
                                <Switch
                                    checked={darkMode}
                                    onChange={(newState) => { handleDarkMode(newState) }}
                                    uncheckedIcon={<i className="bx bx-sun" />}
                                    checkedIcon={<i className="bx bxs-moon" />}
                                    className="dark-mode-switch"
                                />
                            </li>
                            <li className="dropdown dropdown-user nav-item">
                                <div className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                    <div className="user-nav d-sm-flex d-none">
                                        <span className="user-name">{displayName}</span>
                                        <small>{currentUser.email}</small>
                                    </div>
                                    <span>
                                        <img className="round" src={photoURL || defaultAvatar} alt="avatar" height="40" width="40" />
                                    </span>
                                </div>
                                <div className="dropdown-menu dropdown-menu-right pb-0"><a className="dropdown-item" href="page-user-profile.html"><i className="bx bx-user mr-50"></i> Edit Profile</a><a className="dropdown-item" href="app-email.html"><i className="bx bx-envelope mr-50"></i> My Inbox</a><a className="dropdown-item" href="app-todo.html"><i className="bx bx-check-square mr-50"></i> Task</a><a className="dropdown-item" href="app-chat.html"><i className="bx bx-message mr-50"></i> Chats</a>
                                    <div className="dropdown-divider mb-0"></div><a className="dropdown-item" href="auth-login.html"><i className="bx bx-power-off mr-50"></i> Logout</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}





export default Header;