import React from 'react';
import moment from 'moment';

import View from './view';

class FillSurvey extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			pendingForms: [],
		};
		this.itemsListener = () => {};
	}

	componentDidMount() {
		const { user } = this.props;

		if (user.transfer) {
			if (!user.transfer.approved) {
				this.getData();
			}
		} else {
			this.getData();
		}
	}
	getData = () => {
		const { firebase, user, id } = this.props,
			self = this;
		const items = [];
		const pendingForms = [];
		const _process = (newItem) => {
			items.push(newItem);

			self.props.setSurveyItems(items);
			if (!newItem.wasAnswered && newItem.isActive) {
				pendingForms.push(newItem);
			}
			self.setState({ items, pendingForms }, () => {});
		};

		this.itemsListener = firebase.getCollectionBySocket(
			'forms',
			id,
			'questions',
			(response) => {
				response.forEach((doc) => {
					const currentItem = doc.data();
					currentItem.id = doc.id;

					firebase
						.get('answers', id, currentItem.id, user.uid)
						.then((answerDoc) => {
							_process({ ...currentItem, wasAnswered: answerDoc.exists });
						});
				});
			}
		);
	};

	componentWillUnmount() {
		this.itemsListener();
	}
	updateOverview = (questions, answers) => {
		const { firebase, id, user } = this.props;
		let { coefficient = 0, another_coefficients = [] } = user;

		another_coefficients.forEach((_coefficient) => {
			coefficient = coefficient + _coefficient.coefficient;
		});

		console.log(user);
		let resumeRef = firebase.db
			.collection('answers')
			.doc(id)
			.collection(questions.id)
			.doc('overview');

		return firebase.db.runTransaction((t) => {
			return t.get(resumeRef).then((doc) => {
				let data = {};

				if (doc.exists) {
					data = doc.data();
				}

				questions.fields.forEach((question, index) => {
					if (question.type !== 'text' && question.type !== 'textarea') {
						if (data[index] === undefined) {
							data[index] = {};
						}

						if (data[index][answers[index]] === undefined) {
							data[index][answers[index]] = 0;
						}

						data[index][answers[index]] =
							data[index][answers[index]] + coefficient;
					}
				});

				if (doc.exists) {
					t.update(resumeRef, data);
				} else {
					t.set(resumeRef, data);
				}
			});
		});
	};
	onSave = (answers) => {
		const { pendingForms } = this.state,
			{ firebase, user, id } = this.props;

		const { another_coefficients = [] } = user;

		const self = this;

		answers = answers.map((item) =>
			Array.isArray(item) ? JSON.stringify(item) : item
		);

		return new Promise((resolve, reject) => {
			const data = {
				user_name: user.name + ' ' + (user.lastname || user.surname || ''),
				date: moment().unix(),
				answers,
			};

			firebase
				.set('answers', id, pendingForms[0].id, user.uid, data)
				.then(() => {
					self.updateOverview(pendingForms[0], answers).then(() => {
						const onSuccess = () => {
							pendingForms.shift();
							self.setState({ pendingForms }, resolve);
						};

						const limitIterations = another_coefficients.length;
						if (limitIterations) {
							let index = 0;
							another_coefficients.forEach((_coef) => {
								firebase
									.set('answers', id, pendingForms[0].id, _coef.uid, data)
									.then(() => {
										// eslint-disable-next-line
										index++;
										// eslint-disable-next-line
										if (index >= limitIterations) {
											onSuccess();
										}
									});
							});
						} else {
							firebase
								.set('answers', id, pendingForms[0].id, user.uid, data)
								.then(onSuccess);
						}
					});
				})
				.catch((error) => {
					console.error(error);
					reject();
				});
		});
	};
	render() {
		const { pendingForms = [] } = this.state;

		if (pendingForms.length === 0) {
			return null;
		} else {
			return (
				<View
					key={pendingForms[0].id}
					pendingForms={pendingForms}
					onSave={this.onSave}
				/>
			);
		}
	}
}

export default FillSurvey;
