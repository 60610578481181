import React from 'react';

import Loading from 'components/Loading';

import Table from 'components/Table';

const View = ({
	isActive,
	isLoading,
	onDelete,
	onEdit,
	onLaunch,
	onNewQuestionData,
	onSeeResults,
	loadingList = [],
	list = [],
}) => {
	if (isLoading) {
		return <Loading noBackground />;
	}
	return (
		<div className={'card' + (isActive ? '' : ' hide')}>
			<div className="card-content">
				<div className="card-body">
					<div className="display-flex space-between">
						<h3>
							<small>Listado de Preguntas: </small>
						</h3>
						<button
							className="btn btn-outline-primary"
							onClick={onNewQuestionData}
						>
							<i className="bx bx-plus" /> Nueva Pregunta
						</button>
					</div>
					<Table
						list={list}
						filterFunction={(record) =>
							`${record.name} ${record.identification} ${record.phone} ${record.address} ${record.owner_name} ${record.dateLabel}`
						}
						defaultMaxRecordPerPage={25}
						defaultSorter={{ parameter: 'date', order: 'DESC' }}
						iterateFunction={(record) => {
							const recordLoading = loadingList.indexOf(record.id) >= 0;
							return (
								<tr key={record.id} className={recordLoading ? ' loading' : ''}>
									<td style={{ width: '150px' }}>
										{recordLoading && (
											<i className="bx bx-loader-circle spin" />
										)}
										{!recordLoading && (
											<div className="display-flex space-between">
												<button
													title={record.isActive ? 'Detener' : 'Lanzar'}
													onClick={() => {
														onLaunch(record.id, !record.isActive);
													}}
													className={
														'btn btn-icon rounded-circle btn-outline-' +
														(record.isActive ? 'danger' : 'success') +
														' mr-1 mb-1'
													}
												>
													<i
														className={
															'bx bx-' + (record.isActive ? 'stop' : 'play')
														}
													/>
												</button>
												<button
													title="Editar"
													onClick={() => {
														onEdit(record.id);
													}}
													className="btn btn-icon rounded-circle btn-outline-primary mr-1 mb-1"
												>
													<i className="bx bx-edit" />
												</button>

												<button
													title="Eliminar"
													onClick={() => {
														onDelete(record.id);
													}}
													type="button"
													className="btn btn-icon rounded-circle btn-outline-danger mr-1 mb-1"
												>
													<i className="bx bx-trash" />
												</button>
											</div>
										)}
									</td>
									<td>{record.name}</td>
									<td>{record.dateLabel}</td>
									<td>
										<button
											onClick={() => onSeeResults(record.id)}
											className="btn btn-link"
										>
											Ver resultados
										</button>
									</td>
								</tr>
							);
						}}
						noDataMessage="No hay nada que coincida con tu búsqueda"
						fields={[
							{
								text: '',
							},
							{
								text: 'Nombre',
								parameter: 'name',
							},
							{
								text: 'Fecha de Creación',
								parameter: 'date',
							},
							{
								text: 'Resultados',
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
};

export default View;
