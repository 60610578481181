import React from 'react';

import SimpleCard from 'components/SimpleCard';

import PersonalData from './PersonalData';
import AuthData from './AuthData';

const View = ({
	onBack,
	handleState,
	handleValidators,
	validators,
	onSubmit,
	isLoading,
	type,
	references,
}) => {
	return (
		<React.Fragment>
			<button onClick={onBack} className="btn btn-primary">
				<i className="bx bx-left-arrow-alt" /> Volver
			</button>
			<div className="d-flex align-items-center mt-1 mb-1">
				<i className="bx bx-chevron-right" />
				<span>
					{' '}
					Nuevo{' '}
					{type === 'manager'
						? 'Jefe de Reunión'
						: type === 'owner'
						? 'Asistente'
						: 'Administrador'}
				</span>
			</div>
			<form onSubmit={onSubmit} className="mb-3" ref={references.form}>
				<SimpleCard>
					<PersonalData
						isUser={type === 'patient'}
						isDoctor={type === 'doctor'}
						handleState={handleState}
						validators={validators}
						handleValidators={handleValidators}
						references={references}
					/>
				</SimpleCard>

				<SimpleCard>
					<AuthData
						validators={validators}
						handleValidators={handleValidators}
						ref={references.authData}
					/>
				</SimpleCard>

				{type === 'admin' && <input type="hidden" name="profile" />}
				<div className="text-right">
					<button disabled={isLoading} className="btn btn-success">
						<i
							className={'bx bx-' + (isLoading ? 'loader-alt spin' : 'save')}
						/>{' '}
						Guardar
					</button>
				</div>
			</form>
		</React.Fragment>
	);
};

export default View;
