import swal from 'sweetalert';
import firebaseErrors from 'config/firebaseErrors.json';
import cryptPassword from 'services/cryptPassword';

class Login {
	constructor(props) {
		this.firebase = props.firebase;
		this.company = props.company;
	}

	doSignInWithEmailAndPassword(email, password) {
		const { firebase, company = {} } = this;
		return new Promise((resolve, reject) => {
			firebase
				.doSignInWithEmailAndPassword(email, password)
				.then(resolve)
				.catch((error) => {
					const { code = '123' } = error;

					if (code === 'auth/user-not-found' && company.id) {
						const userNotFound = (code = 'auth/user-not-found') => {
							swal('Opps!', firebaseErrors[code.toUpperCase()], 'error');
							reject(error);
						};
						firebase
							.get('viewers', company.id, 'registered', email.toLowerCase())
							.then((doc) => {
								if (doc.exists) {
									const cryp_password = cryptPassword.encode(password, 426);

									if (cryp_password === doc.data().password) {
										//const { name, surname, password } = doc.data();

										firebase.doSignInAnonymous().then((user) => {
											firebase.update(
												'viewers',
												company.id,
												'registered',
												email.toLowerCase(),
												{ uid: firebase.arrayUnion(user.user.uid) }
											);
										});
									} else {
										userNotFound('auth/wrong-password');
									}
								} else {
									userNotFound();
								}
							});
					} else {
						let message = firebaseErrors[code.toUpperCase()];
						if (message === undefined) {
							message = 'No es posible iniciar sesión en éste momento';
						}

						swal('Opps!', message, 'error');
						reject(error);
					}
				});
		});
	}
	doSignInWithGoogle() {
		const { firebase } = this;
		return firebase.doSignInWithGoogle();
	}
}

export default Login;
