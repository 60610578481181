import React from 'react';
import Chat from 'components/Chat';
import Player from 'components/Player';
import Surveys from 'components/Surveys';

import DownloadResources from 'components/DownloadResources';

import FillSurvey from 'components/FillSurvey';

import CountDown from './CountDown';

import {
	ChatContainer,
	CloseButton,
	NavContainer,
	PlayerContainer,
	RoomContainer,
	ViewersCounter,
} from './Styles';

const resourceOptions = [
	{ icon: 'bx bx-message', text: 'Chat' },
	{
		icon: 'bx bx-cloud-download',
		text: 'Recursos',
		element: () => <h1>Recursos</h1>,
	},
	{ icon: 'bx bx-pie-chart-alt', text: 'Votaciones' },
];

const View = ({
	firebase,
	isLoading,
	notFound,
	id = 'test-cargando',
	currentUser = {},
	assembly = {},
	usersOnline = 0,
	onGetQuorum,
}) => {
	const [selectedOption, setSelectedOption] = React.useState(0);
	const [surveyItems, setSurveyItems] = React.useState([]);

	if (isLoading) {
		return <h1>Cargando</h1>;
	}

	if (notFound) {
		return <h1>Evento no encontrado</h1>;
	}

	return (
		<React.Fragment>
			<RoomContainer>
				<CountDown endDate={assembly.startDate} />
				<PlayerContainer>
					<FillSurvey
						user={currentUser}
						firebase={firebase}
						id={id}
						setSurveyItems={setSurveyItems}
					/>
					<Player videoId={assembly.yt_code || ''} />
					<ViewersCounter>
						<small>Asistentes actuales:</small> <i className="bx bx-group" />{' '}
						{usersOnline > 0 ? usersOnline : 1}
						<small>
							<button onClick={onGetQuorum} className="btn btn-small">
								Revisar Quorum
							</button>
						</small>
					</ViewersCounter>
				</PlayerContainer>
				<ChatContainer className="room-chat">
					<CloseButton
						onClick={() => setSelectedOption(-1)}
						className={selectedOption === -1 ? ' hide ' : ''}
					>
						<i className="bx bx-x" />
					</CloseButton>
					<NavContainer className="nav nav-pills">
						{resourceOptions.map((item, index) => {
							const isActive = selectedOption === index;
							return (
								<li
									className="nav-item"
									onClick={() => setSelectedOption(index)}
									key={index}
								>
									<button
										className={
											'nav-link btn-link' + (isActive ? ' active' : '')
										}
										data-toggle="pill"
										aria-expanded={isActive ? 'true' : 'false'}
									>
										<i className={item.icon} /> <span>{item.text}</span>
									</button>
								</li>
							);
						})}
					</NavContainer>

					<Chat
						{...currentUser}
						firebase={firebase}
						id={id}
						isActive={selectedOption === 0}
					/>
					{selectedOption === 1 && (
						<DownloadResources resources={assembly.resources} />
					)}
					{selectedOption === 2 && (
						<Surveys
							firebase={firebase}
							id={id}
							currentUser={currentUser}
							items={surveyItems}
						/>
					)}
				</ChatContainer>
			</RoomContainer>
		</React.Fragment>
	);
};

export default View;
