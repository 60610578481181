import React from "react";
import moment from "moment";
import Switch from "react-switch";
import TimeInput from 'react-time-input';

import RepeatOptions from "./RepeatOptions";

import { JourneyModal } from "./Styles";

const Modal = ({ onClose, onSubmit, selectedDay })=>{
    const [startTime, setStartTime] = React.useState("00:00");
    const [endTime, setEndTime] = React.useState("00:00");
    const [duration, setDuration] = React.useState(50);
    const [appointments, setAppointments] = React.useState(0);
    const [canSave, handleCanSave] = React.useState(false);

    const [repeat, handleRepeat] = React.useState(false);

    const RepeatOptionsRef = React.useRef();

    const handleSubmit = React.useCallback(()=>{
        const { mode = false, until = false } = (RepeatOptionsRef.current ? RepeatOptionsRef.current : {});
        onSubmit({startTime, endTime, duration, repeat, repeatMode: mode, until});
    }, [duration, endTime, onSubmit, repeat, startTime]);

    React.useEffect(()=>{
        const 
            start = moment(startTime, "HH:mm"),
            end = moment(endTime, "HH:mm"),
            _duration = (isNaN(duration) ? 50 : parseInt(duration));

        const 
            difference = end.diff(start, "minutes");
        
        if (difference <= 0){
            handleCanSave(false);
        } else{
            if (_duration <= 0){
                handleCanSave(false);
            } else{
                const _appointments = Math.floor(difference/_duration);
                if (_appointments < 1){
                    handleCanSave(false);
                    setAppointments(0);
                } else{
                    handleCanSave(true);
                    setAppointments(_appointments);
                }
            }
        }
    }, [startTime, endTime, duration]);


    return (
        <JourneyModal>
            <div className="overlay" onClick={onClose}></div>
            <div className="journey-modal">
                <div className="header">
                    <h4 className="title">Agregar Jornada</h4>
                    <i className="bx bx-x" onClick={onClose} />
                </div>
                <div className="body">
                    <div className="row">
                        <div className="col-sm-12 text-right mb-1">
                            <small className="text-muted">* Las horas deben ingresarse en formato 24H</small>
                        </div>
                        <div className="col-sm-7">
                            <label className="form-label">Hora de Inicio</label>
                        </div>
                        <div className="col-sm-5 form-group">
                            <div className="input-group">
                                <TimeInput
                                    initTime={startTime}
                                    className='form-control'
                                    mountFocus={false}
                                    onTimeChange={(val) => { setStartTime(val) }}
                                />
                                <i className="bx bx-time" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-7">
                            <label className="form-label">Hora de Salida</label>
                        </div>
                        <div className="col-sm-5 form-group">
                            <div className="input-group">
                                <TimeInput
                                    initTime={endTime}
                                    className='form-control'
                                    mountFocus={false}
                                    onTimeChange={(val) => { setEndTime(val) }}
                                />
                                <i className="bx bx-time" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-7">
                            <label className="form-label">Duración de cada cita <small>(EN MINUTOS)</small></label>
                        </div>
                        <div className="col-sm-5 form-group">
                            <div className="input-group">
                                <input className="form-control" type="number" value={duration} onChange={(e) => setDuration(e.target.value)}/>
                                <i className="bx bx-timer" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {(!isNaN(appointments)) && (
                            <h5 className="col-sm-12 mt-1">{appointments} cita{appointments === 1 ? "" : "s"} disponible{appointments === 1 ? "" : "s"}</h5>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-sm-7">
                            <label className="form-label">Repetir</label>
                        </div>
                        <div className="col-sm-5 form-group">
                            <Switch onChange={(val) => handleRepeat(val)} checked={repeat} />
                        </div>
                    </div>

                    {(repeat) && (
                        <RepeatOptions ref={RepeatOptionsRef} selectedDay={selectedDay}/>
                    )}

                    <div className="text-right mt-1">
                        <button type="button" className="btn btn-text" onClick={onClose}><i className="bx bx-x"/> Cancelar</button>
                        <button type="button" disabled={!canSave} onClick={handleSubmit} className="btn btn-primary"><i className="bx bx-save" /> Guardar</button>
                    </div>
                </div>
            </div>
        </JourneyModal>
    )
}

export default Modal;