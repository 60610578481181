import React from 'react';

import passwordGenerator from 'services/passwordGenerator';

import { Container } from './Styles';

const View = ({ pendingForms = [], onSave }) => {
	const [isLoading, handleLoading] = React.useState(false);
	const [currentStep, handleStep] = React.useState(0);
	const [maxProgress, handleProgress] = React.useState(0);
	const [maxStep, handleMaxStep] = React.useState(
		pendingForms[0].fields.length - 1
	);
	const [answers, handleAnswers] = React.useState([]);

	const formElement = React.useRef(null);

	const { fields = [] } = pendingForms[0];

	const onMove = React.useCallback(
		(course) => {
			if (course === 'back') {
				handleStep(currentStep - 1);
			} else {
				const answer = formElement.current[`answer_${currentStep}`];
				let answerValue = '';
				if (fields[currentStep].type === 'multiple') {
					answerValue = [];
					for (let i = 0; i < answer.length; i++) {
						if (answer[i].checked) {
							answerValue.push(answer[i].value);
						}
					}
				} else {
					answerValue = answer.value;
				}

				if (answerValue.length > 0) {
					answers[currentStep] = answerValue;
					handleAnswers(answers);

					let newStep = currentStep + 1;

					if (newStep > maxProgress) {
						handleProgress(newStep);
					}
					handleStep(newStep);
				} else {
					try {
						answer.focus();
					} catch (error) {}
				}
			}
		},
		[answers, currentStep, fields, maxProgress]
	);

	const onSubmit = React.useCallback(
		(e) => {
			e.preventDefault();
			if (answers.length === pendingForms[0].fields.length) {
				handleLoading(true);

				onSave(answers)
					.then(() => {
						if (pendingForms[0]) {
							if (pendingForms[0].fields) {
								handleMaxStep(pendingForms[0].fields.length - 1);
							}
							handleStep(0);
							handleProgress(0);
							handleAnswers([]);
							handleLoading(false);
						}
					})
					.catch((error) => {
						console.log(error);
						handleLoading(false);
					});
			} else {
				onMove('next');
			}
		},
		[answers, onSave, pendingForms, onMove]
	);

	return (
		<Container>
			<form className="survey-content" ref={formElement} onSubmit={onSubmit}>
				<div className="header">
					<div>
						{currentStep > 0 && (
							<button
								type="button"
								onClick={() => onMove('back')}
								className="btn btn-outline-primary"
							>
								<i className="bx bx-chevron-left" />
							</button>
						)}
					</div>

					<div>
						{currentStep < maxProgress && (
							<button
								type="button"
								onClick={() => onMove('next')}
								className="btn btn-outline-primary"
							>
								<i className="bx bx-chevron-right" />
							</button>
						)}
					</div>
				</div>
				<div className="body">
					{fields.map((field, index) => {
						return (
							<div
								className={
									'form-item' + (currentStep === index ? ' active' : '')
								}
								key={index}
							>
								<h4 className="form-ask">{field.name}</h4>
								{field.type === 'text' && (
									<input
										type="text"
										className="form-control"
										name={`answer_${index}`}
										tabIndex={0}
									/>
								)}

								{field.type === 'date' && (
									<input
										type="date"
										className="form-control"
										name={`answer_${index}`}
									/>
								)}

								{field.type === 'time' && (
									<input
										type="time"
										className="form-control"
										name={`answer_${index}`}
									/>
								)}

								{field.type === 'number' && (
									<input
										type="number"
										className="form-control"
										name={`answer_${index}`}
									/>
								)}

								{field.type === 'textarea' && (
									<textarea
										rows={8}
										className="form-control"
										name={`answer_${index}`}
									/>
								)}

								{field.type === 'select' && (
									<div className="form-choices">
										{field.choices.map((choice, optIndex) => {
											const id = `opt_${passwordGenerator(16, true)}`;
											return (
												<div
													className="custom-control custom-radio"
													key={`${optIndex}_${choice}`}
												>
													<input
														type="radio"
														id={id}
														name={`answer_${index}`}
														value={choice}
													/>
													<label htmlFor={id}>{choice}</label>
												</div>
											);
										})}
									</div>
								)}

								{field.type === 'multiple' && (
									<div className="form-choices">
										{field.choices.map((choice, optIndex) => {
											const id = `opt_${passwordGenerator(16, true)}`;
											return (
												<div
													className="checkbox checkbox-info"
													key={`${optIndex}_${choice}`}
												>
													<input
														type="checkbox"
														className="checkbox-input"
														id={id}
														name={`answer_${index}`}
														value={choice}
													/>
													<label htmlFor={id}>{choice}</label>
												</div>
											);
										})}
									</div>
								)}
							</div>
						);
					})}
					<div
						className={
							'form-item align-items-center ' +
							(currentStep > maxStep ? ' active d-flex' : '')
						}
						style={{ height: '100%' }}
					>
						<p>
							Has finalizado el formulario, por favor dale click en el botón de
							finalizar para guardar tus respuestas
						</p>
					</div>
				</div>
				<div className="footer">
					{currentStep <= maxStep && (
						<button
							type="button"
							onClick={() => onMove('next')}
							className="btn btn-outline-primary"
						>
							<i className="bx bx-chevron-right" /> Continuar
						</button>
					)}
					{currentStep > maxStep && (
						<button
							type="submit"
							disabled={isLoading}
							className="btn btn-outline-success"
						>
							<i
								className={'bx bx-' + (isLoading ? 'loader-alt spin' : 'check')}
							/>{' '}
							Finalizar
						</button>
					)}
				</div>
			</form>
		</Container>
	);
};

export default View;
