import React from 'react';
import SimpleCard from 'components/SimpleCard';

const View = ({ user_data, onAccept, isLoading, wasAccepted }) => {
	console.log(user_data);
	const isCoefficientPlural = user_data.coefficient !== 1 ? 's' : '';

	return (
		<React.Fragment>
			<h1>Solicitud de transferencia de Poder</h1>
			<SimpleCard>
				{isLoading && <h5>Enviando tu aceptación</h5>}
				{!isLoading && !wasAccepted && (
					<React.Fragment>
						<p>
							<strong>
								{user_data.name} {user_data.surname}
							</strong>{' '}
							del <strong>{user_data.house} </strong> quiere que lo representes
							en la asamblea y uses su{isCoefficientPlural}{' '}
							{user_data.coefficient} voto{isCoefficientPlural}
						</p>

						<p>
							Si estás de acuerdo dale{' '}
							<button
								onClick={onAccept}
								disabled={isLoading}
								className="btn btn-success"
							>
								click aquí
							</button>
						</p>
						<p>En caso contrario, simplemente debes cerrar esta página</p>
					</React.Fragment>
				)}
				{!isLoading && wasAccepted && (
					<h5>
						Tu aceptación ha sido envíada, notificaremos a {user_data.name} de
						tu decisión, <small>Gracias,</small>
					</h5>
				)}
			</SimpleCard>
		</React.Fragment>
	);
};

export default View;
