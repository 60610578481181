import styled from 'styled-components';

export const MainContainer = styled.div`
	& .chat-content {
		height: calc(100% - 5rem);
		overflow-y: scroll;
	}
	& .chat-content .chat-list {
		height: auto;
		margin: 1rem 0 2rem;
	}

	& .chat-content .chat-list .chat-line {
		margin: 0.67rem 0 0 0;
		text-align: right;
		width: 100%;
	}

	& .chat-content .chat-list .chat-line .chat-owner {
		color: white;
		font-size: 0.8em !important;
		text-transform: capitalize;
	}

	.chat-content .chat-list .chat-line.left .chat-owner {
		color: var(--gray-dark) !important;
	}
	& .chat-content .chat-list .chat-line .chat-message {
		background: var(--primary);
		border-radius: 0.267rem;
		box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.6);
		clear: both;
		color: var(--white);
		float: right;
		margin: 0.2rem 0.2rem 1.8rem 0;
		max-width: calc(100% - 5rem);
		padding: 0.75rem 1rem;
		position: relative;
		text-align: right;
		word-break: break-word;
	}

	& .chat-content .chat-list .chat-line.left .chat-message {
		background-color: #fafbfb;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
		color: #727e8c;
		float: left;
		margin: 0.2rem 0 1.8rem 0.2rem;
		text-align: left;
	}

	& .chat-content .chat-list .chat-line .chat-message p {
		margin-bottom: 0;
	}
	& .chat-content .chat-list .chat-line .chat-message .chat-time {
		bottom: -20px;
		color: #828d99;
		font-size: 0.8rem;
		position: absolute;
		right: 0px;
		white-space: nowrap;
	}

	& .chat-footer {
		bottom: 0;
		position: absolute;
		width: calc(100% - 1rem);
	}
`;
