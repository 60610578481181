import React from 'react';

import cryptPassword from 'services/cryptPassword';
import getPassword from 'services/passwordGenerator';
import swal from 'sweetalert';
import moment from 'moment';
import * as XLSX from 'xlsx';

class LoadFromFile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			errorLog: '',
		};
	}
	onChangeFile = (event) => {
		const self = this;
		let reader = new FileReader();
		reader.readAsArrayBuffer(event.target.files[0]);
		let tmpUsers = [];
		reader.onloadend = (e) => {
			const data = new Uint8Array(e.target.result);
			var workbook = XLSX.read(data, { type: 'array' });
			workbook.SheetNames.forEach((sheetName) => {
				const XL_row_object = XLSX.utils.sheet_to_row_object_array(
					workbook.Sheets[sheetName]
				);
				tmpUsers = tmpUsers.concat(XL_row_object);
			});
			self.enqueueUsers(tmpUsers);
		};
	};
	enqueueUsers = (_users) => {
		const users = this.state.users.concat(_users);
		this.setState({ users });
	};

	processUsers = () => {
		const { users = [] } = this.state;
		const { firebase, eid, company } = this.props;
		const self = this;

		this.setState({ isUploading: true }, () => {
			self.props.handleState({ needDownloadUsers: true });
			let idx = 0;
			let errorLog = '';
			const assembly_data = {
				url: `${window.location.origin}/${company.url}`,
				date: moment
					.unix(company.startDate)
					.format('dddd DD [de] MMMM [de] YYYY'),
				hour: moment.unix(company.startDate).format('hh:mm a'),
			};
			const checkProcess = () => {
				idx++;

				if (idx % 300) {
					setTimeout(runProcess, 200);
				} else {
					setTimeout(runProcess, 10000);
				}
			};
			const runProcess = () => {
				if (idx < users.length) {
					const currentUser = users[idx];
					if (
						currentUser.Nombre &&
						currentUser.Apellido &&
						currentUser.Correo &&
						currentUser['Apartamento/Casa']
					) {
						const data = {
							name: currentUser.Nombre,
							surname: currentUser.Apellido,
							email: currentUser.Correo.toLowerCase(),
							house: currentUser['Apartamento/Casa'],
						};

						if (currentUser.Documento) {
							data.nid = `${currentUser.Documento}`;
						} else {
							data.nid = '';
						}

						if (currentUser.Coeficiente) {
							try {
								data.coefficient = parseFloat(currentUser.Coeficiente);
								if (isNaN(data.coefficient)) {
									data.coefficient = 1;
								}
							} catch (error) {
								data.coefficient = 1;
							}
						} else {
							data.coefficient = 1;
						}

						if (currentUser['Codigo de Acceso']) {
							data.password = `${currentUser['Codigo de Acceso']}`;
						} else {
							data.password = getPassword(8, true);
						}

						const _decryptedPassword = data.password;

						data.password = cryptPassword.encode(data.password, 426);
						firebase
							.set('viewers', eid, 'registered', data.email, data)
							.then(() => {
								firebase
									.sendEmail({
										eid,
										type: 'security',
										to: data.email,
										template: 'newUser',
										subject: `Datos de Acceso Asamblea ${company.name}`,
										additionalInfo: {
											name: data.name,
											user: data.email,
											password: _decryptedPassword,
											...assembly_data,
										},
									})
									.catch((error) => console.error(error));
								checkProcess();
							})
							.catch((error) => {
								console.log(error);
								errorLog += `\n el Usuario de la fila ${
									idx + 1
								} no será procesado debido a un problema con el servidor`;
								checkProcess();
							});
					} else {
						errorLog += `\n el Usuario de la fila ${
							idx + 1
						} no será procesado pues no tiene los campos necesarios`;
						checkProcess();
					}
				} else {
					self.setState({ isUploading: false, users: [], errorLog }, () => {
						swal('', 'La lista fue procesada', 'success');
					});
				}
			};
			runProcess();
		});
	};

	render() {
		const { isUploading } = this.state;
		return (
			<React.Fragment>
				<div className="text-right">
					<button
						className="btn btn-outline-primary"
						onClick={() => this.props.handleState({ needUpload: false })}
					>
						<i className="bx bx-arrow-back" /> Volver al listado
					</button>
				</div>
				<p>
					Si tienes dudas, puedes{' '}
					<a href="/PlantillaUsuarios.xlsx">descargar la plantilla </a>{' '}
					necesaria para el cargue de usuarios
				</p>
				<label htmlFor="txtViewers_InputFile">
					{' '}
					Carga aqui el listado de usuarios
				</label>
				<br />
				<input
					id="txtViewers_InputFile"
					type="file"
					onChange={this.onChangeFile}
					accept=".xlsx"
				/>
				<br /> <br />
				{this.state.users.length > 0 && (
					<React.Fragment>
						<p>
							se {isUploading ? 'están procesando' : 'procesarán'}{' '}
							<strong>{this.state.users.length}</strong> asistentes
						</p>
						<button
							className="btn btn-success"
							disabled={isUploading}
							onClick={this.processUsers}
						>
							<i
								className={isUploading ? 'bx-loader-alt spin' : 'bx bx-upload'}
							/>{' '}
							{isUploading ? 'Procesando' : 'Procesar'}
						</button>
					</React.Fragment>
				)}
				{this.state.errorLog !== '' && (
					<div className="">
						<p className="text-danger">{this.state.errorLog}</p>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default LoadFromFile;
