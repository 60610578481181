import React from "react";
import Dropzone from 'react-dropzone';
import { withFirebase } from "components/Firebase";

import swal from 'sweetalert';

import Loading from "components/Loading";

import { UploaderContainer, Dropzone as DropzoneContainer } from "./Styles";


class Uploader extends React.Component{
    constructor(props) {
        super(props);

        this.state = { 
            src: this.props.src, 
            isLoading: false
        };

        this.onDrop = this.onDrop.bind(this);
        this.onImageLoadError = this.onImageLoadError.bind(this);
    }
    onDrop(acceptedFiles) {
        const
            self = this,
            { uploadPath, name, firebase } = this.props;

        this.setState({ isLoading: true}, ()=>{
            acceptedFiles.map((file) => {
                const _processFile = () => {
                    firebase.uploadFile(uploadPath, file, name)
                        .then((response) => {
                            self.setState({ isLoading: false, src: response.url }, ()=>{
                              self.props.onSelect(response.url);
                            });
                        })
                        .catch(() => {
                            swal("Error", `En este momento no es posible cargar tu archivo, te recomendamos esperar unos momentos y reintentar ésta tarea`, "error")
                            .then(() => {
                                self.setState({isLoading: false});
                            });
                        });
                    return false;
                };
    
                if (typeof this.props.onValidation === "function") {
                    this.props.onValidation(file, _processFile);
                } else {
                    _processFile();
                }
                return true;
            });
        });
    }
    onImageLoadError(e){
        this.setState({src: false});
    }

    render() {
        const
            { src, isLoading } = this.state,
            { height = "14em" } = this.props;

        return (
            <UploaderContainer className={(isLoading ? "loading" : "")} height={height}>
                {isLoading ? (
                    <Loading loader />
                ) : (
                    <React.Fragment>
                        <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <DropzoneContainer>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps({
                                            accept: "image/*",
                                            multiple: false
                                        })}/>
                                        {src && (<img src={src} alt="profile" onError={this.onImageLoadError} />)}
                                        <small>Dale click aqui, o arrastra tu archivo</small>
                                    </div>
                                </DropzoneContainer>
                            )}
                        </Dropzone>
                    </React.Fragment>
                )}
            </UploaderContainer>
        );
    }
}

export default withFirebase(Uploader)
