import styled from "styled-components";

export const UploaderContainer = styled.div`
    height: ${props => props.height};
    padding: 1rem;
    position: relative;

    & button {
        margin-top: 2rem;
        float: right;
    }
`;

export const Dropzone = styled.section`
    border: 6px dashed rgba(0, 0, 0, 0.84);
    border-radius: 8px;
    height: 100%;
    position: relative;

    
    & > div{
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
    }

    & img{
        height: auto;
        max-height: 80%;
        max-width: 80%;
        width: auto;
    }
`;
