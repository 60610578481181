import React from 'react';
import moment from 'moment';

import { withFirebase } from 'components/Firebase';

import GuestRouter from './Guest';
import LoggedRouter from './Logged';

import Loading from 'components/Loading';

import momentSpanish from 'config/MomentSpanish';

import 'assets/css/vendors.min.css';
import 'assets/css/bootstrap.min.css';
import 'assets/css/bootstrap-extended.min.css';
import 'assets/css/colors.min.css';
import 'assets/css/components.min.css';

import 'assets/css/dark-layout.min.css';

import 'react-toastify/dist/ReactToastify.css';

import 'assets/css/animations.css';
import 'assets/css/styles.css';
import swal from 'sweetalert';

import email_default_data from 'screens/Settings/email.json';

const superadminsEmail = [
	'jhonathan2@gmail.com',
	'espanaonline2@gmail.com',
	'jsanchezarias@gmail.com',
];

class Setup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			authenticated: false,
			approved: true,
			isLoading: true,
			googleNewUser: false,
		};

		this.updateUser = this.updateUser.bind(this);
		moment.defineLocale('es', momentSpanish);
	}
	componentDidMount() {
		const self = this;
		const { firebase } = this.props;

		firebase.auth
			.getRedirectResult()
			.then(function (result) {
				if (result.credential) {
					const { additionalUserInfo = {}, user } = result,
						{
							providerId = '',
							profile = {},
							isNewUser = false,
						} = additionalUserInfo;

					if (isNewUser) {
						let name = '',
							lastname = '';

						if (additionalUserInfo.providerId === 'google.com') {
							const { given_name = '', family_name = '' } = profile;
							name = given_name;
							lastname = family_name;
							self.setState({ googleNewUser: true }, () => {
								firebase.doUpdateUser({ uid: user.uid, emailVerified: false });
							});
						}

						if (additionalUserInfo.providerId === 'facebook.com') {
							const { first_name = '', last_name = '' } = profile;
							name = first_name;
							lastname = last_name;
						}

						const userData = {
							name,
							lastname,
							email: user.email,
							isVerified: false,
							nid: '',
							creationDate: moment().unix(),
						};

						if (superadminsEmail.includes(user.email)) {
							userData.isVerified = true;
							userData.type = 'superadmin';
						}

						if (providerId !== '') {
							firebase.set('users', `${user.uid}`, userData);
						}
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});

		let { pathname } = window.location;
		if (pathname.startsWith('/admin')) {
			firebase
				.get('settings', 'company')
				.then((company) => {
					onAuthStateChanged({ company: company.data() });
				})
				.catch((error) => {
					console.error(error);
					setTimeout(() => {
						window.location.reload();
					}, 1500);
				});
		} else {
			firebase
				.get('assemblies', {
					field: 'url',
					condition: '==',
					value: pathname.split('/')[1].toLowerCase(),
				})
				.then((response) => {
					if (response.empty) {
						self.setState({
							authenticated: false,
							approved: false,
							notFound: true,
							currentUser: null,
							isLoading: false,
							company: {},
						});
					} else {
						response.forEach((a) => {
							onAuthStateChanged({ company: { ...a.data(), id: a.id } });
						});
					}
				})
				.catch((error) => {
					console.error(error);
					setTimeout(() => {
						window.location.reload();
					}, 1500);
				});
		}

		const onAuthStateChanged = ({ company }) => {
			firebase.auth.onAuthStateChanged((user) => {
				if (user) {
					if (superadminsEmail.includes(user.email)) {
						if (company.email === undefined) {
							const defaultCompanyData = {
								email: email_default_data,
							};

							firebase.update('settings', 'company', defaultCompanyData);
							company = { ...company, ...defaultCompanyData };
						}

						firebase.get('users', user.uid).then((docUser) => {
							self.setState({
								authenticated: true,
								currentUser: { ...user, isAdmin: true, ...docUser.data() },
								isLoading: false,
								company: company,
							});
						});
					} else {
						if (company.id) {
							let anonymousCounter = 0;
							const checkAnonymousUser = () => {
								firebase
									.get('viewers', company.id, 'registered', {
										field: 'uid',
										condition: 'array-contains',
										value: user.uid,
									})
									.then((res) => {
										if (res.empty) {
											if (anonymousCounter < 4) {
												anonymousCounter++;
												setTimeout(checkAnonymousUser, 200);
											} else {
												self.setState({
													authenticated: false,
													approved: false,
													currentUser: null,
													isLoading: false,
													company: company,
												});
											}
										} else {
											let viewer_data = {};
											res.forEach((doc) => {
												viewer_data = {
													...doc.data(),
													id: doc.id,
													uid: doc.id,
													type: 'user',
												};
											});

											self.setState({
												authenticated: true,
												currentUser: {
													...user,
													isAdmin: false,
													displayName: `${viewer_data.name} ${viewer_data.surname} (${viewer_data.house})`,
													...viewer_data,
												},
												isLoading: false,
												company: company,
											});
										}
									});
							};

							checkAnonymousUser();
						} else {
							firebase.get('users', user.uid).then(async (docUser) => {
								if (docUser.exists) {
									if (docUser.data().assembly) {
										if (!company.id) {
											const assemblyData = await firebase.get(
												'assemblies',
												docUser.data().assembly
											);
											company = { ...assemblyData.data(), id: assemblyData.id };
										}
									}
									self.setState({
										authenticated: true,
										currentUser: {
											...user,
											isAdmin: user.phoneNumber !== null,
											...docUser.data(),
										},
										isLoading: false,
										company: company,
									});
								} else {
									swal(
										'Error',
										'El usuario no ha sido encontrado, por favor revisa que accediste en la dirección correcta',
										'error'
									);
								}
							});
						}
					}
				} else {
					this.setState({
						authenticated: false,
						approved: true,
						currentUser: null,
						isLoading: false,
						company: company,
					});
				}
			});
		};
	}

	handleMainState = (data) => {
		this.setState(data);
	};

	updateUser(newData, callback) {
		this.setState(
			{ currentUser: { ...this.state.currentUser, ...newData } },
			callback
		);
	}

	render() {
		const { state } = this;
		if (state.isLoading) {
			return (
				<div style={{ height: '100vh' }}>
					<Loading />
				</div>
			);
		}
		if (state.authenticated) {
			return (
				<LoggedRouter
					{...state}
					doSignOut={this.props.firebase.doSignOut}
					firebase={this.props.firebase}
					updateUser={this.updateUser}
					handleMainState={this.handleMainState}
				/>
			);
		}
		return <GuestRouter {...state} firebase={this.props.firebase} />;
	}
}

export default withFirebase(Setup);
