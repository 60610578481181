import BasicData from './BasicData';
import LocationData from './LocationData';
import Emails from './Emails';
import Youtube from './Youtube';
import Downloads from './Downloads';
import Forms from 'screens/Forms';
import Viewers from 'components/Viewers';
import Terms from './Terms';
import Report from 'components/Report';

const viewOptions = [
	{
		icon: 'bx bx-user-pin',
		text: 'Identidad',
		component: BasicData,
		requireForSubmit: true,
	},
	{
		icon: 'bx bx-home-circle',
		text: 'Contacto',
		component: LocationData,
		requireForSubmit: true,
	},
	{
		icon: 'bx bx-envelope',
		text: 'Correos',
		component: Emails,
		requireForSubmit: true,
	},
	{
		icon: 'bx bxl-youtube',
		text: 'Transmisión',
		component: Youtube,
		requireForSubmit: true,
	},
	{
		icon: 'bx bx-download',
		text: 'Recursos',
		component: Downloads,
		requireForSubmit: true,
	},
	{
		icon: 'bx bx-badge',
		text: 'Términos',
		component: Terms,
		requireForSubmit: true,
	},
	{
		icon: 'bx bx-group',
		text: 'Asistentes',
		component: Viewers,
	},
	{
		icon: 'bx bx-spreadsheet',
		text: 'Preguntas',
		component: Forms,
	},
	{
		icon: 'bx bx-spreadsheet',
		text: 'Reporte',
		component: Report,
	},
];

export default viewOptions;
