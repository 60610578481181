import React from 'react';

import cryptPassword from 'services/cryptPassword';
import View from './view';

class SignUpViewers extends React.Component {
	constructor(props) {
		super(props);
		console.log(props);

		this.state = {
			needUpload: false,
			needDownloadUsers: true,
			registeredUsers: [],
		};
	}

	handleState = (data) => {
		this.setState(data);
	};

	getViewers = () => {
		const { firebase, eid } = this.props;
		const self = this;
		self.setState({ isDownloading: true }, () => {
			firebase
				.get('viewers', eid, 'registered')
				.then((response) => {
					const registeredUsers = [];

					response.forEach((element) => {
						registeredUsers.push({
							...element.data(),
							password: cryptPassword.decode(element.data().password, 426),
							id: element.id,
						});
					});

					self.setState({
						registeredUsers,
						isDownloading: false,
						needDownloadUsers: false,
					});
				})
				.catch((error) => {
					console.error(error);
					self.setState({ isDownloading: false });
				});
		});
	};

	render() {
		return (
			<View
				{...this.state}
				eid={this.props.eid}
				company={this.props.company}
				firebase={this.props.firebase}
				handleState={this.handleState}
				getViewers={this.getViewers}
			/>
		);
	}
}

export default SignUpViewers;
