import React from 'react';
import styled from 'styled-components';
import SimpleCard from 'components/SimpleCard';

const View = ({
	onSearch,
	isSearching,
	wasSearch,
	results,
	onRequest,
	isRequesting,
	wasRequested,
}) => {
	return (
		<React.Fragment>
			<SimpleCard>
				<h4 className="card-title">Transferir poder de voto</h4>
				<div className="col-md-12">
					<div className="row text-right">
						<div className="form-label-container col-md-5">
							<label className="form-label">Documento de identidad</label>
						</div>
						<SearchContainer
							className="form-control-container form-group col-md-7"
							onSubmit={onSearch}
						>
							<input
								autoComplete="off"
								className="form-control"
								type="text"
								style={{ width: 'calc(100% - 69px)' }}
								name="nid"
								placeholder="Número de documento de identificación"
							/>
							<button className="btn btn-success" disabled={isSearching}>
								<i
									className={
										'bx bx-' + (isSearching ? 'loader-circle spin' : 'search')
									}
								/>{' '}
								Buscar
							</button>
						</SearchContainer>
					</div>
				</div>
			</SimpleCard>
			{isSearching && (
				<SimpleCard>
					<i className={'bx bx-loader-circle spin'} /> Buscando
				</SimpleCard>
			)}

			{wasSearch && results.length === 0 && (
				<SimpleCard>
					<h3>No hay resultados con el numero de documento que ingresaste</h3>
				</SimpleCard>
			)}

			{wasSearch && results.length > 0 && (
				<SimpleCard>
					{results.map((item) => {
						return (
							<ResultItem key={item.id}>
								<div>
									<h4>
										{item.name} {item.surname} (<strong>{item.house}</strong>)
									</h4>
									<small>
										<i>{item.email}</i>
									</small>
								</div>
								<button
									className={
										'btn btn-' + (wasRequested ? 'success' : 'primary')
									}
									onClick={(event) => onRequest(item.name, item.email)}
									disabled={isRequesting || wasRequested}
								>
									<i
										className={
											'bx bx-' +
											(isRequesting
												? 'loader-circle spin'
												: wasRequested
												? 'check'
												: 'transfer')
										}
									/>{' '}
									{wasRequested
										? 'Solicitud enviada'
										: 'Solicitar recibir poder'}
								</button>
							</ResultItem>
						);
					})}
				</SimpleCard>
			)}
		</React.Fragment>
	);
};

export default View;

const SearchContainer = styled.form`
	& > input {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		display: inline-block;
		width: calc(100% - 140px) !important;
	}

	& > input ~ button {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		vertical-align: top;
	}
`;

const ResultItem = styled.div`
	display: flex;
	justify-content: space-between;
`;
