/**
 *  Encode a string using a reversible algorithm
 *
 * @param {string} _password - String for encoding
 * @param {number} key - Secuence to encode, must be a number of 3 digits
 * @returns {string} - encoded string
 */
function encodePassword(_password, key) {
	if (isNaN(key)) {
		console.error('Key must be a number');
		return false;
	}

	if (key < 100) {
		console.error('Key must have 3 digits minimum');
		return false;
	}

	const seed = _password.substr(0, 4),
		arrSeed = seed.split('');

	const keySeed = `${key}`.split('');

	for (let i = 0; i < 4; i++) {
		keySeed[i] = parseInt(keySeed[i], 10);
	}

	let b64Password = Buffer.from(_password).toString('base64');

	b64Password = `${arrSeed[0]}${b64Password}`;
	b64Password = `${b64Password.substr(0, keySeed[0])}${
		arrSeed[1]
	}${b64Password.substr(keySeed[0])}`;
	b64Password = `${b64Password.substr(0, keySeed[1])}${
		arrSeed[2]
	}${b64Password.substr(keySeed[1])}`;
	b64Password = `${b64Password.substr(0, keySeed[2])}${
		arrSeed[3]
	}${b64Password.substr(keySeed[2])}`;

	b64Password = Buffer.from(b64Password).toString('base64');

	return b64Password;
}

/**
 *  Decode a string from a reversible algorithm
 *
 * @param {string} _password - String for decoding
 * @param {number} key - Secuence to encode, must be a number of 3 digits
 * @returns {string} - encoded string
 */
function decodePassword(_password, key) {
	if (isNaN(key)) {
		console.error('Key must be a number');
		return false;
	}

	if (key < 100) {
		console.error('Key must have 3 digits minimum');
		return false;
	}

	const keySeed = `${key}`.split('');

	for (let i = 0; i < 4; i++) {
		keySeed[i] = parseInt(keySeed[i], 10);
	}

	_password = Buffer.from(_password, 'base64').toString('utf-8');
	_password = `${_password.substr(0, keySeed[2])}${_password.substr(
		keySeed[2] + 1
	)}`;
	_password = `${_password.substr(0, keySeed[1])}${_password.substr(
		keySeed[1] + 1
	)}`;
	_password = `${_password.substr(0, keySeed[0])}${_password.substr(
		keySeed[0] + 1
	)}`;
	_password = `${_password.substr(1)}`;
	_password = Buffer.from(_password, 'base64').toString('utf-8');

	return _password;
}
export default { encode: encodePassword, decode: decodePassword };
