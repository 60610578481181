import React from 'react';
import swal from 'sweetalert';
import moment from 'moment';

import checkNid from 'controllers/checkNid';

import View from './view';

class NewUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			icon: false,
			isLoading: false,
			specialties: [],
			validators: {},
		};

		this.references = {
			form: React.createRef(),
			authData: React.createRef(),
			groups: React.createRef(),
			changeIcon: React.createRef(),
		};
	}
	onBack = () => {
		this.props.history.goBack();
	};
	handleState = (data, callback) => {
		this.setState(data, () => {
			if (typeof callback === 'function') {
				callback();
			}
		});
	};
	handleValidators = (title, result) => {
		const { validators } = this.state;
		validators[title] = result;
		this.setState({ validators });
	};
	onSubmit = (e) => {
		e.preventDefault();
		const { validators } = this.state;
		const self = this;
		const {
			nid,
			name,
			lastname,
			email,
			password,
			password2,
			profile,
		} = e.target.elements;
		const defaultValidators = ['nid', 'name', 'email', 'password', 'password2'];

		const onNoValidators = (index) => {
			swal('', 'Debes completar los campos', 'error').then(() => {
				validators[index] = false;
				self.setState({ validators }, () => {
					switch (index) {
						case 'nid':
							nid.focus();
							break;
						case 'password':
							password.focus();
							break;
						case 'password2':
							password2.focus();
							break;
						case 'email':
							email.focus();
							break;
						case 'name':
							name.focus();
							break;
						default:
							break;
					}
				});
			});
		};

		let isValidated = true;
		for (let i = 0; i < defaultValidators.length; i++) {
			if (
				validators[defaultValidators[i]] === undefined ||
				validators[defaultValidators[i]] === false
			) {
				onNoValidators(defaultValidators[i]);
				isValidated = false;
				break;
			}
		}
		if (isValidated) {
			const { icon = false } = this.state;
			const { firebase, type } = this.props;

			self.setState({ isLoading: true }, () => {
				const data = {
					nid: nid.value,
					name: name.value,
					lastname: lastname.value,
					email: email.value,
					type,
					isVerified: true,
					creationDate: moment().unix(),
				};

				if (type === 'admin') {
					data.type = profile.value;
				}

				checkNid(firebase, nid.value)
					.then(() => {
						firebase
							.doCreateUser({
								email: data.email,
								emailVerified: true,
								password: password.value,
								displayName: `${data.name} ${data.lastname}`,
							})
							.then((userRecord) => {
								firebase.set('users', userRecord.uid, data).then(() => {
									const onSuccess = () => {
										self.setState({ isLoading: false }, () => {
											swal('', 'El usuario ha sido creado', 'success').then(
												() => {
													self.onReset();
												}
											);
										});
									};

									if (icon !== false) {
										firebase
											.uploadFile(`users/${userRecord.uid}`, icon, 'PROFILE')
											.then(onSuccess);
									} else {
										onSuccess();
									}
								});
							})
							.catch(function (error) {
								swal('Error', error, 'error').then(() => {
									self.setState({ isLoading: false });
								});
							});
					})
					.catch(() => {
						self.setState({ isLoading: false }, () => {
							swal(
								'Error',
								'El número de documento que ingresaste no es válido, si crees que esto es un error por favor comunicalo a un asesor',
								'error'
							).then(() => {
								nid.focus();
							});
						});
					});
			});
		}
	};
	onReset() {
		this.references.form.current.reset();
		this.references.authData.current.reset();
		this.references.changeIcon.current.reset();
	}
	render() {
		return (
			<View
				{...this.state}
				type={this.props.type}
				references={this.references}
				handleState={this.handleState}
				handleValidators={this.handleValidators}
				onBack={this.onBack}
				onSubmit={this.onSubmit}
			/>
		);
	}
}

export default NewUser;
