import React from 'react';
import { withRouter } from 'react-router';

import logo from 'assets/img/logo.png';

import { NavLink } from 'react-router-dom';

import Room from 'screens/Room';

import arrRoutes from './routes';

const Sidebar = ({
	company = {},
	toggleMenu,
	collapseMenu,
	doSignOut,
	location,
	isAdmin,
	currentUser = {},
	isCollapsed = false,
}) => {
	const [Routes, setRoutes] = React.useState([]);

	React.useEffect(() => {
		const arr = arrRoutes.filter((item) =>
			item.allowedBy.includes(currentUser.type || '')
		);

		if (!company.mainCompany) {
			arr.push({
				path: `/${company.url}`,
				name: 'Salón',
				icon: 'bx bx-play',
				component: Room,
				allowedBy: ['superadmin', 'admin', 'user'],
			});
		}

		setRoutes(arr);
	}, [currentUser, company.mainCompany, company.url]);

	const [isCollapsedHover, setCollapsedStatusHover] = React.useState(true);

	let { pathname = '/resumen' } = location;
	if (pathname === '/') {
		pathname = '/resumen';
	}

	const onHover = () => {
		if (isCollapsed) {
			setCollapsedStatusHover(!isCollapsedHover);
		}
	};

	React.useEffect(() => {
		setCollapsedStatusHover(false);
	}, [isCollapsed]);

	return (
		<div
			onMouseEnter={onHover}
			onMouseLeave={onHover}
			className={
				'main-menu menu-fixed menu-light menu-accordion menu-shadow' +
				(isCollapsedHover ? ' expanded' : '')
			}
			data-scroll-to-active="true"
		>
			<div className={'navbar-header' + (isCollapsedHover ? ' expanded' : '')}>
				<ul className="nav navbar-nav flex-row">
					<div className="navbar-brand">
						<div className="brand-logo">
							<img src={company.LOGO_64x || logo} alt="logo" />
						</div>
						<h2 className="brand-text mb-0">{company.name || 'VirtualMed'}</h2>
					</div>
					<li className="nav-item nav-toggle">
						<div
							className="nav-link modern-nav-toggle pr-0"
							data-toggle="collapse"
						>
							<i
								onClick={toggleMenu}
								className="bx bx-x d-block d-xl-none font-medium-4 primary toggle-icon"
							></i>
							<i
								onClick={collapseMenu}
								className={
									'toggle-icon bx font-medium-4 d-none d-xl-block collapse-toggle-icon primary ' +
									(isCollapsed ? 'bx-circle' : 'bx-disc')
								}
								data-ticon="bx-disc"
							></i>
						</div>
					</li>
				</ul>
			</div>
			<div className="shadow-bottom"></div>
			<div className="main-menu-content">
				<ul
					className="navigation navigation-main"
					id="main-menu-navigation"
					data-menu="menu-navigation"
					data-icon-style=""
				>
					{Routes.map((route, index) => {
						const { showOnMenu = true } = route;
						let authorized = route.requireAdmin ? isAdmin : true;
						if (showOnMenu && authorized) {
							return (
								<li
									className={
										'nav-item' +
										(route.path.includes(pathname) ? ' active' : '')
									}
									key={index}
								>
									<NavLink
										to={route.path.replace(
											':COMPANY_URL',
											company.url || 'admin'
										)}
									>
										<i className={route.icon}></i>
										<span className="menu-title">{route.name}</span>
									</NavLink>
								</li>
							);
						}

						return null;
					})}

					<li className="nav-item" style={{ marginTop: '50px' }}>
						<button
							style={{
								background: 'none',
								border: 'none',
								color: 'var(--dark)',
							}}
							onClick={doSignOut}
						>
							<i
								className="bx bxs-log-out"
								style={{
									float: 'left',
									fontSize: '1.4rem',
									marginRight: '1rem',
									width: '1.6rem',
								}}
							></i>
							<span className="menu-title">Salir</span>
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default withRouter(Sidebar);
