import React from 'react';

import SimpleCard from 'components/SimpleCard';

const View = ({ isActive = false, company = {} }) => {
	return (
		<div className={isActive ? '' : 'hide'}>
			<p>
				<b>Configuración del reproductor apra la transmisión</b>,{' '}
				<small>
					Estos datos los obtienes al crear la transmisión en vivo en Youtube
					studio
				</small>
			</p>
			<div className="row">
				<div className="col-md-12">
					<SimpleCard>
						<div className="col-md-6">
							<div className="form-group">
								<label className="col-form-label">
									Código de la transmisión:
								</label>
								<input
									className="form-control"
									type="text"
									placeholder="Ingresa el código para compartir que te da Youtube"
									name="yt_code"
									defaultValue={company.yt_code || ''}
								/>
							</div>
						</div>
					</SimpleCard>
				</div>
			</div>
		</div>
	);
};

export default View;
