import React from 'react';

const Player = ({ videoId = '' }) => {
	return (
		<div className="frame-container">
			<div className="player-top-band"></div>
			<iframe
				title={videoId}
				width="100%"
				height="100%"
				src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&autohide=1&showinfo=0&controls=0`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
			<div className="player-bottom-band"></div>
		</div>
	);
};

export default Player;

//nlf3wY33kcE
