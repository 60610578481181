import React from "react";

const Buttons = ({tabs, selectedTab, setSelectedTab}) => {
    if (tabs.length === 0){
        return null;
    }
    return  (
        <React.Fragment>
            {
                tabs.map((tab, index) => {
                    return (
                        <button key={index}
                            title={tab.label}
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "pre"
                            }}
                            className={"btn btn-block text-left " + (selectedTab === index ? "btn-primary" : "btn-outline-primary")}
                            onClick={() => setSelectedTab(index)}>
                            <i className={"mg-r-20 " + tab.icon} /> <span>{tab.label}</span>
                        </button>
                    )
                })
            }
        </React.Fragment>
    )
}

export default Buttons;