import React from 'react';
import swal from 'sweetalert';
import moment from 'moment';

import checkNid from 'controllers/checkNid';

import View from './view';

class UserInfo extends React.Component {
	constructor(props) {
		super(props);

		this.state = { loadingAvatar: false };
	}
	onChangeAvatar = (file) => {
		const { firebase, currentUser, updateUser, user } = this.props,
			{ uid } = user,
			self = this;

		this.setState({ loadingAvatar: true }, () => {
			firebase
				.uploadFile(`users/${uid}`, file, 'PROFILE')
				.then((response) => {
					if (uid === currentUser.uid) {
						updateUser({ photoURL: response.url }, () => {
							self.setState({ loadingAvatar: false });
						});
					} else {
						self.setState({ loadingAvatar: false });
					}
				})
				.catch((res) => {
					self.setState({ loadingAvatar: false });
					console.log(res);
				});
		});
	};
	onSubmit = (e) => {
		e.preventDefault();
		const onError = (field, message, endMessage = 'no puede estar vacío') => {
			swal('Error', `${message} ${endMessage}`, 'error').then(() => {
				field.focus();
			});
		};
		const {
				nid,
				name,
				lastname,
				borndate,
				address,
				neighborhood,
				city,
				phone,
			} = e.target.elements,
			{ user, firebase, currentUser } = this.props,
			self = this;

		if (nid.value !== '') {
			if (name.value !== '') {
				if (borndate.value !== '') {
					const borndate_moment = moment(borndate.value, 'YYYY-MM-DD');
					if (borndate_moment.isValid) {
						this.setState({ isLoading: true }, async () => {
							const data = {
								nid: nid.value,
								name: name.value,
								lastname: lastname.value,
								borndate: borndate_moment.toDate(),
								address: address.value,
								neighborhood: neighborhood.value,
								city: city.value,
								phone: phone.value,
							};

							checkNid(firebase, nid.value, user.uid, user.nid)
								.then(() => {
									firebase.update('users', user.uid, data).then(() => {
										if (currentUser.uid === user.uid) {
											self.props.updateUser(data);
										}

										self.setState({ isLoading: false }, () => {
											swal('', 'Los datos han sido guardados', 'success');
										});
									});
								})
								.catch(() => {
									onError(
										nid,
										'El número de documento que ingresaste no es válido, ',
										'si crees que esto es un error por favor comunicalo a un asesor'
									);
									self.setState({ isLoading: false });
								});
						});
					} else {
						onError(borndate, 'Debes ingresar', 'una fecha válida');
					}
				} else {
					onError(borndate, 'La fecha de nacimiento', 'no puede estar vacía');
				}
			} else {
				onError(name, 'El nombre ');
			}
		} else {
			onError(nid, 'El número de identificación');
		}
	};
	render() {
		return (
			<View
				{...this.state}
				user={this.props.user}
				onChangeAvatar={this.onChangeAvatar}
				onSubmit={this.onSubmit}
			/>
		);
	}
}

export default UserInfo;
