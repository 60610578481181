import React from "react";
import { Menu, Item, Separator, Submenu } from 'react-contexify';

import profiles from "config/profiles.json";

const UserMenu = ({ onStatus, onDelete, onProfile, canAddMoreDoctors }) => (
    <Menu id='menu_id' animation="zoom" >
        <Item onClick={onStatus}><i className="bx bx-user-pin" /> Cambiar Estado</Item>
        <Submenu label="Cambiar Perfil">
            {Object.keys(profiles).map((profile)=>{
                if (!canAddMoreDoctors){
                    if (profile === "doctor"){
                        return null
                    }
                }
                return <Item key={profile} onClick={({props}) => onProfile({props, profile})}>{profiles[profile]}</Item>
            })}
        </Submenu>
        <Separator />
        <Item onClick={onDelete}><i className="bx bx-user-x"/> Eliminar Usuario</Item>
    </Menu>
);

export default UserMenu;