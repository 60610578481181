import React from "react";
import moment from "moment";
import swal from "sweetalert";

import passwordGenerator from "services/passwordGenerator";

import View from "./view";

class Scheduler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf("week"),
            endDate: moment().endOf("week"),
            showedDays: []
        };
        this.timetablesByCalendar = {};
        this.allTimetables = [];
        this.searchs = [];
    }
    componentDidMount() {
        const { startDate, endDate } = this.state;
        this.onDateChange({ startDate, endDate });
    }
    addJourney = ({ startTime, endTime, duration, selectedDay, repeat, repeatMode, until }) => {
        const
            start = getTimeAsFloat(startTime),
            end = getTimeAsFloat(endTime),
            self = this;

        const
            { user, firebase } = this.props,
            { specialties = [] } = user;

        const data = [];
        const contextData = {
            from: start,
            until: end,
            duration: duration,
            specialties,
            doctor: user.uid,
            doctor_name: `${user.displayName}`,
            creation_date: moment().unix()
        };

        this.setState({ isLoading: true }, () => {
            if (repeat) {
                const iterableMoment = selectedDay.moment.clone();
                while (iterableMoment.isBefore(until)) {
                    data.push({ id: getId(), date: iterableMoment.unix(), ...contextData });
                    iterableMoment.add(1, repeatMode);
                }
                if (data.length === 0) {
                    data.push({ id: getId(), date: selectedDay.moment.unix(), ...contextData });
                }
            } else {
                data.push({ id: getId(), date: selectedDay.moment.unix(), ...contextData });
            }

            firebase.writeOnBatch("timetables", data)
                .then(() => {
                    self.allTimetables = [...self.allTimetables, ...data];
                    swal("", "El horario ha sido creado", "success");
                    self.sortSchedules();
                })
                .catch((error) => {
                    console.error(error);
                    swal("Algo salió mal", "Hubo un error en la generación del Horario, por favor revisa tus parámetros y vuelve a intentarlo", "error");
                });
        });

    }
    deleteJourney = (journey) => {
        let arrIndex = 0;
        for (let i = 0; i < this.allTimetables.length; i++){
            if (this.allTimetables[i].id === journey.id){
                arrIndex = i;
                break;
            }
        }
        const { firebase } = this.props;
        const self = this;

        swal({
            title: "Estás seguro",
            text: `de querer eliminar éste horario? ésta acción es irreversible`,
            icon: "warning",
            buttons: ["No", "Sí, eliminar!"],
            dangerMode: true
        })
        .then((will)=>{
            if (will){
                self.setState({isLoading: true}, ()=>{
                    firebase.delete("timetables", journey.id)
                    .then(()=>{
                        self.allTimetables.splice(arrIndex, 1);
                        swal("", "El horario ha sido eliminado", "success");
                        self.sortSchedules();
                    })
                    .catch(()=>{
                        swal("Algo salió mal", "No fue posible eliminar este horario, por favor revisa los parámetros e intentalo nuevamente", "error")
                        .then(()=>{
                            self.setState({ isLoading: false });
                        }); 
                    });
                });
            }
        })

        

    }
    getSchedules = () => {
        const self = this;
        const { startDate, endDate } = this.state;
        const { firebase, user } = this.props;
        const
            startDateUnix = startDate.unix(),
            endDateUnix = endDate.unix(),
            timetablesLength = Object.keys(self.allTimetables).length;

        const getFromDB = async (conditions) => {
            if (conditions.length === 0) {
                conditions = [[
                    { "field": "date", "condition": ">=", "value": startDateUnix },
                    { "field": "date", "condition": "<=", "value": endDateUnix }
                ]]
            }
            const _process = async ()=>{
                
                
                for (const condition of conditions){
                    const res = await firebase.get("timetables", [
                        { field: "doctor", condition: "==", value: user.uid },
                        condition[0], condition[1]
                    ]);

                    /*const res = [{
                        data: () => {return {
                            creation_date: 1588153748,
                            date: 1588654800,
                            doctor: "TR5FkIwTRhZqbyEEDsO3qYi32tv2",
                            doctor_name: "Jhonathan Espinosa",
                            duration: 50,
                            from: 8,
                            id: "6ukuIVfMAQEeKgjJgyHBmU2vwrHx",
                            until: 12.5
                        }}
                    }];*/

                    res.forEach((doc) => {
                        const _data = doc.data();
                        const dataExists = self.allTimetables.filter(item => item.id === _data.id);
                        if (dataExists.length === 0) {
                            self.allTimetables.push(_data);
                        }
                    });
                }
                return true;
            }
            
            await _process();
            self.sortSchedules();
        }

        if (timetablesLength === 0) {
            self.searchs.push({ start: startDateUnix, end: endDateUnix });
            getFromDB([]);
        } else {
            let needFirebase = true;
            const
                dates = [],
                toDelete = [];
            let
                newSearchs = [];
            
            for (let index = 0; index < self.searchs.length; index++){
                const search = self.searchs[index];
                if (startDateUnix >= search.start && endDateUnix <= search.end) {
                    newSearchs = [];
                    needFirebase = false;
                    break;
                } else {
                    if (endDateUnix <= search.start) {
                        handleNewCondition(dates, startDateUnix, endDateUnix);
                        if (endDateUnix === search.start){
                            search.start = startDateUnix;
                        } else{
                            handleNewCondition(newSearchs, startDateUnix, endDateUnix, "searchs");
                        }
                    } else {
                        if (startDateUnix < search.start && endDateUnix <= search.end) {
                            handleNewCondition(dates, startDateUnix, search.start);
                            search.start = startDateUnix;
                        } else {
                            if (startDateUnix >= search.start && startDateUnix <= search.end && endDateUnix > search.end) {
                                handleNewCondition(dates, search.end, endDateUnix);
                                search.end = endDateUnix;
                            } else {
                                if (startDateUnix >= search.end) {
                                    handleNewCondition(dates, startDateUnix, endDateUnix);
                                    if (startDateUnix === search.end) {
                                        search.end = startDateUnix;
                                    } else {
                                        handleNewCondition(newSearchs, startDateUnix, endDateUnix, "searchs");
                                    }
                                } else{
                                    if (startDateUnix < search.start && endDateUnix > search.end) {
                                        toDelete.push(index);
                                        handleNewCondition(dates, startDateUnix, search.start);
                                        handleNewCondition(dates, search.end, endDateUnix);
                                        handleNewCondition(newSearchs, startDateUnix, endDateUnix, "searchs");
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (needFirebase) {
                toDelete.forEach((index)=>{
                    self.searchs.splice(index, 1);
                })
                newSearchs.forEach((search) => {
                    self.searchs.push(search);
                });
                getFromDB(dates);
            } else {
                self.sortSchedules();
            }
        }
    }
    sortSchedules = () => {
        const self = this;
        const { startDate, endDate } = this.state;
        const
            startDateUnix = startDate.unix(),
            endDateUnix = endDate.unix();

        const _timetables = self.allTimetables.filter(item => item.date >= startDateUnix && item.date <= endDateUnix);

        _timetables.sort((a, b) => {
            if (a.date <= b.date && a.from <= b.from) {
                return -1;
            }

            if (a.date > b.date && a.from > b.from) {
                return 1;
            }

            return 0;
        })

        const timetables = {};

        _timetables.forEach((journey) => {
            const label = moment.unix(journey.date).format("YYYYMMDD");
            if (timetables[label] === undefined) {
                timetables[label] = [];
            }

            timetables[label].push({ ...journey });
        });

        self.timetablesByCalendar = { ...timetables };
        self.handle();
    }
    handleState = (data) => {
        this.setState(data);
    }
    handle = () => {
        const { startDate, endDate } = this.state;
        const labelFormat = "YYYYMMDD";
        const
            showedDays = [],
            _showedDays = {};

        let difference = endDate.diff(startDate, "days");
        let momentIndex = startDate.clone();

        for (let i = 0; i <= difference; i++) {
            const
                newMoment = momentIndex.clone().add(i, "days"),
                dayLabel = newMoment.format(labelFormat);

            if (_showedDays[dayLabel] === undefined) {
                _showedDays[dayLabel] = true;
                showedDays.push({ label: dayLabel, moment: newMoment });
            }
        }

        momentIndex = startDate.clone();

        while (momentIndex.format("dddd") !== "Domingo") {
            momentIndex.add(-1, "days");
            const
                newMoment = momentIndex.clone(),
                dayLabel = newMoment.format(labelFormat);

            showedDays.unshift({ label: dayLabel, moment: newMoment, isBlocked: true });
        }

        momentIndex = endDate.clone();
        while (momentIndex.format("dddd") !== "Sabado") {
            momentIndex.add(1, "days");
            const
                newMoment = momentIndex.clone(),
                dayLabel = newMoment.format(labelFormat);

            showedDays.push({ label: dayLabel, moment: newMoment, isBlocked: true });
        }

        const timetables = {};
        for (let day in this.timetablesByCalendar) {
            const dayLabel = moment(day, "YYYYMMDD").format(labelFormat);

            if (timetables[dayLabel] === undefined) {
                timetables[dayLabel] = [...this.timetablesByCalendar[day]];
            } else {
                timetables[dayLabel] = timetables[dayLabel].concat(this.timetablesByCalendar[day]);
            }
        }
        this.setState({ timetables, showedDays, isLoading: false });
    }
    handleDatesMove = (course) => {
        let { startDate, endDate } = this.state;

        const sign = (course === "next" ? 1 : -1);

        let difference = (endDate.diff(startDate, "days") + 1); 
        if (difference >= 30){
            difference = difference * sign;
            
            if (startDate.format("YYYYMMDD") === moment(startDate).startOf("month").format("YYYYMMDD") && endDate.format("YYYYMMDD") === moment(endDate).endOf("month").format("YYYYMMDD")){
                startDate = startDate.add((1 * sign), "month").startOf("month");
                endDate = endDate.add((1 * sign), "month").endOf("month");
            } else{
                startDate.add(difference, "days");
                endDate.add(difference, "days");
            }
        } else{
            difference = difference * sign;
            startDate.add(difference, "days");
            endDate.add(difference, "days");
        }
        this.onDateChange({ startDate, endDate });
    }
    onDateChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate, isLoading: true }, () => {
            this.getSchedules();
        });
    }
    render() {
        return (
            <View {...this.state}
                addJourney={this.addJourney}
                deleteJourney={this.deleteJourney}
                handleState={this.handleState}
                handleDatesMove={this.handleDatesMove}
                onDateChange={this.onDateChange}
            />
        )
    }
}

export default Scheduler;

const getTimeAsFloat = (strTime) => {
    const arr = strTime.split(":");
    if (arr.length === 2) {
        const hour = parseInt(arr[0]);
        const minutes = parseInt(arr[1]) / 60;
        return hour + minutes;
    }
    return false;
}

const getId = () => {
    return passwordGenerator(28, true)
}

const handleNewCondition = (arr, start, end, arrType = "dates") => {
    let canSave = true;
    if (arrType === "dates"){
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][0].value === start && arr[i][1].value === end) {
                canSave = false;
                break;
            }
        }
        if (canSave){
            arr.push([
                { "field": "date", "condition": ">=", "value": start },
                { "field": "date", "condition": "<=", "value": end }
            ]);
        }
    }

    if (arrType === "searchs") {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].start === start && arr[i].end === end) {
                canSave = false;
                break;
            }
        }
        if (canSave) {
            arr.push({"start": start, "end": end});
        }
    }
}