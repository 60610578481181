import styled from "styled-components";

export const FieldContainer = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    padding: 1rem 0;
    width: 90%;
    margin-left: 10%;
    margin-right: 15px;

    & .field-header i,
    & .choices-actions i{
        opacity: 0.6;
        cursor: pointer;
        font-size: 2em;
        margin-bottom: 1em;
        margin-left: 1em;
        transition: 0.2s;
    }

    & .field-header i:hover,
    & .choices-actions i:hover{
        opacity: 1;
    }

    & h6{
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    & ul{
    list-style-type:none; 
    }

    & ul li {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }

    & ul li .choices-actions{
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    & ul li .choices-actions i{
        margin-bottom: 0 !important;
    }

    & ul li i:hover{
        opacity: 1;
    }

    & ul li input{
        flex: 1;
    }
`;