import styled from "styled-components";

export const Header = styled.div`
    & {
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: 620px){
        & {
            flex-direction: column;
        }
        
        &  > div:first-child{
            margin-bottom: 1rem;
        }
    }
`;

export const TimetableContainer = styled.div`
    &-container{
        display: flex;
        flex-wrap: wrap;
    }
    & {
        display: inline-block;
        padding: 0 4px;
        width: calc(100% / 7);
        vertical-align: top;
    }

    & .header{
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);
        border-top: 1px solid rgba(0, 0, 0, 0.14);
        display: flex;
        justify-content: space-between;
        padding: 4px 0;
    }

    & > .header > div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & > .header > div > h4{
        line-height: 1;
        font-size: 1rem;
        margin: 0;
    }

    & > .header > button{
        position: relative;
    }
    & > .header > button > span{
        display: none;
        background: var(--white);
        border: 1px solid var(--primary);
        border-radius: 4px;
        color: var(--gray);
        margin-top: 28px;
        padding: 4px;
        position: absolute;
        right: 0;
        width: 120px;
        z-index: 2;
    }
    & > .header > button > span i{
        color: var(--primary);
    }
    & > .header > button:hover > span{
        display: inline-block;
    }

    & .timetables{
        margin: 8px 0;
        min-height: 4em;
    }

    & .timetables .timetable-container{
        align-items: center;
        display: inline-flex;
        margin: 4px 0;
        position: relative;
        width: 100%;    
    }

    & .timetables .timetable-container .timetable{
        position: relative;
    }

    & .timetables .timetable-container .timetable > div{
        background-color: #CCF5F8;
        border-left: 3px solid var(--cyan);
        border-radius: 4px;
        color: var(--cyan);
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        width: auto;
    }

    & .timetables .timetable-container .timetable:hover{
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.544);
    }

    & .timetables .timetable-container .timetable > div > div{
        align-items: center;
        display: inline-flex;
    }
    & .timetables .timetable-container .timetable > div > div > *{
        display: inline-block;
    }
    & .timetables .timetable-container .timetable > div > div > i{
        padding: 4px 6px;
    }

    & .timetables .timetable-container .timetable > div > span{
        font-size: .9rem;
        font-weight: 300;
        padding: 4px 2px 1px 4px;
    }

    & .timetables .timetable-container .timetable > button.btn-delete{
        align-items: center;
        background: var(--danger);
        border: none;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        color: white;
        display: none;
        height: 100%;
        font-size: inherit;
        position: absolute;
        top: 0;
        right: 0;
    }

    & .timetables .timetable-container .timetable:hover  > button.btn-delete{
        display: flex;
    }
    & .timetables .timetable-container .timetable > button.btn-delete > span{
        display: none;
    }
    & .timetables .timetable-container .timetable > button.btn-delete:hover > span{
        display: inline-block;
    }
    &.main-header{
        margin-top: 1rem;
    }
    &.main-header .header{
        border-bottom: none;
        padding: 8px 0;
    }

    &.is-blocked{
        color: rgba(0, 0, 0, 0.28);
        background: rgba(0, 0, 0, 0.18);
    }

    @media (max-width: 740px){
        & > .header{
            flex-direction: column;
        }   
        
        & > .header > div{
            width: 100%;
        }
    }

    @media (max-width: 690px ){
        & .timetables .timetable-container .timetable > div > div > i{
            display: none;
        }
    }

    @media (max-width: 430px ){
        & .timetables .timetable-container .timetable > div > div > span{
            font-size: 0.8rem;
        }   
    }

    @media (max-width: 370px ){
        & .timetables .timetable-container .timetable > div > span{
            font-size: 0.6rem;
        }
    }
`;


export const Loader = styled.div`
    align-items: center;
    background: rgba(0, 0, 0, 0.34);
    color: var(--white);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    & i{
       font-size: 64px;   
    }
`;

export const JourneyModal = styled.div`
    &{
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        min-height: 420px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    & .overlay{
        background: rgba(0, 0, 0, 0.24);
        cursor: pointer;
        height: 100%;
        position: absolute;
        width: 100%;   
    }

    & .journey-modal{
        background: white;
        border-radius: 4px;
        width: 420px;
        z-index: 2;
    }

    & .journey-modal .header{
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    & .journey-modal .header .title{
        padding: 4px 8px;
        font-weight: 300;
        font-size: 1rem;
    }

    & .journey-modal .header > i.bx-x{
        cursor: pointer;
        opacity: 0.4;
        padding: 8px;
    }

    & .journey-modal .header > i.bx-x:hover{
        opacity: 1;
    }

    & .journey-modal .body{
        padding: 1rem;
    }

    & .journey-modal .body > .row{
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        display: flex;
        flex-wrap: wrap;
        margin: 4px 1rem;
        padding-bottom: 4px;
    }

    & .journey-modal .body .form-group{
        margin: 0;
    }
    & .journey-modal .body .form-group .form-control{
        padding: .47rem .8rem .47rem 40px !important; 
        
    }

    & .journey-modal .body .form-group i{
        position: absolute;
        color: inherit;
        padding: 10px;
        z-index: 9;
    }

    & .journey-modal .body .form-group .form-control:focus~i{
        color: var(--primary);
        font-weight: 700;
    }
`;