import React from 'react';
import View from './view';
import swal from 'sweetalert';

class Room extends React.Component {
	constructor(props) {
		super(props);

		const { match = {} } = props,
			{ params = {} } = match,
			{ uid = false } = params;

		this.state = {
			id: '_',
			url: uid,
			assembly: {},
			notFound: false,
			isLoading: true,
			usersOnline: 0,
			representedPeople: 1,
		};

		this._isMounted = false;
	}
	componentDidMount() {
		this._isMounted = true;
		/*if (!this.props.menuIsCollapsed) {
			this.props.collapseMenu();
		}*/

		const { url = false } = this.state;
		if (url) {
			this.getAssembly();
			this.getQuorum();
		} else {
			this.props.history.push('/');
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
		if (this.props.menuIsCollapsed) {
			//this.props.collapseMenu();
		}

		const { representedPeople = 1 } = this.state;

		this.incrementQuorum(representedPeople * -1);
	}

	getQuorum = () => {
		const { firebase, currentUser } = this.props;
		const { another_coefficients = [] } = currentUser;
		const { id = '_' } = this.state;
		const self = this;

		if (id !== '_') {
			firebase.initPresence(
				`inRoom/${id}`,
				{ isConnected: true, people: another_coefficients.length + 1 },
				{ isConnected: false, people: another_coefficients.length + 1 }
			);
			self.incrementQuorum(another_coefficients.length + 1);
		} else {
			setTimeout(this.getQuorum, 500);
		}
	};
	incrementQuorum = (value = 1) => {
		const { firebase } = this.props;
		const { id = '_' } = this.state;
		const self = this;

		if (id !== '_') {
			firebase.db
				.collection('assemblies')
				.doc(id)
				.update({
					usersOnline: firebase.FieldValue.increment(value),
				})
				.catch((err) => {
					console.error(err);
					setTimeout(() => {
						self.incrementQuorum(value);
					}, 1000);
				});
		} else {
			if (this._isMounted) {
				setTimeout(() => {
					self.incrementQuorum(value);
				}, 1000);
			}
		}
	};
	onGetQuorum = () => {
		const { firebase } = this.props;
		const { id = '_' } = this.state;
		if (id !== '_') {
			firebase.realtimedatabase
				.ref(`inRoom/${id}`)
				.once('value')
				.then((snapshot) => {
					let connectedUsers = 0;
					snapshot.forEach((doc) => {
						if (doc.val().isConnected) {
							const { people = 1 } = doc.val();
							connectedUsers = connectedUsers + people;
						}
					});
					firebase.db.collection('assemblies').doc(id).update({
						usersOnline: connectedUsers,
					});
				});
		}
	};

	getAssembly = () => {
		const { firebase, currentUser } = this.props;
		const { another_coefficients = [] } = currentUser;
		const { url = '' } = this.state;
		const self = this;

		firebase.getCollectionBySocket(
			'assemblies',
			{ field: 'url', condition: '==', value: url.toLowerCase() },
			(response) => {
				if (response.empty) {
					swal('', 'La url que ingresaste no es válida', 'error');
				} else {
					response.forEach((doc) => {
						self.setState(
							{
								assembly: doc.data(),
								id: doc.id,
								isLoading: false,
								usersOnline: doc.data().usersOnline || 0,
								representedPeople: another_coefficients + 1,
							},
							() => {
								//
							}
						);
					});
				}
			}
		);
	};

	render() {
		if (this.state.id === '_') {
			return <h5>Cargando...</h5>;
		}
		return (
			<View
				{...this.state}
				firebase={this.props.firebase}
				currentUser={this.props.currentUser}
				onGetQuorum={this.onGetQuorum}
			/>
		);
	}
}

export default Room;
