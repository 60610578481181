import React, {Component} from "react";
import ChangeIcon from "./ChangeIcon";

class ChangeIconClass extends Component {
    constructor(props) {
        super(props);
        this.state = { src : props.src }
        this.onChange = this.onChange.bind(this);
    }
    onChange = (event) => {
        if (event.target.files[0]) {
            const _file = event.target.files[0];

            this.props.onChangeFile(_file);
            this.setState({src : URL.createObjectURL(_file)});
        }
    }
    reset = () => {
        this.setState({ src: this.props.src });
    }
    render() { 
        const {src} = this.state;
        const { size = 60, isLoading = false, accept = ".jpeg,.jpg,.png" } = this.props;
        return ( 
            <ChangeIcon src={src} onChange={this.onChange} size={size} isLoading={isLoading} accept={accept}/>
         );
    }
}
 
export default ChangeIconClass;