import React from 'react';
import moment from 'moment';

import { PeopleTableContainer } from './Styles';

const PeopleTable = ({ title, zeroMessage = '', data = [], users = {} }) => {
	return (
		<PeopleTableContainer>
			<h4>{title}</h4>
			{data.length === 0 && <div className="alert">{zeroMessage}</div>}
			{data.length && (
				<table className="table">
					<thead>
						<tr>
							<th>Documento</th>
							<th>Nombre</th>
							<th>Apellido</th>
							<th>Propiedad</th>
							<th>Cedido a</th>
							<th>Fecha</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => (
							<tr key={users[row].email}>
								<td>{users[row].nid}</td>
								<td>{users[row].name}</td>
								<td>{users[row].surname}</td>
								<td>{users[row].house}</td>
								<td>
									{users[users[row].transfer.approved_user].name}{' '}
									{users[users[row].transfer.approved_user].surname} (
									{users[users[row].transfer.approved_user].house})
								</td>
								<td>
									{moment
										.unix(users[row].transfer.approved_date)
										.format('YYYY-MM-DD hh:mm a')}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</PeopleTableContainer>
	);
};

export default PeopleTable;
