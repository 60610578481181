import React from "react";

import { NavLink } from "react-router-dom";

import imgRegister from "assets/css/img/pages/forgot-password.png";

const View = ({isLoading, onSubmit}) => {
    return (
        <React.Fragment>
            <div className="col-md-6 col-12 px-0">
                <div className="card disable-rounded-right d-flex justify-content-center mb-0 p-2 h-100">
                    <div className="card-header pb-1">
                        <div className="card-title">
                            <h4 className="text-center mb-2">Recuperar tu clave</h4>
                        </div>
                    </div>
                    <div className="card-content">
                        <div className="card-body">
                            <form className="mb-2" onSubmit={onSubmit}>
                                <div className="form-group">
                                    <label className="text-bold-600">Dirección de correo</label>
                                    <input type="email" name="email" className="form-control" placeholder="Pon aquí tu dirección de correo" />
                                </div>
                                <button type="submit" disabled={isLoading} className="btn btn-primary glow position-relative w-100">Recuperar mi clave
                                    <i id="icon-arrow" className={"bx " + (isLoading ? "bx-loader-alt spin" : "bx-right-arrow-alt")}></i>
                                </button>
                            </form>
                            <hr />
                            <div className="text-center">
                                <small className="mr-25">No tienes una cuenta?</small>
                                <NavLink to="/registro"><small>Registrate</small></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                <img className="img-fluid" src={imgRegister} alt="branding logo" />
            </div>
        </React.Fragment>
    )
}

export default View;