import moment from 'moment';

class Tick {
	constructor({ endDate, callback = () => {}, interval = 60000 }) {
		this.endDate = moment.unix(endDate);
		this.callback = callback;
		this.intervalTime = interval;

		this.isActive = true;
	}
	destroy() {
		this.isActive = false;
	}
	start() {
		const __difference = this.endDate.diff(new Date(), 'seconds'),
			isOutOfDate = __difference < 0,
			_difference = Math.abs(__difference);

		const _days = Math.floor(_difference / (60 * 60 * 24)),
			_hours = Math.floor((_difference % (60 * 60 * 24)) / (60 * 60)),
			_minutes = Math.floor((_difference % (60 * 60)) / 60),
			_seconds = Math.floor(_difference % 60),
			_data = {};

		_data.days = _days > 9 ? _days : `0${_days}`;
		_data.hours = _hours > 9 ? _hours : `0${_hours}`;
		_data.minutes = _minutes > 9 ? _minutes : `0${_minutes}`;
		_data.seconds = _seconds > 9 ? _seconds : `0${_seconds}`;
		_data.difference = __difference;
		_data.isOutOfDate = isOutOfDate;

		this.callback(_data);

		setTimeout(() => {
			if (this.isActive) {
				this.start();
			}
		}, this.intervalTime);
	}
}

export default Tick;
