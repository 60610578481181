import React from 'react';
import moment from 'moment';
import swal from 'sweetalert';

import View from './view';

class Forms extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			loadingList: [],
			list: [],
		};
		this.getForms = this.getForms.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}
	componentDidMount() {
		this.getForms();
	}
	getForms() {
		const { firebase } = this.props,
			self = this;
		firebase.get('assemblies').then((response) => {
			const list = [];
			if (!response.empty) {
				response.forEach((doc) => {
					const data = doc.data();
					list.push({
						...data,
						dateLabel: moment
							.unix(data.creationDate)
							.format('DD [de] MMM [de] YYYY - HH:mm'),
						id: doc.id,
					});
				});
				self.setState({ isLoading: false, list });
			} else {
				self.setState({ isLoading: false });
			}
		});
	}
	onDelete(uid) {
		const { loadingList } = this.state,
			{ firebase } = this.props,
			self = this;

		swal({
			title: '',
			text: `¿Estas seguro que quieres borrar ésta asamblea?, esto no podrá revertirse`,
			icon: 'warning',
			buttons: ['No', 'Sí, eliminar!'],
			dangerMode: false,
		}).then((will) => {
			if (will) {
				loadingList.push(uid);
				self.setState({ loadingList, isLoading: true }, () => {
					const index = loadingList.indexOf(uid);
					firebase.delete('assemblies', uid).then(() => {
						loadingList.splice(index, 1);
						const { list } = self.state;

						let idx = -1;
						for (let i = 0; i < list.length; i++) {
							if (list[i].id === uid) {
								idx = i;
								break;
							}
						}
						if (idx >= 0) {
							list.splice(idx, 1);
						}
						self.setState({ list, loadingList, isLoading: false });
					});
				});
			}
		});
	}
	render() {
		return <View {...this.state} onDelete={this.onDelete} />;
	}
}

export default Forms;
