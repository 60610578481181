import React from 'react';

const ChatMesage = ({ message, time, isInLeft = false, displayName = '' }) => {
	return (
		<div className={'chat-line' + (isInLeft ? ' left' : '')}>
			<div className="chat-message">
				<label className="chat-owner">{displayName}</label>
				<p>{message}</p>
				<span className="chat-time">{time}</span>
			</div>
		</div>
	);
};

export default ChatMesage;
