const removeAccents = (s) => {
	let r = s.toLowerCase().trim();
	r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
	r = r.replace(new RegExp(/[èéêë]/g), 'e');
	r = r.replace(new RegExp(/[ìíîï]/g), 'i');
	r = r.replace(new RegExp(/[òóôõö]/g), 'o');
	r = r.replace(new RegExp(/[ùúûü]/g), 'u');
	r = r.replace(new RegExp(/ñ/g), 'n');
	r = r.replace(new RegExp(/\//g), '-');
	r = r.replace(new RegExp(/\\/g), '-');
	r = r.replace(new RegExp(/\s/g), '-');
	r = r.replace(new RegExp(/[^\\dA-Za-z\\d0-9\-_]/g), '');

	return r;
};

export default removeAccents;
