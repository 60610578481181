import React from 'react';

import FieldBuilder from './FieldsBuilder';

const View = ({ onSubmit, isLoading, cid, defaultData, onNewQuestionData }) => {
	const [fields, setFields] = React.useState(defaultData.fields || []);

	const move = (from, to) => {
		const _fields = [...fields],
			element = fields[from];

		_fields.splice(from, 1);
		_fields.splice(to, 0, element);
		return _fields;
	};
	const onDown = (index) => {
		setFields(move(index, index + 1));
	};
	const onUp = (index) => {
		setFields(move(index, index - 1));
	};

	const onDelete = (index) => {
		const _fields = [...fields];
		_fields.splice(index, 1);
		setFields(_fields);
	};

	const onUpdate = (data, index) => {
		const _fields = [...fields];
		_fields[index] = data;
		setFields(_fields);
	};

	const _onSubmit = (e) => {
		e.preventDefault();
		const { name } = e.target.elements;
		onSubmit(name.value, fields);
	};

	return (
		<div className="card">
			<div className="card-content">
				<div className="card-body">
					<div className="display-flex space-between">
						<h3>
							<small>{cid ? 'Editar' : 'Nuevo'} Formulario </small>
						</h3>
						<button
							className="btn btn-outline-primary"
							onClick={onNewQuestionData}
						>
							<i className="bx bx-spreadsheet" /> Listado de Formularios
						</button>
					</div>

					<form onSubmit={_onSubmit} className="mg-t-20">
						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Nombre</label>
							</div>
							<div className="col-md-8 form-group">
								<input
									className="form-control"
									type="text"
									name="name"
									defaultValue={defaultData.name || ''}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<label className="form-label">campos</label>
							</div>
							<div className="col-md-8 form-group">
								<button
									type="button"
									className="btn btn-block btn-outline-primary"
									onClick={() => setFields([...fields, {}])}
								>
									<i className="bx bx-plus" />
									Agregar Campo
								</button>
							</div>
						</div>

						<div className="row">
							{fields.map((field, index) => {
								return (
									<FieldBuilder
										field={field}
										index={index}
										isTheFirst={index === 0}
										isTheLast={index === fields.length - 1}
										onDown={onDown}
										onUp={onUp}
										onUpdate={onUpdate}
										onDelete={onDelete}
										key={`${index}_${field.name || ''}_${field.type || ''}`}
									/>
								);
							})}
						</div>

						<div className="col-sm-12 d-flex justify-content-end">
							<button
								disabled={isLoading}
								type="submit"
								className="btn btn-primary mr-1 mb-1"
							>
								<i
									className={
										'bx bx-' + (isLoading ? 'loader-circle spin' : 'save')
									}
								/>{' '}
								Guardar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default View;
