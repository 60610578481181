import React from 'react';
import moment from 'moment';
import swal from 'sweetalert';

import NewForm from './New';

import View from './view';

import QuestionAnswers from 'components/Surveys/QuestionAnswers';

class Forms extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			loadingList: [],
			list: [],
			cid: false,
			questionNewForm: false,
			selectedQuestion: -1,
		};

		this.getForms = this.getForms.bind(this);
		this.onDelete = this.onDelete.bind(this);

		this.questionsListener = () => {};
	}
	componentDidMount() {
		this.getForms();
	}
	componentWillUnmount() {
		this.questionsListener();
	}
	getForms() {
		const { firebase, eid } = this.props,
			self = this;
		this.questionsListener = firebase.getCollectionBySocket(
			'forms',
			eid,
			'questions',
			(response) => {
				const list = [];
				if (!response.empty) {
					response.forEach((doc) => {
						const data = doc.data();
						list.push({
							...data,
							dateLabel: moment
								.unix(data.date)
								.format('DD [de] MMM [de] YYYY - HH:mm'),
							id: doc.id,
						});
					});
					self.setState({ isLoading: false, list });
				} else {
					self.setState({ isLoading: false });
				}
			}
		);
	}
	handleState = (data) => {
		this.setState(data);
	};
	onEdit = (uid) => {
		this.setState({
			questionNewForm: true,
			cid: uid,
		});
	};
	onDelete(uid) {
		const { loadingList } = this.state,
			{ firebase, eid } = this.props,
			self = this;

		swal({
			title: '',
			text: `¿Estas seguro que quieres borrar éste formulario?, esto no podrá revertirse`,
			icon: 'warning',
			buttons: ['No', 'Sí, eliminar!'],
			dangerMode: false,
		}).then((will) => {
			if (will) {
				loadingList.push(uid);
				self.setState({ loadingList, isLoading: true }, () => {
					const index = loadingList.indexOf(uid);
					firebase.delete('forms', eid, 'questions', uid).then(() => {
						loadingList.splice(index, 1);
						const { list } = self.state;

						let idx = -1;
						for (let i = 0; i < list.length; i++) {
							if (list[i].id === uid) {
								idx = i;
								break;
							}
						}
						if (idx >= 0) {
							list.splice(idx, 1);
						}
						self.setState({ list, loadingList, isLoading: false });
					});
				});
			}
		});
	}
	onLaunch = (uid, newValue) => {
		const { loadingList } = this.state,
			{ firebase, eid } = this.props,
			self = this;

		loadingList.push(uid);

		self.setState({ loadingList }, () => {
			const index = loadingList.indexOf(uid);
			firebase
				.update('forms', eid, 'questions', uid, { isActive: newValue })
				.then(() => {
					loadingList.splice(index, 1);
					const { list } = self.state;

					let idx = -1;
					for (let i = 0; i < list.length; i++) {
						if (list[i].id === uid) {
							idx = i;
							break;
						}
					}
					if (idx >= 0) {
						list[idx].isActive = newValue;
					}
					self.setState({ list, loadingList });
				});
		});
	};
	onNewQuestionData = () => {
		this.setState(
			{ questionNewForm: !this.state.questionNewForm, cid: false },
			() => {}
		);
	};
	onSeeResults = (uid) => {
		const { list } = this.state;
		let idx = -1;
		for (let i = 0; i < list.length; i++) {
			if (list[i].id === uid) {
				idx = i;
				break;
			}
		}
		this.setState({ selectedQuestion: idx });
	};
	render() {
		if (this.state.selectedQuestion !== -1) {
			return (
				<QuestionAnswers
					items={this.state.list}
					selectedQuestion={this.state.selectedQuestion}
					assemblyId={this.props.eid}
					handleState={this.handleState}
					isReport
				/>
			);
		}
		if (this.state.questionNewForm) {
			return (
				<NewForm
					{...this.props}
					cid={this.state.cid}
					onNewQuestionData={this.onNewQuestionData}
					eid={this.props.eid}
				/>
			);
		}
		return (
			<View
				{...this.state}
				isActive={this.props.isActive}
				onNewQuestionData={this.onNewQuestionData}
				onDelete={this.onDelete}
				onLaunch={this.onLaunch}
				onEdit={this.onEdit}
				onSeeResults={this.onSeeResults}
			/>
		);
	}
}

export default Forms;
