import React from 'react';
import { NavLink } from 'react-router-dom';

import defaultAvatarDoctor from 'assets/img/doctor.png';

import Buttons from './Tabs/Buttons';

import General from './Tabs/General';
import ChangePassword from './Tabs/ChangePassword';
import Scheduler from './Tabs/Scheduler';
import UserInfo from './Tabs/Info';

const View = ({
	rid = false,
	record = {},
	currentUser,
	company,
	firebase,
	isPasswordLoading,
	onChangePassword,
	onDeleteGroup,
	onSelectGroup,
	updateUser,
}) => {
	const [selectedTab, setSelectedTab] = React.useState(0);
	const [tabs, setTabs] = React.useState([]);
	const [previousLink, setPreviousLink] = React.useState({});

	React.useEffect(() => {
		const _tabs = [
			{
				icon: 'bx bx-cog',
				label: 'General',
				Component: (
					<General
						user={record}
						onSelectGroup={onSelectGroup}
						onDeleteGroup={onDeleteGroup}
					/>
				),
			},
		];

		if (record.uid === currentUser.uid || currentUser.type === 'admin') {
			_tabs.push(
				{
					icon: 'bx bx-edit-alt',
					label: 'Información',
					Component: (
						<UserInfo
							firebase={firebase}
							user={record}
							currentUser={currentUser}
							updateUser={updateUser}
						/>
					),
				},
				{
					icon: 'bx bx-lock-alt',
					label: 'Cambiar clave',
					Component: (
						<ChangePassword
							onSubmit={onChangePassword}
							isLoading={isPasswordLoading}
							user={record}
						/>
					),
				}
			);
		}

		if (record.type === 'doctor') {
			_tabs.push({
				icon: 'bx bx-time',
				label: 'Horario',
				Component: <Scheduler firebase={firebase} user={record} />,
			});
		}

		setTabs(_tabs);
	}, [
		currentUser,
		currentUser.type,
		currentUser.uid,
		firebase,
		isPasswordLoading,
		onChangePassword,
		onDeleteGroup,
		onSelectGroup,
		record,
		updateUser,
		company,
	]);

	React.useEffect(() => {
		const newPreviousLink = {};
		if (record.type === 'doctor') {
			newPreviousLink.link = 'profesionales';
			newPreviousLink.label = 'Profesionales de la Salud';
		}

		if (record.type === 'patient') {
			newPreviousLink.link = 'pacientes';
			newPreviousLink.label = 'Pacientes';
		}

		if (record.type === 'admin') {
			newPreviousLink.link = 'usuarios';
			newPreviousLink.label = 'Usuarios';
		}

		setPreviousLink(newPreviousLink);
	}, [record.type]);

	return (
		<div>
			<div className="d-flex align-items-center mg-b-30">
				<NavLink to={`/${previousLink.link}`}>
					<i className="bx bx-group" /> Listado de {previousLink.label}
				</NavLink>
				<i className="bx bx-chevron-right" />
				<span> {rid ? `${record.displayName}` : 'Nuevo usuario'}</span>
			</div>
			<div className="row">
				<div className="col-lg-2 col-md-3 col-sm-4">
					<Buttons
						tabs={tabs}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
				</div>

				<div className="col-lg-10 col-md-9 col-sm-8">
					{rid !== false && (
						<div className="card">
							<div className="card-content">
								<div className="card-body">
									<div className="d-flex">
										<img
											src={record.PROFILE_64x || defaultAvatarDoctor}
											alt="profile"
											style={{
												borderRadius: '4px',
												boxShadow:
													'0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
												height: '86px',
												objectFit: 'cover',
												width: '86px',
											}}
										/>

										<div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
											<h2>{record.displayName}</h2>
											<h6>{record.email}</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="row user-tab-container">
				{tabs[selectedTab] && tabs[selectedTab].Component}
			</div>
		</div>
	);
};

export default View;
