import React from 'react';
import swal from 'sweetalert';
import moment from 'moment';

import View from './view';

class NewUser extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			icon: false,
			isLoading: false,
			specialties: [],
			validators: {},
		};

		this.references = {
			form: React.createRef(),
		};
	}
	onBack = () => {
		this.props.history.goBack();
	};
	handleState = (data, callback) => {
		this.setState(data, () => {
			if (typeof callback === 'function') {
				callback();
			}
		});
	};
	handleValidators = (title, result) => {
		const { validators } = this.state;
		validators[title] = result;
		this.setState({ validators });
	};
	onSubmit = (e) => {
		e.preventDefault();
		const { validators } = this.state;
		const self = this;
		const { name, date, hour, meridiem, url } = e.target.elements;
		const defaultValidators = ['name'];

		const onNoValidators = (index) => {
			swal('', 'Debes completar los campos', 'error').then(() => {
				validators[index] = false;
				self.setState({ validators }, () => {
					switch (index) {
						case 'name':
							name.focus();
							break;
						case 'date':
							date.focus();
							break;
						case 'hour':
							hour.focus();
							break;
						default:
							break;
					}
				});
			});
		};

		let isValidated = true;

		for (let i = 0; i < defaultValidators.length; i++) {
			if (
				validators[defaultValidators[i]] === undefined ||
				validators[defaultValidators[i]] === false
			) {
				onNoValidators(defaultValidators[i]);
				isValidated = false;
				break;
			}
		}
		if (isValidated) {
			const { firebase } = this.props;

			self.setState({ isLoading: true }, () => {
				const data = {
					name: name.value,
					startDate: moment(
						`${date.value} ${hour.value} ${meridiem.value}`,
						'YYYY-MM-DD hh:mm a'
					).unix(),
					url: url.value,
					creationDate: moment().unix(),
				};

				self.setState({ isLoading: false });

				firebase
					.get('assemblies', { field: 'url', condition: '==', value: data.url })
					.then((duplicates) => {
						if (duplicates.empty) {
							firebase
								.add('assemblies', data)
								.then(() => {
									swal('', 'La asamblea ha sido creada', 'success').then(() => {
										self.onReset();
									});
								})
								.catch(() => {
									self.setState({ isLoading: false }, () => {
										swal(
											'Error',
											'Hubo un error al intentar crear esta asamblea',
											'error'
										).then(() => {
											name.focus();
										});
									});
								});
						} else {
							swal(
								'',
								'Ese nombre de asamblea ya fue asignado, por favor ingresa un nombre diferente',
								'error'
							);
						}
					});
			});
		}
	};
	onReset() {
		this.references.form.current.reset();
		this.onBack();
	}
	render() {
		return (
			<View
				{...this.state}
				type={this.props.type}
				references={this.references}
				handleState={this.handleState}
				handleValidators={this.handleValidators}
				onBack={this.onBack}
				onSubmit={this.onSubmit}
			/>
		);
	}
}

export default NewUser;
