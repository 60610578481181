import React from 'react';
import swal from 'sweetalert';
import View from './view';

import Loading from 'components/Loading';

class User extends React.Component {
	constructor(props) {
		super(props);

		const { match = {} } = props,
			{ params = {} } = match,
			{ rid = false } = params;

		this.state = {
			isReady: false,
			isLoading: false,
			rid,
			record: {},
			isPasswordLoading: false,
			loadingAvatar: false,
		};

		this.getRecord = this.getRecord.bind(this);

		this.onChangePassword = this.onChangePassword.bind(this);
		this.onDeleteGroup = this.onDeleteGroup.bind(this);
		this.onSelectGroup = this.onSelectGroup.bind(this);
	}
	componentDidMount() {
		const { rid } = this.state,
			{ currentUser } = this.props;

		if (rid) {
			if (currentUser.type === 'doctor' || currentUser.type === 'patient') {
				if (rid !== currentUser.uid) {
					this.setState({ rid: currentUser.uid }, () => {
						this.getRecord(rid);
					});
				} else {
					this.getRecord(rid);
				}
			} else {
				this.getRecord(rid);
			}
		} else {
			this.setState({ isReady: true });
		}
	}
	getRecord(rid) {
		const { firebase, history } = this.props,
			self = this;

		self.setState({ isLoading: true }, () => {
			firebase
				.get('users', rid)
				.then((response) => {
					if (response.exists) {
						firebase.getUser(rid).then((data) => {
							const record = { ...response.data(), ...data, id: response.id };
							self.setState({ isReady: true, isLoading: false, record });
						});
					} else {
						history.goBack();
					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}

	onChangePassword(e) {
		e.preventDefault();
		const { password, password2 } = e.target.elements;

		const { rid } = this.state,
			{ firebase } = this.props,
			self = this;

		if (rid) {
			if (password.value !== '') {
				if (password.value.length >= 8) {
					if (password.value === password2.value) {
						this.setState({ isPasswordLoading: true }, () => {
							firebase
								.doUpdateUser({ uid: rid, password: password.value })
								.then(() => {
									swal('Ok,', 'la contraseña ha sido actualizada', 'success');
									self.setState({ isPasswordLoading: false });
								});
						});
					} else {
						swal(
							'Por tu seguridad,',
							'las contraseñas deben coincidir',
							'error'
						);
					}
				} else {
					swal(
						'Por tu seguridad,',
						'la contraseña no debería ser tan corta',
						'error'
					);
				}
			} else {
				swal(
					'Por tu seguridad,',
					'la contraseña no puede estar vacía',
					'error'
				);
			}
		}
	}
	onDeleteGroup(groupId) {
		console.log(groupId);
		const { firebase } = this.props,
			{ rid } = this.state;

		firebase.update('users', rid, {
			specialties: firebase.arrayRemove(groupId),
		});
	}
	onSelectGroup(group) {
		const { firebase } = this.props,
			{ rid } = this.state;

		firebase.update('users', rid, {
			specialties: firebase.arrayUnion(group.id),
		});
	}
	render() {
		const { isReady } = this.state;

		if (isReady) {
			return (
				<View
					{...this.state}
					currentUser={this.props.currentUser}
					company={this.props.company}
					firebase={this.props.firebase}
					onChangeAvatar={this.onChangeAvatar}
					onChangePassword={this.onChangePassword}
					onDeleteGroup={this.onDeleteGroup}
					onSelectGroup={this.onSelectGroup}
					updateUser={this.props.updateUser}
				/>
			);
		}

		return <Loading loader blue noBackground />;
	}
}

export default User;
