import React from 'react';
import SimpleCard from 'components/SimpleCard';

const Location = ({ isActive = false, company = {} }) => {
	return (
		<SimpleCard className={isActive ? '' : 'hide'}>
			<h4 className="card-title">Contacto</h4>
			<div className="row">
				<div className="col-md-4">
					<label className="form-label">Dirección</label>
				</div>
				<div className="col-md-8 form-group position-relative has-icon-left flex-grow-1">
					<input
						className="form-control"
						name="address"
						autoComplete="street-address"
						placeholder="Dirección"
						defaultValue={company.address || ''}
					/>
					<div className="form-control-position">
						<i className="bx bx-home-alt"></i>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-4">
					<label className="form-label">Barrio</label>
				</div>
				<div className="col-md-6 form-group position-relative has-icon-left flex-grow-1">
					<input
						className="form-control"
						name="neighborhood"
						autoComplete="address-level2"
						placeholder="Barrio"
						defaultValue={company.neighborhood || ''}
					/>
					<div className="form-control-position">
						<i className="bx bx-directions"></i>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-4">
					<label className="form-label">Ciudad</label>
				</div>
				<div className="col-lg-4 col-md-6 col-sm-8 form-group position-relative has-icon-left flex-grow-1">
					<input
						className="form-control"
						name="city"
						autoComplete="address-level2"
						placeholder="Ciudad"
						defaultValue={company.city || ''}
					/>
					<div className="form-control-position">
						<i className="bx bx-building-house"></i>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-4">
					<label className="form-label">Teléfono</label>
				</div>
				<div className="col-lg-4 col-md-6 col-sm-8 form-group position-relative has-icon-left flex-grow-1">
					<input
						className="form-control"
						type="number"
						name="phone"
						autoComplete="tel"
						placeholder="Número telefónico"
						defaultValue={company.phone || ''}
					/>
					<div className="form-control-position">
						<i className="bx bx-phone"></i>
					</div>
				</div>
			</div>
		</SimpleCard>
	);
};

export default Location;
