import styled from 'styled-components';

export const Img = styled.img`
    display: inline-block;
    font-size: inherit;
    height: ${props => props.size}px;
    object-fit: cover;
    padding: 12px;
    width: ${props => props.size}px;
`;

export const Container = styled.div`
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    border: dashed 2px rgba(0, 0, 0, 0.64);
    border-radius: 8px;
    display: flex;
    height: ${props => props.size}px;
    justify-content: center;
    padding: 2px;
    position: relative;
    &:hover label{
        display: flex;
        text-decoration: underline;
        text-transform: uppercase;
    }
`;

export const LabelButton = styled.label`
    align-items: center;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.84);
    color: white;
    display: ${props => props.isLoading ? "flex" : "none"};
    font-size: ${props => props.isLoading ? parseInt(props.size/2) + "px": "11px"};
    height: ${props => (props.size - 4)}px;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    transition: 2s;
    width: 100%;
    &:hover{
        cursor: pointer;
    }
`;