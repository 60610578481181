const colors = [
    '3, 155, 229', //Light Blue
    '128, 232, 0', //Lime green
    '255, 183, 0', //Orange
    '139, 4, 168', //Purple
    '255, 49, 0', //Red
    '255, 223, 0', //Yellow
    '0, 160, 138', //Darkcyan
    '229, 57, 53', //Red
    '94, 53, 177', //Deep Purple
    '67, 160, 71', //Green
    '253, 216, 53', //Yellow
    '244, 81, 30', //Deep Orange
    '84, 110, 122', //Blue Gray
    '216, 27, 96', //Pink
    '57, 73, 171', //Indigo
    '0, 172, 193', //Cyan
    '124, 179, 66', //Light Green
    '255, 179, 0', //Amber
    '109, 76, 65', //Brown
    '142, 36, 170', //Purple
    '30, 136, 229', //Blue
    '0, 137, 123', //Teal
    '192, 202, 51', //Lime
    '251, 140, 0', //Orange
    '117, 117, 117' //Gray
];

var getColorForChart = function (index, opacity) {
    index = index || 0;
    opacity = opacity || 1;

    if (index >= colors.length){
        index = Math.floor(colors.length/index);
    }
    
    return 'rgba(' + colors[index] + ',' + opacity + ')'
}

export default getColorForChart;