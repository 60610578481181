function completeWithZero(number, spaces){
    const 
        size = number.toString().length,
        maxSize = spaces - size;
    
    let answer = `${number}`;
    for (let i = 0; i < maxSize; i++){
        answer = `0${answer}`;
    }

    return answer;
}

export default completeWithZero;