import React from "react";

import Table from "react-simpliest-table";

const esLabels = {
    "all": "Mostrar todos",
    "records_per_page": "Registros por página",
    "showing": "Está mostrando",
    "to": "a",
    "of": "de",
    "rows": "filas",
    "no_data": "Tu listado está vacío",
    "no_data_filtered": "Sin coincidencias filtradas",
    "filter": "Pon tu filtro aquí"
};

const EsTable = (props) => {
    return (
        <Table labels={esLabels} {...props} />
    )
}

export default EsTable;