import styled from 'styled-components';

export const RoomContainer = styled.div`
	height: calc(100vh - 7em);
	position: relative;
	width: 100%;

	& > div {
		display: inline-block;
		vertical-align: top;
	}
`;

export const ChatContainer = styled.div`
	display: none;
	border-left: 1px solid rgba(0, 0, 0, 0.05);
	height: 100%;
	padding-left: 1em;
	position: relative;
	width: 30%;

	& > div {
		height: calc(100% - 3em);
		overflow-y: hidden;
		position: relative;
	}

	@media (max-width: 960px) {
		padding-left: 0;
		width: 100%;

		& > div {
			background: rgba(26, 35, 58, 0.84);
			color: var(---white);
			height: calc(100vh - 170px);
			position: absolute;
			top: calc(-100% + 4em);
			width: 100%;
			z-index: 3;
		}
	}
`;

export const PlayerContainer = styled.div`
	background: black;
	overflow: hidden;
	position: relative;
	width: 70%;
	height: 100%;

	@media (max-width: 960px) {
		height: calc(100% - 4em);
		width: 100%;
	}
`;

export const NavContainer = styled.ul`
	width: 100%;

	& > li {
		width: calc(100% / 3);
		margin: 0 !important;
	}

	& > li > button {
		width: 100%;
	}

	@media (max-width: 1600px) {
		& > li > button > i {
			display: block;
		}
	}

	@media (max-width: 960px) {
		& > li {
			display: block;
		}

		& > li > button.active {
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
		}
	}
`;

export const CloseButton = styled.button`
	background: none;
	border: none;
	color: white;
	display: none;
	padding: 8px 10px 6px;
	position: absolute;
	top: calc(-100% + 4em);
	right: 10px;
	z-index: 4;
	@media (max-width: 960px) {
		display: inherit;

		&:hover {
			opacity: 0.8;
		}
		&:focus {
			outline: none;
		}

		& > i {
			font-size: 2.2rem;
			text-shadow: 2px 1px 3px gray;
		}
	}
`;

export const ViewersCounter = styled.div`
	background: rgba(0, 0, 0, 0.84);
	border-bottom-right-radius: 8px;
	border-top-right-radius: 8px;
	bottom: 3px;
	color: white;
	left: 0;
	padding: 6px 12px 6px 3px;
	position: absolute;
	z-index: 3;

	&:hover > i {
		display: none;
	}

	& > small {
		display: none;
	}

	&:hover > small {
		display: inline-block;
	}
`;
