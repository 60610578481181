import React from 'react';
import moment from 'moment';

import generateId from 'services/passwordGenerator';

import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';

import { Container } from './Styles';

const tomorrow = moment().add(1, 'days');
const rangeLabels = {
	TODAY: 'Hoy',
	YESTERDAY: 'Ayer',
	WEEK: 'Ésta semana',
	MONTH: 'Éste mes',
};

const DateSelector = ({
	startDate = moment().startOf('week'),
	endDate = moment().endOf('day'),
	focusedInput,
	startDateLabel = 'startDate',
	endDateLabel = 'endDate',
	focusedInputLabel = 'focusedInput',
	startDatePlaceholder = 'Desde',
	endDatePlaceholder = 'Hasta',
	onDateChange = () => {},
	onFocusedInputChange = () => {},
	onChange = () => {},
	allowFutureDays = false,
}) => {
	const [startDateId] = React.useState(generateId(16, true));
	const [endDateId] = React.useState(generateId(16, true));

	const [rangeSelected, setRange] = React.useState('WEEK');

	const handleDateChange = ({ startDate, endDate }) => {
		onDateChange({ [startDateLabel]: startDate, [endDateLabel]: endDate });
	};

	const handleFocusedInputChange = ({ focusedInput }) => {
		onFocusedInputChange({ [focusedInputLabel]: focusedInput });
	};

	const handleChange = ({ startDate, endDate }) => {
		if (startDate !== null && endDate !== null) {
			onChange({ [startDateLabel]: startDate, [endDateLabel]: endDate });
		}
	};

	return (
		<Container>
			<div className="buttons">
				{['TODAY', 'YESTERDAY', 'WEEK', 'MONTH'].map((range) => (
					<button
						key={range}
						onClick={() => {
							handleDate({
								rangeSelected: range,
								setRange,
								handleDateChange,
								handleChange,
								allowFutureDays,
							});
						}}
						className={
							'btn btn-sm ' +
							(rangeSelected === range ? 'btn-primary' : 'btn-outline-primary')
						}
					>
						{rangeLabels[range]}
					</button>
				))}
			</div>
			<div className="date-picker">
				<DateRangePicker
					startDate={startDate}
					startDateId={startDateId}
					endDate={endDate}
					endDateId={endDateId}
					minimumNights={0}
					onDatesChange={({ startDate, endDate }) =>
						handleDate({
							startDate,
							endDate,
							setRange,
							handleDateChange,
							allowFutureDays,
						})
					}
					focusedInput={focusedInput}
					onFocusChange={(focusedInput) =>
						handleFocusedInputChange({ focusedInput })
					}
					startDatePlaceholderText={startDatePlaceholder}
					endDatePlaceholderText={endDatePlaceholder}
					isOutsideRange={
						allowFutureDays
							? () => false
							: (day) => isInclusivelyAfterDay(day, tomorrow)
					}
					displayFormat="YYYY-MM-DD"
					onClose={handleChange}
				/>
			</div>
		</Container>
	);
};

const handleDate = ({
	startDate,
	endDate,
	rangeSelected,
	setRange,
	handleDateChange,
	handleChange,
	allowFutureDays,
}) => {
	if (rangeSelected) {
		let startDate, endDate;
		switch (rangeSelected) {
			case 'TODAY':
				startDate = moment().startOf('day');
				endDate = moment().endOf('day');
				break;
			case 'YESTERDAY':
				startDate = moment().startOf('day').add(-1, 'days');
				endDate = moment().endOf('day').add(-1, 'days');
				break;
			case 'WEEK':
				startDate = moment().startOf('week');
				endDate = moment().endOf(allowFutureDays ? 'week' : 'day');
				break;
			case 'MONTH':
				startDate = moment().startOf('month');
				endDate = moment().endOf(allowFutureDays ? 'month' : 'day');
				break;
			default:
				startDate = moment().startOf('day');
				endDate = moment().endOf('day');
				break;
		}
		setRange(rangeSelected);
		handleDateChange({ startDate, endDate });
		handleChange({ startDate, endDate });
		return true;
	}

	if (startDate !== null && endDate !== null) {
		const startDateLabel = startDate.format('YYYYMMDD'),
			endDateLabel = endDate.format('YYYYMMDD'),
			today = moment().format('YYYYMMDD');

		if (endDateLabel === today) {
			if (startDateLabel === today) {
				setRange('TODAY');
			} else {
				if (startDateLabel === moment().startOf('week').format('YYYYMMDD')) {
					setRange('WEEK');
				} else {
					if (startDateLabel === moment().startOf('month').format('YYYYMMDD')) {
						setRange('MONTH');
					} else {
						setRange('');
					}
				}
			}
		} else {
			const yesterday = moment().add(-1, 'days').format('YYYYMMDD');
			if (endDateLabel === yesterday && startDateLabel === yesterday) {
				setRange('YESTERDAY');
			} else {
				if (
					endDateLabel === moment().endOf('week').format('YYYYMMDD') &&
					startDateLabel === moment().startOf('week').format('YYYYMMDD')
				) {
					setRange('WEEK');
				} else {
					if (
						endDateLabel === moment().endOf('month').format('YYYYMMDD') &&
						startDateLabel === moment().startOf('month').format('YYYYMMDD')
					) {
						setRange('MONTH');
					}
				}
			}
		}
	}

	handleDateChange({ startDate, endDate });
};

export default DateSelector;
