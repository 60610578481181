import styled from 'styled-components';

export const ListItem = styled.button`
	background: none;
	border: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.14);
	margin: 4px 0;
	padding: 8px 18px 8px 0;
	text-align: left;
	width: 100%;

	&:first-child {
		margin-top: 32px;
	}

	& > i {
		float: right;
	}

	@media (max-width: 960px) {
		color: #8a99b5;
		padding: 2em;
	}
`;

export const ChartsContainer = styled.div`
	max-height: calc(100% - 6em);
	overflow-y: scroll;

	& > div.col-sm-6 {
		display: inline-block;
		vertical-align: top;
	}

	@media (max-width: 960px) {
		padding: 2em;
	}
`;

export const ListContainer = styled.div`
	align-items: center;
	display: flex;
`;
