import React from 'react';

import QuestionsList from './questionsList';
import QuestionAnswers from './QuestionAnswers';

class Surveys extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedQuestion: -1,
		};
	}

	handleState = (data) => {
		this.setState(data);
	};

	render() {
		const { selectedQuestion } = this.state;
		if (selectedQuestion >= 0) {
			return (
				<QuestionAnswers
					{...this.state}
					assemblyId={this.props.id}
					items={this.props.items}
					handleState={this.handleState}
				/>
			);
		}
		return (
			<QuestionsList
				{...this.state}
				assemblyId={this.props.id}
				items={this.props.items}
				handleState={this.handleState}
			/>
		);
	}
}

export default Surveys;
