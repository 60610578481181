import React from 'react';
import moment from 'moment';
import swal from 'sweetalert';

import CodeNotFound from './CodeNotFound';
import View from './View';

class RequestCoefficient extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDownloading: true,
			notFound: false,
			isLoading: false,
			user_data: {},
			wasAccepted: false,
		};
		console.log(props);
	}
	componentDidMount() {
		const { firebase, company, match = {} } = this.props;
		const self = this;
		let code = '_';
		try {
			code = match.params.code;
		} catch (error) {
			console.error(error);
		}

		firebase
			.get('viewers', company.id, 'registered', [
				{
					field: 'transfer.code',
					condition: '==',
					value: code,
				},
				{
					field: 'transfer.approved',
					condition: '==',
					value: false,
				},
			])
			.then((response) => {
				if (response.empty) {
					swal(
						'Error',
						'El código que ingresaste no es válido',
						'error'
					).then(() => self.setState({ isDownloading: false, notFound: true }));
				} else {
					let user_data = {};
					response.forEach(
						(doc) => (user_data = { ...doc.data(), id: doc.id })
					);

					self.setState({ isDownloading: false, user_data });
				}
			});
	}
	onAccept = () => {
		const { firebase, company, currentUser } = this.props;
		const { user_data } = this.state;
		const self = this;
		this.setState({ isLoading: true }, () => {
			firebase
				.update('viewers', company.id, 'registered', user_data.id, {
					'transfer.approved': true,
					'transfer.approved_date': moment().unix(),
					'transfer.approved_user': currentUser.uid,
				})
				.then(() => {
					firebase
						.update('viewers', company.id, 'registered', currentUser.id, {
							another_coefficients: firebase.arrayUnion({
								uid: user_data.id,
								coefficient: user_data.coefficient,
							}),
						})
						.then(() => {
							self.setState({ isLoading: false, wasAccepted: true });
						});
				});
		});
	};
	render() {
		if (this.state.isDownloading) {
			return <h1>Cargando...</h1>;
		} else {
			if (this.state.notFound) {
				return <CodeNotFound />;
			} else {
				return <View {...this.state} onAccept={this.onAccept} />;
			}
		}
	}
}
export default RequestCoefficient;
