import React from 'react';

import { ListContainer, ListItem } from './Styles';

const QuestionsList = ({ items, handleState }) => {
	return (
		<ListContainer>
			{items.map((item, index) => (
				<ListItemButton
					key={item.id}
					onClick={() => handleState({ selectedQuestion: index })}
				>
					{item.name}
				</ListItemButton>
			))}
		</ListContainer>
	);
};

export default QuestionsList;

const ListItemButton = (props) => (
	<ListItem {...props}>
		{props.children} <i className="bx bx-chevron-right" />
	</ListItem>
);
