import React from 'react';

import image1 from "assets/img/loading-bubbles.svg";
import image2 from "assets/img/loader-preview.svg";
import image2_blue from "assets/img/loader-preview-blue.svg";

import { Container } from "./styles.js";

const Loading = ({ loader = false, noBackground = false, blue = false})=>{
    return (
        <Container noBackground={noBackground}>
            <img src={(loader ? (blue ? image2_blue : image2) : image1)} alt="loading" />
        </Container>
    )
}

export default Loading;