import styled from "styled-components";

export const PersonalDataRow = styled.div`
    & .left{
        display: inline-block;
        width: 96px;
    }

    & .right{
        display: inline-block;
        margin-left: 2rem;
        width: calc(100% - 96px - 2rem);
    }

    & .right .form-label-container,
    & .right .form-control-container{
        width: calc(100% - 2rem);
    }

    @media (min-width: 767px){
        & .right .form-label-container{
            width: 90px !important;
            max-width: 90px !important;
        }
        & .right .form-control-container{
            width: calc(100% - 90px - 2rem) !important;
        }
    }

    @media (max-width: 560px){
        & .left{
            margin: 0 1rem 1rem;
            width: 100%;       
        }
        
        & .right{
            width: 100%;
        }
        
    }
`;