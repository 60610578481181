import MyProfile from 'screens/MyProfile';
import Settings from 'screens/Settings';
import Assemblies from 'screens/Assemblies';
import NewAssemblies from 'screens/Assemblies/New';
import AssembliesSettings from 'screens/AssembliesSettings';

import Users from 'screens/Users';
import NewUser from 'screens/NewAdmin';
import User from 'screens/User/Custom/Admin';
import Terms from 'screens/Terms';
import TransferCoefficient from 'screens/TransferCoefficient';
import RequestCoefficient from 'screens/RequestCoefficient';
import Room from 'screens/Room';
import Home from 'screens/Home';

const routes = [
	{
		path: '/:COMPANY_URL/terminos-y-condiciones',
		name: 'Términos y Condiciones',
		icon: 'bx bx-briefcase-alt',
		component: Terms,
		allowedBy: ['superadmin', 'admin', 'user'],
	},
	{
		path: '/admin/configuracion',
		showOnMenu: false,
		name: 'Configuracion',
		icon: 'bx bx-cog',
		component: Settings,
		allowedBy: ['superadmin'],
	},
	{
		path: '/admin/asambleas/nueva',
		showOnMenu: false,
		name: 'Asambleas',
		icon: 'bx bx-building',
		component: NewAssemblies,
		allowedBy: ['superadmin'],
	},
	{
		path: '/admin/asambleas/e/:eid',
		showOnMenu: false,
		name: 'Asambleas',
		icon: 'bx bx-building',
		component: AssembliesSettings,
		allowedBy: ['superadmin'],
	},
	{
		path: '/admin/asambleas',
		name: 'Asambleas',
		icon: 'bx bx-building',
		component: Assemblies,
		allowedBy: ['superadmin'],
	},
	{
		path: `/admin/usuarios/e/:rid`,
		showOnMenu: false,
		name: 'Usuarios',
		icon: 'bx bx-group',
		component: User,
		allowedBy: ['superadmin', 'admin'],
	},
	{
		path: `/admin/usuarios/nuevo`,
		showOnMenu: false,
		name: 'Usuarios',
		icon: 'bx bx-group',
		component: NewUser,
		allowedBy: ['superadmin', 'admin'],
	},
	{
		path: `/admin/usuarios`,
		name: 'Usuarios',
		icon: 'bx bx-group',
		component: Users,
		allowedBy: ['superadmin', 'admin'],
	},
	{
		path: `/admin`,
		name: 'Home',
		showOnMenu: false,
		component: Home,
		allowedBy: ['superadmin', 'admin'],
	},
	{
		path: `/:COMPANY_URL/mi-perfil`,
		name: 'Mi Perfil',
		icon: 'bx bx-user-circle',
		component: MyProfile,
		allowedBy: ['user'],
	},
	{
		path: `/:COMPANY_URL/ceder-poder`,
		showOnMenu: true,
		name: 'Ceder Poder',
		icon: 'bx bx-transfer',
		component: TransferCoefficient,
		allowedBy: ['user'],
	},
	{
		path: `/:COMPANY_URL/transferir/:code`,
		showOnMenu: false,
		name: 'Ceder Poder',
		icon: 'bx bx-transfer',
		component: RequestCoefficient,
		allowedBy: ['user'],
	},
	{
		path: `/:uid`,
		showOnMenu: false,
		name: 'Salón',
		icon: 'bx bx-play',
		component: Room,
		allowedBy: ['superadmin', 'admin', 'user'],
	},
];

export default routes;
