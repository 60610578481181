import React from 'react';

import LoginMethods from 'controllers/login';

import View from './view';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};

		this.Methods = new LoginMethods({
			firebase: props.firebase,
			company: props.company,
		});

		this.onLogin = this.onLogin.bind(this);
		this.onGoogle = this.onGoogle.bind(this);
	}
	onLogin(e) {
		e.preventDefault();
		const { email, password } = e.target.elements;
		this.setState({ isLoading: true }, () => {
			this.Methods.doSignInWithEmailAndPassword(email.value, password.value)
				.then(() => {
					this.setState({ isLoading: false });
				})
				.catch(() => {
					this.setState({ isLoading: false });
				});
		});
	}
	onGoogle() {
		this.Methods.doSignInWithGoogle();
	}
	render() {
		return (
			<View
				company={this.props.company}
				isLoading={this.state.isLoading}
				onLogin={this.onLogin}
				onGoogle={this.onGoogle}
			/>
		);
	}
}

export default Login;
