import React from 'react';
import moment from 'moment';

import defaultAvatar from 'assets/img/user.png';

import ChangeIcon from 'components/ChangeIcon';

import { Form, InfoTable } from './styles';

const View = (props) => {
	const {
		user,
		onChangeAvatar,
		loadingAvatar,
		loadingForm,
		updateName,
		doUpdatePassword,
		loadingPassword,
		dbData = {},
	} = props;
	return (
		<div className="row">
			<div className="card col-md-12">
				<div className="card-content">
					<div className="card-body">
						<div className="containeroso row">
							<div className="content-center col-md-4 col-sm-6">
								<ChangeIcon
									src={user.photoURL || defaultAvatar}
									size={240}
									isLoading={loadingAvatar}
									onChangeFile={onChangeAvatar}
								/>
							</div>
							<div className="col-md-8 col-sm-6">
								<Form className="row" onSubmit={updateName}>
									<div className="col-md-6 form-group">
										<input
											name="name"
											className="form-control"
											type="text"
											defaultValue={user.name}
										/>
									</div>
									<div className="col-md-6 form-group">
										<input
											name="lastname"
											className="form-control"
											type="text"
											defaultValue={user.lastname}
										/>
										<button type="submit" disabled={loadingForm}>
											<i
												className={
													'bx bx-' + (loadingForm ? 'loader-alt spin' : 'save')
												}
											/>
										</button>
									</div>
								</Form>
								<InfoTable>
									<div>
										<div>Id</div>
										<div>{user.uid}</div>
									</div>

									<div>
										<div>Email</div>
										<div>{user.email}</div>
									</div>
									<div>
										<div>Perfil:</div>
										<div>
											{user.phoneNumber
												? 'Administrador'
												: dbData.type === 'manager'
												? 'Jefe de reunión'
												: 'Usuario del Sistema'}
										</div>
									</div>
									<div>
										<div>Fecha de Creación:</div>
										<div className="users-view-latest-activity">
											{moment(user.metadata.creationTime).format(
												'ddd DD [de] MMM [de] YYYY, HH:mm'
											)}
										</div>
									</div>
									<div>
										<div>Última autenticación:</div>
										<div className="users-view-latest-activity">
											{moment(user.metadata.lastSignInTime).format(
												'ddd DD [de] MMM [de] YYYY, HH:mm'
											)}
										</div>
									</div>
									<div>
										<div>Estado:</div>
										<div>
											<span className="badge badge-light-success users-view-status">
												Activo
											</span>
										</div>
									</div>
									<div>
										<button
											onClick={doUpdatePassword}
											className="btn-link"
											disabled={loadingPassword}
										>
											{' '}
											<i
												className={
													'bx bx-' +
													(loadingPassword ? 'loader-alt spin' : 'lock')
												}
											/>{' '}
											Cambiar mi clave
										</button>
									</div>
								</InfoTable>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default View;
