import React from 'react';

import SimpleCard from 'components/SimpleCard';

const Terms = ({ company = {} }) => {
	let { terms = '' } = company;

	return (
		<div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
			<h1 className="text-center col-md-12">TÉRMINOS Y CONDICIONES</h1>
			<SimpleCard>
				{terms.split(/\n/gi).map((paragraph, index) => (
					<p key={index}>{paragraph}</p>
				))}
			</SimpleCard>
		</div>
	);
};

export default Terms;
