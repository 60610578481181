import React from 'react';

import moment from 'moment';

import PeopleTable from './peopleTable';
import TransferedTable from './transferTable';
import { HeaderContainer } from './Styles';

const View = ({ company, wasnotInRoom, wasInRoom, transferVote, users }) => {
	return (
		<React.Fragment>
			<div className="text-right d-print-none">
				<button className="btn btn-success" onClick={() => window.print()}>
					<i className="bx bx-printer" /> Imprimir
				</button>
			</div>
			<div
				className="text-center"
				style={{ background: 'white', borderRadius: '6px', padding: '8px 4px' }}
			>
				<h1>Informe Asamblea Digital</h1>
				<HeaderContainer>
					<div className="brand-img">
						{company.LOGO_256x ? (
							<img src={company.LOGO_256x} alt="logo" />
						) : null}
					</div>
					<div>
						<h3>{company.name}</h3>
						<small>
							<i>
								{moment
									.unix(company.startDate)
									.format('dddd DD [de] MMM [de] YYYY, hh:mm a')}
							</i>
						</small>
					</div>
				</HeaderContainer>

				<PeopleTable
					title="Listado de Personas que no asistieron"
					zeroMessage="No hubo inasistencia"
					data={wasnotInRoom}
					users={users}
				/>

				<PeopleTable
					title="Listado de Personas que sí asistieron"
					zeroMessage="No hay asistencia aún"
					data={wasInRoom}
					users={users}
				/>

				<TransferedTable
					title="Listado de Personas que transfirieron su voto"
					zeroMessage="No hubo transferencia"
					data={transferVote}
					users={users}
				/>
			</div>
		</React.Fragment>
	);
};

export default View;
