import React from 'react';
import viewOptions from './viewOptions';

const View = ({
	eid,
	isLoading,
	company,
	updateData,
	onSubmitData,
	sendTestMessage,
	isSendingMessage,
	firebase,
}) => {
	const [currentView, setCurrentView] = React.useState(0);

	const onChangeView = React.useCallback((index) => {
		setCurrentView(index);
	}, []);

	return (
		<React.Fragment>
			<div className="card d-print-none">
				<div className="card-content">
					<div className="card-header">
						<h4 className="card-title">Configuración</h4>
						<h6 className="card-subtitle mt-1">
							Establece aquí los datos de tu Asamblea
						</h6>
					</div>
				</div>
			</div>
			<ul className="nav nav-pills d-print-none">
				{viewOptions.map((item, index) => {
					const isActive = currentView === index;
					return (
						<li
							className="nav-item"
							onClick={() => onChangeView(index)}
							key={index}
						>
							<button
								className={'nav-link btn-link' + (isActive ? ' active' : '')}
								data-toggle="pill"
								aria-expanded={isActive ? 'true' : 'false'}
							>
								<i className={item.icon} /> {item.text}
							</button>
						</li>
					);
				})}
			</ul>
			{currentView < 6 && (
				<form onSubmit={onSubmitData}>
					{viewOptions.slice(0, 6).map((item, index) => {
						const isActive = currentView === index;
						if (item.requireForSubmit) {
							return (
								<item.component
									eid={eid}
									firebase={firebase}
									company={company}
									updateData={updateData}
									isActive={isActive}
									key={index}
									sendTestMessage={sendTestMessage}
									isSendingMessage={isSendingMessage}
								/>
							);
						}
						return null;
					})}
					<div className="text-right mt-1 mb-3">
						<button
							type="submit"
							className="btn btn-success"
							disabled={isLoading}
						>
							<i className={'bx bx-' + (isLoading ? 'loader-alt' : 'save')} />
							{isLoading ? 'Actualizando' : 'Guardar'}
						</button>
					</div>
				</form>
			)}
			{currentView >= 6 &&
				viewOptions.map((item, index) => {
					const isActive = currentView === index;
					if (isActive) {
						return (
							<item.component
								eid={eid}
								firebase={firebase}
								company={company}
								updateData={updateData}
								isActive={isActive}
								key={index}
								sendTestMessage={sendTestMessage}
								isSendingMessage={isSendingMessage}
							/>
						);
					}
					return null;
				})}
		</React.Fragment>
	);
};

export default View;
