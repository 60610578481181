import React from 'react';
import swal from 'sweetalert';

import View from './view';

class MyProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			loadingPassword: false,
			loadingAvatar: false,
			dbData: {},
		};

		this.getUser = this.getUser.bind(this);
		this.onChangeAvatar = this.onChangeAvatar.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onUpdateName = this.onUpdateName.bind(this);
	}
	componentDidMount() {
		this.getUser();
	}
	getUser() {
		const self = this,
			{ currentUser, company } = this.props;
		this.props.firebase
			.get('viewers', company.id, 'registered', currentUser.uid)
			.then((res) => {
				const user = res.data();
				currentUser.name = user.name;
				currentUser.lastname = user.lastname || user.surname || '';
				self.setState({ dbData: user, isLoading: false });
			});
	}
	onChangeAvatar(file) {
		const { firebase, currentUser, updateUser } = this.props;
		const self = this;
		this.setState({ loadingAvatar: true }, () => {
			firebase
				.uploadFile(`users/${currentUser.uid}`, file, 'PROFILE')
				.then((response) => {
					firebase
						.doUpdateUser({ uid: currentUser.uid, photoURL: response.url })
						.then(() => {
							updateUser({ photoURL: response.url }, () => {
								self.setState({ loadingAvatar: false });
							});
						});
				})
				.catch((res) => {
					self.setState({ loadingAvatar: false });
					console.log(res);
				});
		});
	}
	onChangePassword() {
		const { firebase, currentUser } = this.props,
			self = this;

		this.setState({ loadingPassword: true }, () => {
			firebase.doSendPasswordResetEmail(currentUser.email).then(() => {
				swal(
					'',
					'Se ha envíado un link a tu correo electrónico para realizar el cambio de forma segura, por favor revisa tu bandeja',
					'success'
				);
				self.setState({ loadingPassword: false });
			});
		});
	}
	onUpdateName(e) {
		e.preventDefault();
		const { name, lastname } = e.target.elements,
			{ firebase, currentUser, updateUser } = this.props,
			self = this;

		this.setState({ isLoading: true }, () => {
			firebase
				.doUpdateUser({
					uid: currentUser.uid,
					displayName: `${name.value} ${lastname.value}`,
				})
				.then(() => {
					firebase
						.update('users', currentUser.uid, {
							name: name.value,
							lastname: lastname.value,
						})
						.then(() => {
							updateUser(
								{
									displayName: `${name.value} ${lastname.value}`,
									name: name.value,
									lastname: lastname.value,
								},
								() => {
									self.setState({ isLoading: false });
								}
							);
						});
				});
		});
	}
	render() {
		return (
			<View
				doUpdatePassword={this.onChangePassword}
				loadingPassword={this.state.loadingPassword}
				updateName={this.onUpdateName}
				loadingForm={this.state.isLoading}
				loadingAvatar={this.state.loadingAvatar}
				onChangeAvatar={this.onChangeAvatar}
				user={this.props.currentUser}
				dbData={this.state.dbData}
			/>
		);
	}
}

export default MyProfile;
