const config = {
	apiKey: 'AIzaSyBnU3VIOxjQBOfErag3scXpN21TC5N3Pe8',
	authDomain: 'asambleavirtual-9c8fb.firebaseapp.com',
	databaseURL: 'https://asambleavirtual-9c8fb-default-rtdb.firebaseio.com',
	projectId: 'asambleavirtual-9c8fb',
	storageBucket: 'asambleavirtual-9c8fb.appspot.com',
	messagingSenderId: '259003286088',
	appId: '1:259003286088:web:0401c24cd2311057920e7d',
	measurementId: 'G-2EM3QZ4ZZ4',
};

export default config;
