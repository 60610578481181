import React from 'react';

import { NavLink } from 'react-router-dom';

import Table from 'components/Table';
import Loading from 'components/Loading';

const View = ({ isLoading, onDelete, loadingList = [], list = [] }) => {
	if (isLoading) {
		return <Loading noBackground />;
	}
	return (
		<React.Fragment>
			<div className="card">
				<div className="card-content">
					<div className="card-body">
						<div className="display-flex space-between">
							<h3>
								<small>Listado de Asambleas: </small>
							</h3>
							<NavLink
								className="btn btn-outline-primary"
								to="/admin/asambleas/nueva"
							>
								<i className="bx bx-plus" /> Nueva Asamblea
							</NavLink>
						</div>
						<Table
							list={list}
							filterFunction={(record) => `${record.name} ${record.dateLabel}`}
							defaultMaxRecordPerPage={25}
							defaultSorter={{ parameter: 'date', order: 'DESC' }}
							iterateFunction={(record) => {
								const recordLoading = loadingList.indexOf(record.id) >= 0;
								return (
									<tr
										key={record.id}
										className={recordLoading ? ' loading' : ''}
									>
										<td style={{ width: '75px' }}>
											{recordLoading && (
												<i className="bx bx-loader-circle spin" />
											)}
											{!recordLoading && (
												<div className="display-flex space-between">
													<button
														onClick={() => {
															onDelete(record.id);
														}}
														type="button"
														className="btn btn-icon rounded-circle btn-outline-danger mr-1 mb-1"
													>
														<i className="bx bx-trash" />
													</button>
												</div>
											)}
										</td>
										<td>
											<NavLink to={`/admin/asambleas/e/${record.id}`}>
												{record.name}
											</NavLink>
										</td>
										<td>{record.dateLabel}</td>
									</tr>
								);
							}}
							noDataMessage="No hay nada que coincida con tu búsqueda"
							fields={[
								{
									text: '',
								},
								{
									text: 'Nombre',
									parameter: 'name',
								},
								{
									text: 'Fecha de Creación',
									parameter: 'date',
								},
							]}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default View;
