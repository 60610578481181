import React from 'react';

import { withFirebase } from 'components/Firebase';

import View from './view';

class QuestionAnswers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chartData: {},
		};

		this.answerListener = () => {};
	}
	componentDidMount() {
		const { firebase, assemblyId } = this.props;
		const question = this.props.items[this.props.selectedQuestion];

		const self = this;

		this.answerListener = firebase.getCollectionBySocket(
			'answers',
			assemblyId,
			question.id,
			'overview',
			(snapshot) => {
				const arr = {};
				const downloadedData = snapshot.data();

				for (let index in downloadedData) {
					if (arr[index] === undefined) {
						arr[index] = { labels: [], data: [] };
					}

					for (let choice in downloadedData[index]) {
						arr[index].labels.push(choice);
						arr[index].data.push(downloadedData[index][choice]);
					}
				}

				self.setState({ chartData: arr });
			}
		);
	}
	componentWillUnmount() {
		this.answerListener();
	}

	render() {
		return (
			<View
				{...this.state}
				assemblyId={this.props.assemblyId}
				handleState={this.props.handleState}
				item={this.props.items[this.props.selectedQuestion]}
				isReport={this.props.isReport}
			/>
		);
	}
}

export default withFirebase(QuestionAnswers);
