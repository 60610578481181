import React from "react";
import moment from "moment";
import swal from 'sweetalert';

import View from "./view";

import Loading from "components/Loading";

import profiles from "config/profiles.json";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingUsers: [],
            list: []
        };

        this.addUserToLoadingList = this.addUserToLoadingList.bind(this);
        this.deleteUserFromLoadingList = this.deleteUserFromLoadingList.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onDeleteUser = this.onDeleteUser.bind(this);

        this.completeList = [];
    }
    componentDidMount() {
        this.getUsers();
    }
    componentWillUnmount() {
        if (typeof this.usersListener === "function") {
            this.usersListener();
        }
    }
    addUserToLoadingList(uid) {
        return new Promise((resolve) => {
            const { loadingUsers } = this.state;
            loadingUsers.push(uid);
            this.setState({ loadingUsers }, resolve);
        });
    }
    deleteUserFromLoadingList(uid, data) {
        data = data || {};
        const { loadingUsers } = this.state;
        const index = loadingUsers.indexOf(uid);
        if (index >= 0) {
            loadingUsers.splice(index, 1);
            this.setState({ loadingUsers, ...data });
        }
    }
    onChangeProfile = ({ props, profile }) => {
        const
            { firebase } = this.props,
            self = this;

        swal({
            title: "",
            text: `Este usuario será ${profiles[profile]}, ¿Estas seguro?`,
            icon: "warning",
            buttons: ["No", `Sí, convertir`],
            dangerMode: true
        })
            .then((will) => {
                if (will) {

                    self.addUserToLoadingList(props.uid)
                        .then(() => {

                            firebase.update("users", props.uid, { type: profile })
                                .then(() => {
                                    const { list } = self.state;

                                    self.deleteUserFromLoadingList(props.uid, { list });
                                });
                        });
                }
            });
    }
    onChangeStatus({ props }) {
        const
            { firebase } = this.props,
            self = this;

        swal({
            title: "",
            text: `Se ${(props.isVerified ? "desactivará" : "activará")} este usuario, ¿Estas seguro?`,
            icon: "warning",
            buttons: ["No", `Sí, ${(props.isVerified ? "desactivar" : "activar")}!`],
            dangerMode: true
        })
            .then((will) => {
                if (will) {

                    self.addUserToLoadingList(props.uid)
                        .then(() => {
                            firebase.doUpdateUser({ uid: props.uid, emailVerified: !props.isVerified })
                                .then(() => {
                                    const { list } = self.state;
                                    list[props.index].isVerified = !props.isVerified;
                                    list[props.index].statusLabel = (props.isVerified ? "Inactivo" : "Activo");

                                    self.deleteUserFromLoadingList(props.uid, { list });
                                    firebase.update("users", props.uid, { isVerified: !props.isVerified });
                                });
                        });
                }
            });
    }
    onDeleteUser({ props }) {
        const
            { firebase } = this.props,
            self = this;

        swal({
            title: "",
            text: `Se eliminará este usuario, ESTA ACCIÓN NO PUEDE DESHACERSE, ¿Estas seguro?`,
            icon: "warning",
            buttons: ["No", "Sí, eliminar!"],
            dangerMode: true
        })
            .then((will) => {
                if (will) {
                    self.addUserToLoadingList(props.uid)
                        .then(() => {
                            firebase.doDeleteUser(props.uid)
                                .then(() => {
                                    const { list } = self.state;
                                    list.splice(props.index, 1);
                                    self.deleteUserFromLoadingList(props.uid, { list });
                                });
                        });
                }
            });
    }
    getUsers() {
        const
            { firebase, currentUser, company } = this.props,
            { maxDoctors = 100000} = company,
            self = this;

        const conditions = [{ "field": "type", "condition": "==", "value": "patient" }];

        const onSuccess = (response) => {
            const list = [];
            let countDoctors = 0;
            response.forEach((doc) => {
                const data = doc.data();

                if (data.type === "doctor"){
                    countDoctors++;
                }

                if (data.type !== "superadmin"){
                    list.push({
                        ...data,
                        uid: doc.id,
                        id: doc.id,
                        statusLabel: (data.isVerified ? "activo" : "inactivo"),
                        creationDateLabel: moment.unix(data.creationDate).format("DD [de] MMM YYYY, HH:mm"),
                        profileLabel: profiles[data.type]
                    });
                }
            });
            self.completeList = [...list];

            self.setState({ isLoading: false, list, countDoctors, maxDoctors});
        };

        if (currentUser.type === "patient") {
            this.usersListener = firebase.getCollectionBySocket("users", currentUser.uid,
                (response) => {
                    onSuccess([response]);
                });
        } else {
            if (currentUser.type === "doctor") {
                this.usersListener = firebase.getCollectionBySocket("users", conditions, onSuccess);
            } else {
                this.usersListener = firebase.getCollectionBySocket("users", onSuccess);
            }
        }
    }
    render() {
        if (this.state.isLoading) {
            return <Loading loader blue noBackground />
        }
        return (
            <View
                {...this.state}
                onProfile={this.onChangeProfile}
                onStatus={this.onChangeStatus}
                onDelete={this.onDeleteUser}
            />
        )
    }
}

export default Users;