import React from 'react';
import Loading from 'components/Loading';

import View from './view';

class Report extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
		console.log(props);
	}
	componentDidMount() {
		const { firebase, eid } = this.props;
		const self = this;

		const users = {};

		const wasInRoom = [],
			wasnotInRoom = [],
			transferVote = [];

		const questions = {};

		let questionLength = 0;

		firebase.get('viewers', eid, 'registered').then((registeredUsers) => {
			registeredUsers.forEach((doc) => {
				const { email = '_', uid = [], transfer = {} } = doc.data();
				users[email] = doc.data();

				if (uid.length) {
					//asistió
					if (transfer.approved) {
						transferVote.push(email);
					} else {
						wasInRoom.push(email);
					}
				} else {
					if (transfer.approved) {
						//transfirió el poder
						transferVote.push(email);
					} else {
						// No Asistió
						wasnotInRoom.push(email);
					}
				}
			});

			firebase.get('forms', eid, 'questions').then((_questions) => {
				_questions.forEach((question) => {
					questionLength++;
					questions[question.id] = question.data();
				});
				if (questionLength > 0) {
				}
				self.setState({
					isLoading: false,
					users,
					wasInRoom,
					wasnotInRoom,
					transferVote,
					questions,
				});
			});
		});
	}
	render() {
		if (this.state.isLoading) {
			return <Loading noBackground />;
		}

		const { company = {} } = this.props;

		return <View company={company} {...this.state} />;
	}
}

export default Report;
