import React from "react";

import { Container, Img, LabelButton } from "./Styles";

import Loading from "components/Loading";

import getRandom from "services/getRandomNumber";

const ChangeIcon = ({src, onChange, size = 60, isLoading = false, accept}) => {
    
    const [id] = React.useState("img_" + new Date().getTime() + getRandom().toString());

    return(
        <Container size={size}>
            <LabelButton htmlFor={id} size={size} isLoading={isLoading}>
                {(isLoading) ?
                    <Loading />
                :
                    "Cambiar"
                }
            </LabelButton>
            <Img size={size} src={src} alt="icon" />
            <input
                disabled={isLoading}
                onChange={onChange}
                id={id}
                type="file"
                style={{ display: 'none' }} 
                accept={accept}/>
        </Container>
    )
}

export default ChangeIcon;