import styled from 'styled-components';

export const HeaderContainer = styled.div`
	display: flex;
	margin-top: 3rem;
	text-align: left;

	& .brand-img {
		height: 5em;
		margin-right: 1rem;
		position: relative;
		text-align: center;
		width: 20%;
	}

	& .brand-img img {
		max-width: 100%;
		height: 100%;
		width: auto;
	}
`;

export const PeopleTableContainer = styled.div`
	text-align: left;
	margin-top: 3em;
`;
