import React from 'react';

import Loading from 'components/Loading';

import Table from 'components/Table';

const ViewersList = ({
	isDownloading,
	handleState,
	list,
	loadingList = [],
	onEdit,
	onDelete,
	getViewers,
}) => {
	React.useEffect(() => {
		getViewers();
	}, [getViewers]);
	return (
		<React.Fragment>
			<div className="text-right">
				<button
					className="btn btn-outline-primary"
					onClick={() => handleState({ needUpload: true })}
				>
					<i className="bx bx-plus" /> Agregar usuarios
				</button>
			</div>
			{isDownloading && <Loading noBackground />}
			{!isDownloading && (
				<Table
					list={list}
					filterFunction={(record) =>
						`${record.name} ${record.surname} ${record.nid} ${record.email} ${record.password}`
					}
					defaultMaxRecordPerPage={25}
					defaultSorter={{ parameter: 'date', order: 'DESC' }}
					iterateFunction={(record) => {
						const recordLoading = loadingList.indexOf(record.id) >= 0;
						return (
							<tr key={record.id} className={recordLoading ? ' loading' : ''}>
								<td style={{ width: '150px' }}>
									{recordLoading && <i className="bx bx-loader-circle spin" />}
									{!recordLoading && (
										<div className="display-flex space-between">
											<button
												title="Editar"
												onClick={() => {
													onEdit(record.id);
												}}
												className="btn btn-icon rounded-circle btn-outline-primary mr-1 mb-1"
											>
												<i className="bx bx-edit" />
											</button>

											<button
												title="Eliminar"
												onClick={() => {
													onDelete(record.id);
												}}
												type="button"
												className="btn btn-icon rounded-circle btn-outline-danger mr-1 mb-1"
											>
												<i className="bx bx-trash" />
											</button>
										</div>
									)}
								</td>
								<td>{record.nid}</td>
								<td>{record.name}</td>
								<td>{record.surname}</td>
								<td>{record.email}</td>
								<td>{record.house}</td>
								<td>{record.coefficient}</td>
								<td>{record.password}</td>
							</tr>
						);
					}}
					noDataMessage="No hay nada que coincida con tu búsqueda"
					fields={[
						{
							text: '',
						},
						{
							text: 'Documento',
							parameter: 'nid',
						},
						{
							text: 'Nombre',
							parameter: 'name',
						},
						{
							text: 'Apellidos',
							parameter: 'surname',
						},
						{
							text: 'Correo',
							parameter: 'email',
						},
						{
							text: 'Casa/Apartamento',
							parameter: 'house',
						},
						{
							text: 'Coeficiente',
							parameter: 'coefficient',
						},
						{
							text: 'Código de Acceso',
							parameter: 'password',
						},
					]}
				/>
			)}
		</React.Fragment>
	);
};

export default ViewersList;
