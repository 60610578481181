import React from "react";

import SimpleCard from "components/SimpleCard";

import passwordGenerator from "services/passwordGenerator";

import passwordValidator from "password-validator";

const schemaPassword = new passwordValidator();

schemaPassword
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase();

const ChangePassword = ({ onSubmit, isLoading = false }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);

    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");

    const [isPasswordValid, setPasswordValid] = React.useState(false);
    const [isPasswordMatch, setPasswordMatch] = React.useState(true);

    React.useEffect(() => {
        const isValid = schemaPassword.validate(password);
        setPasswordValid(isValid);
    }, [password]);

    React.useEffect(() => {
        setPasswordMatch(password === password2);
    }, [password, password2]);

    return (
        <React.Fragment>
            <div className="col-lg-2 col-md-3 col-sm-4"></div>

            <div className="col-lg-10 col-md-9 col-sm-8">
                <SimpleCard>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="form-label">Nueva clave</label>
                            </div>
                            <div className="col-md-8 form-group">
                                <div className="input-group">
                                    <input className="form-control" type={(showPassword ? "text" : "password")} name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <div className="input-group-append">
                                        <button
                                            onClick={() => {
                                                const pass = passwordGenerator(12);
                                                setPassword(pass);
                                                setPassword2(pass);
                                                setShowPassword(true)
                                            }}
                                            title={(showPassword ? "Ocultar" : "Ver") + " clave"}
                                            className="btn btn-info btn-sm"
                                            type="button">
                                            <i className={`bx bx-key`} /> Generar
                                        </button>
                                        <button
                                            onClick={() => setShowPassword(!showPassword)}
                                            title={(showPassword ? "Ocultar" : "Ver") + " clave"}
                                            className="btn btn-secondary"
                                            type="button">
                                            <i className={`bx bx-lock-${(showPassword ? "open-" : "")}alt`} />
                                        </button>
                                    </div>
                                </div>
                                {isPasswordValid ? null : <small>La clave debe tener mínimo 8 caracteres, también debe tener mayúsculas y minúsculas</small>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="form-label">Nuevamente la nueva clave</label>
                            </div>
                            <div className="col-md-8 form-group">
                                <div className="input-group">
                                    <input className="form-control" type={(showPassword2 ? "text" : "password")} name="password2" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                                    <div className="input-group-append">
                                        <button
                                            onClick={() => setShowPassword2(!showPassword2)}
                                            title={(showPassword2 ? "Ocultar" : "Ver") + " clave"}
                                            className="btn btn-secondary"
                                            type="button">
                                            <i className={`bx bx-lock-${(showPassword2 ? "open-" : "")}alt`} />
                                        </button>
                                    </div>
                                </div>
                                {isPasswordMatch ? null : <small>Las claven deben coincidir</small>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-end">
                                <button disabled={isLoading || (!isPasswordValid)} type="submit" className="btn btn-primary mb-1">
                                    <i className={"bx bx-" + (isLoading ? "loader-circle spin" : "save")} /> Guardar
                                    </button>
                            </div>
                        </div>
                    </form>
                </SimpleCard>
            </div>
        </React.Fragment>
    )
}

export default ChangePassword;