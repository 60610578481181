import React from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import View from './view';

import Loading from 'components/Loading';

class NewForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			cid: props.cid,
			defaultData: {},
		};

		this.getRecord = this.getRecord.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	componentDidMount() {
		const { cid } = this.state;
		if (cid) {
			this.getRecord(cid);
		} else {
			this.setState({ isLoading: false });
		}
	}
	getRecord(cid) {
		const { firebase, history, eid } = this.props,
			self = this;

		firebase.get('forms', eid, 'questions', cid).then((response) => {
			if (response.exists) {
				const defaultData = { ...response.data(), id: response.id };
				self.setState({ isLoading: false, defaultData });
			} else {
				history.push('/formularios');
			}
		});
	}
	onSubmit(name, fields) {
		const self = this;
		const { eid } = this.props;

		if (name === '') {
			swal('Hey', 'El nombre no puede estar vacío', 'error');
		} else {
			const { firebase } = this.props,
				{ cid } = this.state;

			const data = {
				name,
				fields,
				date: moment().unix(),
			};

			if (cid) {
				self.setState({ isLoading: true }, () => {
					firebase
						.update('forms', eid, 'questions', cid, {
							...data,
						})
						.then(() => {
							swal('', 'El formulario ha sido actualizado', 'success');
							self.setState({ isLoading: false });
						});
				});
			} else {
				self.setState({ isLoading: true }, () => {
					firebase
						.add('forms', eid, 'questions', {
							...data,
						})
						.then((res) => {
							swal({
								title: '',
								text: `El formulario ha sido guardado`,
								icon: 'success',
								dangerMode: false,
							}).then(() => {
								self.props.onNewQuestionData();
							});
						});
				});
			}
		}
	}
	render() {
		if (this.state.isLoading) {
			return <Loading noBackground />;
		}

		return (
			<View
				{...this.state}
				onSubmit={this.onSubmit}
				eid={this.props.eid}
				onNewQuestionData={this.props.onNewQuestionData}
			/>
		);
	}
}

export default NewForm;
