import React from 'react';
import { withFirebase } from 'components/Firebase';
import moment from 'moment';

import { MainContainer } from './Styles';

import ChatMessage from './message';

class Chat extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			messages: [],
		};

		this.dbListener = () => {};

		this.chatContainer = React.createRef(null);
	}
	componentDidMount() {
		const { firebase, id } = this.props;
		const self = this;

		this.dbListener = firebase.getCollectionBySocket(
			'rooms',
			id,
			'chat',
			(res) => {
				if (!res.empty) {
					res.docChanges().forEach((change) => {
						if (change.type === 'added') {
							self.onNewMessage({ ...change.doc.data(), id: change.doc.id });
						}
					});
					self.moveToBottom();
				}
			}
		);
	}
	componentWillUnmount() {
		this.dbListener();
	}
	onNewMessage = (message) => {
		const { messages } = this.state;
		messages.push({
			...message,
			id: message.id,
			time: moment.unix(message.date).format('hh:mm A'),
		});

		messages.sort((a, b) => {
			if (a.date > b.date) {
				return 1;
			}
			if (a.date < b.date) {
				return -1;
			}
			return 0;
		});

		this.setState({ messages }, () => {
			this.moveToBottom();
		});
	};
	onText = (e) => {
		e.preventDefault();
		const { message } = e.target.elements;
		const {
			id,
			uid = 'tester',
			displayName = 'Usuario de Prueba',
			firebase,
		} = this.props;

		if (message.value !== '') {
			firebase
				.add('rooms', id, 'chat', {
					message: message.value,
					uid,
					displayName,
					date: moment().unix(),
				})
				.then(() => {
					message.value = '';
				});
		}
	};
	moveToBottom = () => {
		this.chatContainer.current.scrollTop =
			this.chatContainer.current.scrollHeight -
			this.chatContainer.current.clientHeight;
	};
	render() {
		const { isActive, uid } = this.props,
			{ messages } = this.state;
		return (
			<MainContainer className={'chat ' + (isActive ? 'active' : ' hide')}>
				<div className="chat-content" ref={this.chatContainer}>
					<div className="chat-list">
						{messages.map((message) => {
							return (
								<ChatMessage
									key={message.id}
									isInLeft={message.uid !== uid}
									displayName={message.displayName}
									message={message.message}
									time={message.time}
								/>
							);
						})}
					</div>
				</div>
				<div className="chat-footer border-top p-1">
					<form className="d-flex align-items-center" onSubmit={this.onText}>
						<input
							type="text"
							className="form-control widget-chat-message mx-75"
							name="message"
							placeholder="Escribe un mensaje aquí..."
							autoComplete="off"
						/>
						<button type="submit" className="btn btn-primary glow">
							<i className="bx bx-paper-plane"></i>
						</button>
					</form>
				</div>
			</MainContainer>
		);
	}
}

export default withFirebase(Chat);
