import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import SimpleCard from 'components/SimpleCard';

const General = ({ user = {}, onSelectGroup, onDeleteGroup }) => {
	return (
		<React.Fragment>
			<div className="col-lg-2 col-md-3 col-sm-4"></div>

			<div className="col-lg-10 col-md-9 col-sm-8">
				<SimpleCard>
					<InfoTable>
						<div>
							<div>Id</div>
							<div style={{ color: 'var(----light)' }}>{user.id}</div>
						</div>
						<div>
							<div>Número de Identificación </div>
							<div>{user.nid}</div>
						</div>
						<div>
							<div>Perfil</div>
							<div>
								{user.type === 'doctor'
									? 'Profesional de la Salud'
									: user.type === 'patient'
									? 'Paciente'
									: 'Administrador'}
							</div>
						</div>
						<div>
							<div>Estado</div>
							<div>
								{user.isVerified ? (
									<span className="badge badge-light-success">ACTIVO</span>
								) : (
									<span className="badge badge-light-danger"> INACTIVO</span>
								)}
							</div>
						</div>
						<div>
							<div>Fecha de Creación</div>
							<div>
								{moment(user.metadata.creationTime).format(
									'DD [de] MMM [de] YYYY, HH:mm'
								)}
							</div>
						</div>
						<div>
							<div>Último inicio de sesión</div>
							<div>
								{moment(user.metadata.lastSignInTime).format(
									'DD [de] MMM [de] YYYY, HH:mm'
								)}
							</div>
						</div>
					</InfoTable>
				</SimpleCard>
			</div>
		</React.Fragment>
	);
};

export default General;

const InfoTable = styled.div`
	& > div {
		border-bottom: 1px solid #ebedf0;
		display: flex;
		padding: 1rem 0;
	}

	& > div > div:nth-child(1) {
		flex: 1;
	}
	& > div > div:nth-child(2) {
		flex: 2;
	}

	@media (max-width: 680px) {
		& > div {
			flex-direction: column;
		}
	}
`;
