import React from "react";

import swal from 'sweetalert';
import firebaseErrors from "config/firebaseErrors.json";

import View from "./view";

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
        
        this.onSubmit = this.onSubmit.bind(this);
    }
    onSubmit(e){
        e.preventDefault();
        const {firebase} = this.props;
        const { email } = e.target.elements;
        this.setState({isLoading: true}, ()=>{
            firebase.doSendPasswordResetEmail(email.value)
            .then(()=>{
                swal("", "Se ha envíado un link de recuperación a tu correo electrónico, por favor revisa tu bandeja", "success");
                this.setState({isLoading: false});
            })
            .catch((error)=>{
                const { code = "123" } = error;

                let message = firebaseErrors[code.toUpperCase()];
                if (message === undefined) {
                    message = "No es posible recuperar tu clave en este momento";
                }

                swal("Opps!", message, "error");

                this.setState({ isLoading: false });
            })
        })
    }
    render() {
        return (
            <View 
                isLoading={this.state.isLoading}
                onSubmit={this.onSubmit}/>
        )
    }
}

export default Signup;