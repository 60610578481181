import React from 'react';
import { Bar, Doughnut, Polar } from 'react-chartjs-2';

import getColor from 'services/chartColors';

class SurveyChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chartType: 'bar',
		};
	}

	render() {
		return (
			<div className={this.props.isReport ? 'col-sm-6 col-md-6 col-lg-4' : ''}>
				<div className="card">
					<div className="card-header">
						<div className="display-flex space-between">
							<h4 className="card-title">{this.props.name}</h4>
							<div className="card-actions">
								<button
									onClick={() => this.setState({ chartType: 'doughnout' })}
									className={
										'btn btn-icon ' +
										(this.state.chartType === 'doughnout' ? ' btn-primary' : '')
									}
								>
									<i className="bx bx-pie-chart-alt" />
								</button>
								<button
									onClick={() => this.setState({ chartType: 'bar' })}
									className={
										'btn btn-icon ' +
										(this.state.chartType === 'bar' ? ' btn-primary' : '')
									}
								>
									<i className="bx bx-bar-chart-alt-2" />
								</button>
								<button
									onClick={() => this.setState({ chartType: 'polar' })}
									className={
										'btn btn-icon ' +
										(this.state.chartType === 'polar' ? ' btn-primary' : '')
									}
								>
									<i className="bx bx-git-repo-forked" />
								</button>
							</div>
						</div>
					</div>
					<div className="card-content">
						<div className="card-body">
							<GetChart
								type={this.state.chartType}
								data={this.props.data || { labels: [], data: [] }}
								options={{}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SurveyChart;

function GetChart(props) {
	const colors = {
		backgroundColor: [],
		borderColor: [],
	};
	props.data.labels.forEach((element, index) => {
		colors.backgroundColor.push(getColor(index, 0.6));
		colors.borderColor.push(getColor(index, 1));
	});

	const chartData = {
		labels: props.data.labels,
		datasets: [
			{
				label: [''],
				...colors,
				data: props.data.data,
			},
		],
	};

	if (props.type === 'bar') {
		return (
			<Bar
				data={chartData}
				options={{ scales: { yAxes: [{ ticks: { beginAtZero: true } }] } }}
				legend={{ display: false }}
			/>
		);
	}

	if (props.type === 'doughnout') {
		return <Doughnut data={chartData} options={{}} />;
	}

	return <Polar data={chartData} options={{}} />;
}
