import React from 'react';
import moment from 'moment';

import getPassword from 'services/passwordGenerator';
import View from './view';

import SimpleCard from 'components/SimpleCard';

class TransferCoefficient extends React.Component {
	constructor(props) {
		super(props);

		const { transfer = {} } = props.currentUser,
			{ approved = false } = transfer;

		this.state = {
			isSearching: false,
			results: [],
			wasSearch: false,
			isRequesting: false,
			wasRequested: false,
			canTransfer: !approved,
		};
	}
	onSearch = (event) => {
		event.preventDefault();
		if (!this.state.isSearching) {
			const { firebase, company } = this.props;
			const { nid } = event.target.elements;
			const self = this;

			if (nid.value !== '') {
				this.setState({ isSearching: true, wasSearch: false }, () => {
					firebase
						.get('viewers', company.id, 'registered', {
							field: 'nid',
							condition: '==',
							value: nid.value,
						})
						.then((response) => {
							const arr = [];
							response.forEach((doc) => {
								arr.push({ ...doc.data(), id: doc.id });
							});

							self.setState({
								results: arr,
								isSearching: false,
								wasSearch: true,
							});
						});
				});
			}
		}
	};
	onRequest = (name, email) => {
		if (!this.state.isRequesting) {
			const self = this;
			this.setState({ isRequesting: true }, () => {
				const { firebase, company, currentUser } = this.props;

				const code = getPassword(16, true);

				firebase
					.update('viewers', company.id, 'registered', currentUser.email, {
						transfer: {
							code,
							timestamp: moment().unix(),
							approved: false,
						},
					})
					.then(() => {
						firebase
							.sendEmail({
								eid: company.id,
								type: 'security',
								to: email,
								template: 'requestVote',
								subject: 'Solicitud de transferencia de voto',
								additionalInfo: {
									owner: `${currentUser.name} ${
										currentUser.lastname || currentUser.surname || ''
									}`,
									house: currentUser.house,
									coefficient: currentUser.coefficient,
									name: name,
									code: code,
									assembly: company.id,
									assembly_name: company.name,
									url_origin: window.location.origin,
								},
							})
							.then(() => {
								console.log('tolis');
							});
						self.setState({ isRequesting: false, wasRequested: true });
						//});
					});
			});
		}
	};
	render() {
		if (!this.state.canTransfer) {
			return (
				<SimpleCard>
					<h5>Ya has transferido tu voto en la asamblea</h5>
				</SimpleCard>
			);
		}
		return (
			<View
				{...this.state}
				onSearch={this.onSearch}
				onRequest={this.onRequest}
			/>
		);
	}
}

export default TransferCoefficient;
