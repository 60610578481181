import React from 'react';

import { PeopleTableContainer } from './Styles';

const PeopleTable = ({ title, zeroMessage = '', data = [], users = {} }) => {
	return (
		<PeopleTableContainer>
			<h4>{title}</h4>
			{data.length === 0 && <div className="alert">{zeroMessage}</div>}
			{data.length && (
				<table className="table">
					<thead>
						<tr>
							<th>Documento</th>
							<th>Nombre</th>
							<th>Apellido</th>
							<th>Propiedad</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => (
							<tr key={users[row].email}>
								<td>{users[row].nid}</td>
								<td>{users[row].name}</td>
								<td>{users[row].surname}</td>
								<td>{users[row].house}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</PeopleTableContainer>
	);
};

export default PeopleTable;
