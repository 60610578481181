import React from 'react';
import moment from 'moment';
import SimpleCard from 'components/SimpleCard';
import ChangeIcon from 'components/ChangeIcon';

import InputMask from 'react-input-mask';

import defaultAvatarDoctor from 'assets/img/doctor.png';

const View = ({ user, loadingAvatar, onChangeAvatar, onSubmit, isLoading }) => {
	return (
		<React.Fragment>
			<div className="col-lg-2 col-md-3 col-sm-4"></div>

			<div className="col-lg-10 col-md-9 col-sm-8">
				<SimpleCard>
					<div
						style={{
							display: 'inline-block',
							width: 'calc(100% - 96px - 1rem)',
						}}
					>
						<h2>Editar información de {user.name}</h2>
						<h4>{user.email}</h4>
					</div>
					<div style={{ display: 'inline-block', width: '96px' }}>
						<ChangeIcon
							src={user.PROFILE_64x || defaultAvatarDoctor}
							size={96}
							isLoading={loadingAvatar}
							onChangeFile={onChangeAvatar}
						/>
					</div>
				</SimpleCard>

				<form onSubmit={onSubmit}>
					<SimpleCard>
						<div className="row mb-1">
							<div className="col-sm-12">Información Personal</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Número de Identificación</label>
							</div>
							<div className="col-md-4 form-group position-relative has-icon-left flex-grow-1">
								<input
									className="form-control"
									name="nid"
									autoComplete="off"
									placeholder="Número de Identificación"
									defaultValue={user.nid || ''}
									required={true}
								/>
								<div className="form-control-position">
									<i className="bx bx-id-card"></i>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Nombres</label>
							</div>
							<div className="col-md-4 form-group position-relative has-icon-left flex-grow-1">
								<input
									className="form-control"
									name="name"
									autoComplete="given-name"
									placeholder="Nombre"
									defaultValue={user.name || ''}
									required={true}
								/>
								<div className="form-control-position">
									<i className="bx bxs-user-detail"></i>
								</div>
							</div>
							<div className="col-md-4 form-group position-relative has-icon-left flex-grow-1">
								<input
									className="form-control"
									name="lastname"
									autoComplete="family-name"
									placeholder="Apellidos"
									defaultValue={user.lastname || ''}
								/>
								<div className="form-control-position">
									<i className="bx bxs-user-detail"></i>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Fecha de Nacimiento</label>
							</div>
							<div className="col-md-8 form-group position-relative has-icon-left flex-grow-1">
								<div
									className="input-group"
									style={{ minWidth: '285px', maxWidth: '286px' }}
								>
									<InputMask
										className="form-control"
										name="borndate"
										autoComplete="bday"
										mask="9999-99-99"
										placeholder="AAAA-MM-DD"
										defaultValue={
											user.borndate
												? moment
														.unix(user.borndate.seconds)
														.format('YYYY-MM-DD')
												: ''
										}
										required={true}
									/>
									<div className="form-control-position">
										<i className="bx bx-calendar"></i>
									</div>
									<div className="input-group-append">
										<span className="input-group-text">AÑO-MES-DÍA</span>
									</div>
								</div>
							</div>
						</div>
					</SimpleCard>

					<SimpleCard>
						<div className="row mb-1">
							<div className="col-sm-12">Información de Contacto</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Dirección</label>
							</div>
							<div className="col-md-8 form-group position-relative has-icon-left flex-grow-1">
								<input
									className="form-control"
									name="address"
									autoComplete="street-address"
									placeholder="Dirección de residencia"
									defaultValue={user.address || ''}
								/>
								<div className="form-control-position">
									<i className="bx bx-home-alt"></i>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Barrio</label>
							</div>
							<div className="col-md-6 form-group position-relative has-icon-left flex-grow-1">
								<input
									className="form-control"
									name="neighborhood"
									autoComplete="address-level2"
									placeholder="Barrio"
									defaultValue={user.neighborhood || ''}
								/>
								<div className="form-control-position">
									<i className="bx bx-directions"></i>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Ciudad</label>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-8 form-group position-relative has-icon-left flex-grow-1">
								<input
									className="form-control"
									name="city"
									autoComplete="address-level2"
									placeholder="Ciudad"
									defaultValue={user.city || ''}
								/>
								<div className="form-control-position">
									<i className="bx bx-building-house"></i>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<label className="form-label">Teléfono</label>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-8 form-group position-relative has-icon-left flex-grow-1">
								<input
									className="form-control"
									type="number"
									name="phone"
									autoComplete="tel"
									placeholder="Número telefónico"
									defaultValue={user.phone || ''}
								/>
								<div className="form-control-position">
									<i className="bx bx-phone"></i>
								</div>
							</div>
						</div>

						<div className="row" disabled={isLoading}>
							<div className="col-md-12 text-right">
								<button
									className="btn btn-success"
									type="submit"
									disabled={isLoading}
								>
									<i
										className={
											'bx bx-' + (isLoading ? 'loader-alt spin' : 'save')
										}
									/>{' '}
									Guardar
								</button>
							</div>
						</div>
					</SimpleCard>
				</form>
			</div>
		</React.Fragment>
	);
};

export default View;
