import React from 'react';

import { FieldContainer } from './Styles';

const View = ({
	state,
	handleState,
	isTheFirst,
	isTheLast,
	onDelete,
	onDeleteChoice,
	onDown,
	onDownChoice,
	onUp,
	onUpChoice,
}) => {
	return (
		<FieldContainer>
			<div className="row">
				<div className="col-md-12 text-right field-header">
					{!isTheFirst && <i className="bx bxs-up-arrow" onClick={onUp} />}
					{!isTheLast && <i className="bx bxs-down-arrow" onClick={onDown} />}
					{!(isTheFirst && isTheLast) && (
						<i className="text-danger bx bx-x-circle" onClick={onDelete} />
					)}
				</div>
				<div className="col-md-4">
					<label className="form-label">Tipo</label>
				</div>
				<div className="col-md-8 form-group">
					<select
						className="form-control"
						value={state.type || 'date'}
						onChange={(e) => {
							handleState({ type: e.target.value });
						}}
					>
						{/*<option value="text">Texto</option>*/}
						<option value="date">Fecha</option>
						<option value="time">Hora</option>
						<option value="number">Número</option>
						{/*<option value="textarea">Texto largo</option>*/}
						<option value="select">Selección</option>
						<option value="multiple">Selección múltiple</option>
					</select>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4">
					<label className="form-label">Nombre</label>
				</div>
				<div className="col-md-8 form-group">
					<input
						className="form-control"
						type="text"
						placeholder="Nombre del campo"
						defaultValue={state.name || ''}
						onBlur={(e) => {
							handleState({ name: e.target.value });
						}}
					/>
				</div>
			</div>
			{(state.type === 'select' || state.type === 'multiple') && (
				<React.Fragment>
					<h6>
						<span>OPCIONES</span>
						<button
							type="button"
							onClick={(e) => handleState({ choices: [...state.choices, ''] })}
							className="btn btn-outline-primary"
						>
							<i className="bx bx-plus" /> Agregar
						</button>
					</h6>
					<ul>
						{state.choices.map((choice, index) => {
							return (
								<li key={`${index}_${choice}`}>
									<input
										className="form-control"
										type="text"
										placeholder="Título de la opción"
										defaultValue={choice}
										onBlur={(e) => {
											const { choices = [''] } = state;

											choices[index] = e.target.value;
											handleState({ choices });
										}}
									/>
									<div className="choices-actions">
										{index > 0 && (
											<i
												className="bx bxs-up-arrow"
												onClick={(e) => onUpChoice(index)}
											/>
										)}
										{index < state.choices.length - 1 && (
											<i
												className="bx bxs-down-arrow"
												onClick={(e) => onDownChoice(index)}
											/>
										)}
										{state.choices.length > 1 && (
											<i
												className="text-danger bx bx-x-circle"
												onClick={(e) => onDeleteChoice(index)}
											/>
										)}
									</div>
								</li>
							);
						})}
					</ul>
				</React.Fragment>
			)}
		</FieldContainer>
	);
};

export default View;
