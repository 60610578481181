import React from "react";
import { MenuProvider } from 'react-contexify';
import { NavLink } from "react-router-dom";

import Table from "components/Table";
import defaultAvatar from "assets/img/user.png";

import UserMenu from "./menu";

function View({ list, onStatus, onDelete, onProfile, loadingUsers, countDoctors, maxDoctors }){

    return (
        <div className="card">
            <div className="card-content">
                <div className="card-header">
                    <div className="display-flex space-between">
                        <h4 className="card-title">Usuarios del Sistema</h4>
                        <NavLink
                            className="btn btn-outline-primary"
                            to="/usuarios/nuevo">
                            <i className="bx bx-plus" /> Nuevo Administrador
                        </NavLink>
                    </div>
                </div>
                <div className="card-body">
                    <UserMenu 
                        canAddMoreDoctors={maxDoctors > countDoctors}
                        onProfile={onProfile}
                        onStatus={onStatus}
                        onDelete={onDelete}/>

                    <Table
                        list={list}
                        filterFunction={record => `${record.uid} ${record.email} ${record.name}  ${record.status} ${record.lastname} ${record.groupsLabel} ${record.creationDateLabel} ${record.profileLabel}`}
                        defaultMaxRecordPerPage={25}
                        iterateFunction={({ specialties = [], ...record}, index) => {
                            const userLoading = (loadingUsers.indexOf(record.uid) >= 0)
                            const linkPath = (record.type === "doctor" ? "profesionales" : (record.type === "patient" ? "pacientes" : "usuarios"));
                            return (
                                <tr key={record.uid} className={(userLoading ? "loading" : "")}>
                                    <td style={{ cursor: "pointer" }}>
                                        {userLoading && (
                                            <i className="bx bx-loader spin" />
                                        )}
                                        {!userLoading && (    
                                            <MenuProvider id="menu_id" event="onClick" data={{
                                                ...record,
                                                index
                                            }}>
                                                <div className="avatar">
                                                    <img 
                                                        className="round" 
                                                        style={{width: "32px", height: "32px"}}
                                                        src={record.PROFILE_64x || defaultAvatar} 
                                                        alt="avatar" height="32" with="32"/>
                                                </div>
                                            </MenuProvider>
                                        )}
                                    </td>
                                    <td><NavLink to={`/${linkPath}/e/${record.uid}`}>{record.email}</NavLink></td>
                                    <td><NavLink to={`/${linkPath}/e/${record.uid}`}>{record.name}</NavLink></td>
                                    <td><NavLink to={`/${linkPath}/e/${record.uid}`}>{record.lastname}</NavLink></td>
                                    <td>{record.profileLabel}</td>
                                    <td>
                                        <span className={"badge badge-light-" + (record.isVerified ? "success" : "danger")}>{(record.statusLabel)}</span>
                                    </td>
                                    <td>
                                        {record.creationDateLabel}
                                    </td>
                                </tr>
                            )
                        }}
                        noDataMessage="No hay nada que coincida con tu búsqueda"
                        fields={[{
                            text: "",
                            parameter: "onlineStatus"
                        },{
                            text: "Correo",
                            parameter: "email"
                        }, {
                            text: "Nombre",
                            parameter: "name"
                        }, {
                            text: "Apellidos",
                            parameter: "lastname"
                        }, {
                            text: "Perfil",
                            parameter: "type"
                        }, {
                            text: "Estado",
                            parameter: "statusLabel"
                        }, {
                            text: "Fecha de Creación",
                            parameter: "creationDate"
                        }]} />
                </div>
            </div>
        </div>
    )
}

export default View;

