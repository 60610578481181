import React from 'react';

import defaultAvatar from 'assets/img/user.png';
import ChangeIcon from 'components/ChangeIcon';

import { PersonalDataRow } from './Styles';

const PersonalData = ({
	isUser = false,
	isDoctor = false,
	handleState,
	handleValidators,
	validators,
	references,
}) => {
	return (
		<PersonalDataRow className="row">
			<div className="left">
				<ChangeIcon
					ref={references.changeIcon}
					src={defaultAvatar}
					size={96}
					isLoading={false}
					onChangeFile={(file) => handleState({ icon: file })}
				/>
			</div>

			<div className="right">
				<div className="row">
					<div className="form-label-container">
						<label className="form-label">Documento de identidad</label>
					</div>
					<div className="form-control-container form-group">
						<input
							className="form-control col-md-4"
							type="text"
							name="nid"
							placeholder="Número de documento de identificación"
							onBlur={(e) => handleValidators('nid', e.target.value !== '')}
						/>
						{validators.nid === true || validators.nid === undefined ? null : (
							<small className="text-danger">
								El Documento no puede estar vacío
							</small>
						)}
					</div>
				</div>

				{isDoctor && (
					<div className="row">
						<div className="form-label-container">
							<label className="form-label">Registro Profesional</label>
						</div>
						<div className="form-control-container form-group">
							<input
								className="form-control col-md-4"
								type="text"
								name="doctor_nid"
								placeholder="Registro Profesional"
							/>
						</div>
					</div>
				)}
				<div className="row">
					<div className="form-label-container">
						<label className="form-label">Nombres</label>
					</div>
					<div className="form-control-container form-group">
						<input
							className="form-control"
							type="text"
							name="name"
							placeholder="Nombres"
							onBlur={(e) => handleValidators('name', e.target.value !== '')}
						/>
						{validators.name === true ||
						validators.name === undefined ? null : (
							<small className="text-danger">
								El nombre no puede estar vacío
							</small>
						)}
					</div>
				</div>

				<div className="row">
					<div className="form-label-container">
						<label className="form-label">Apellidos</label>
					</div>
					<div className="form-control-container form-group">
						<input
							className="form-control"
							type="text"
							name="lastname"
							placeholder="Apellidos"
						/>
					</div>
				</div>
			</div>
		</PersonalDataRow>
	);
};

export default PersonalData;
