import React from "react";

import { DayPickerRangeController } from 'react-dates';

const RepeatOptions = React.forwardRef(({ selectedDay }, ref)=>{
    
    const [mode, handleMode] = React.useState("day");
    const [until, handleDate] = React.useState(selectedDay.moment.clone());

    React.useImperativeHandle(ref, () => {
        return {
            mode, until
        }
    });
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-12">
                    <label className="form-label">Repetir cada</label>
                </div>
                <div className="btn-group col-sm-12" role="group" aria-label="_">
                    <button type="button" onClick={() => handleMode("day")}className={"btn " + (mode === "day" ? "active" : "")}>Día</button>
                    <button type="button" onClick={() => handleMode("week")}className={"btn " + (mode === "week" ? "active" : "")}>Semana</button>
                    <button type="button" onClick={() => handleMode("month")}className={"btn " + (mode === "month" ? "active" : "")}>Mes</button>
                </div>
                <div className="col-sm-12">
                    <p>
                        {(mode === "day") && "Todos los días"}
                        {(mode === "week") && "Todos los " + selectedDay.moment.format("dddd")}
                        {(mode === "month") && "Todos los " + selectedDay.moment.format("DD") + "º de cada mes"}
                    </p>
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Hasta</label>
                </div>
                <div className="col-sm-12 form-group">
                    <DayPickerRangeController
                        startDate={selectedDay.moment} 
                        endDate={until} 
                        onDatesChange={({ endDate }) => handleDate(endDate)} 
                        focusedInput={"endDate"} 
                        onFocusChange={focusedInput => {}} 
                        initialVisibleMonth={() => until} 
                    />
                </div>
            </div>
        </React.Fragment>
    )
});

export default RepeatOptions;