import React from 'react';

import SimpleCard from 'components/SimpleCard';

import ImageUploader from 'components/ImageUploader';
import loginSource from 'assets/css/img/pages/login.png';
import logoSource from 'assets/img/logo.png';
import removeAccents from 'services/removeAccents';

const View = ({ eid, isActive = false, company = {}, updateData }) => {
	const [url, setUrl] = React.useState(company.name);
	const [urlIsEdited, setUrlIsEdited] = React.useState(false);

	React.useState(() => {
		setUrl(removeAccents(company.name || ''));
	}, [company.name]);

	return (
		<SimpleCard className={isActive ? '' : 'hide'}>
			<h4 className="card-title">Identidad</h4>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label className="col-form-label">Nombre de tu Asamblea:</label>
						<input
							className="form-control"
							type="text"
							placeholder="Ingresa el nombre que identificará la Asamblea"
							name="name"
							onChange={(e) => {
								if (!urlIsEdited) {
									setUrlIsEdited(true);
								}
								setUrl(removeAccents(e.target.value));
							}}
							defaultValue={company.name || ''}
						/>
					</div>
					<div className="form-group">
						<label className="col-form-label">
							Dirección para los asistentes:
						</label>
						<p>
							<a
								rel="noopener noreferrer"
								href={`${window.location.origin}/${company.url}`}
								target="_blank"
							>
								{' '}
								{window.location.origin}/{urlIsEdited ? url : company.url || ''}
							</a>
						</p>
					</div>

					<div className="form-group">
						<label className="col-form-label">Logo de tu Asamblea:</label>
						<ImageUploader
							src={company.LOGO_256x || logoSource}
							uploadPath={`assemblies/${eid}`}
							name="LOGO"
							onSelect={(src) => {
								updateData({ LOGO_64x: src }, false);
							}}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
						<label className="col-form-label d-block">
							Imágen de bienvenida
						</label>
						<small className="text-muted">
							La que se muestra en el formulario de inicio de sesión
						</small>
						<ImageUploader
							src={company.LOGIN_256x || loginSource}
							height="18em"
							uploadPath={`assemblies/${eid}`}
							name="LOGIN"
							onSelect={(src) => {
								updateData({ LOGIN_256x: src }, false);
							}}
						/>
					</div>
				</div>
			</div>
		</SimpleCard>
	);
};

export default View;
